// Reducers
import { ActionForKeyAndType, ActionState } from '../../reducers/action';

// Models
import * as model from '../../models';

// Helpers
import { FormType } from './helper';

type ComponentProps = {
  /**
   * Actions
   */
  fetchApp: any;
  fetchAuths: any;
  fetchActionsForApp: any;
  fetchGivesForStep: any;

  /**
   * Redux
   */
  appsByID: { [key: string]: model.App };
  authsByID: any;
  actionState: ActionState;

  /**
   * Hanlders.
   */
  onError?: any;
};

/**
 * Task State Helper provides convinence methods for managing
 * that state for a task is loaded before displaying the
 * BaseSetupForm UI.
 */
export default class TaskStateHelper {
  public props: ComponentProps; // eslint-disable-line

  constructor(props: ComponentProps) {
    this.props = props;
  }

  /**
   * Return
   */
  public getFormState = async (task: model.Task, type: FormType) => {
    await this.ensureTaskState(task);

    if (type === FormType.Read) {
      return this.getStateForStep(task.export_step!, task);
    }
    return this.getStateForStep(task.import_step!, task);
  };

  public ensureTaskState = async (task: model.Task) => {
    const sourceApp = task.export_step?.app;
    const promises = [];
    if (sourceApp) {
      promises.push(this.fetchAppIfNeeded(sourceApp));
      promises.push(this.fetchAuthsIfNeeded(sourceApp));
    }
    const destApp = task.import_step?.app;
    if (destApp) {
      promises.push(this.fetchAppIfNeeded(destApp));
      promises.push(this.fetchAuthsIfNeeded(destApp));
      promises.push(this.props.fetchGivesForStep(task.export_step!));
    }
    await Promise.all(promises);
  };

  /**
   * Fetches an app only if we don't already have it in memory.
   */
  fetchAppIfNeeded = async (appID?: string) => {
    if (!appID) return;

    const { appsByID } = this.props;
    const app = appsByID[appID];
    if (app && app.image) return;
    await this.props.fetchApp(appID);
  };

  fetchAuthsIfNeeded = async (appID: string) => {
    if (!appID) return;

    const { authsByID } = this.props;
    if (authsByID[appID] && authsByID[appID].length) return;

    await this.props.fetchAuths(appID);
  };

  /**
   * Gets modal state for a read modal.
   */
  getStateForStep = async (step: model.Step, task: model.Task) => {
    if (!step) return {};

    // App and Auth
    const appID = step.app!;
    const authID = `${step.auth!}`;
    const actionKey = step.action!;
    const actionType = step.action_type;
    if (!actionKey || !actionType) return { appID, authID };

    const action = this.getAction(actionKey, actionType);
    if (!action) {
      await this.props.fetchActionsForApp(appID);
    }
    // Needs and params
    const needs = step.needs!;
    const mapping = task.mappings!;
    return { appID, authID, actionKey, actionType, needs, mapping };
  };

  /**
   * Fetches an action if it exists.
   */
  getAction = (key: string, type: model.ActionType) => {
    const { actionState } = this.props;
    return ActionForKeyAndType(actionState, key, type);
  };
}
