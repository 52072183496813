// External Deps
import { addBreadcrumb } from '@sentry/browser';
import { indexBy, sortBy } from 'underscore';

// Actions
import { Actions as ZapActions, TypeKeys as ZapTypeKeys } from '../actions/zap';

// Models
import {
  Zap,
  Give,
  Node,
  NodeOutput,
  FormattedNodeInput,
  WritesByRootID,
  WritesWithErrors,
} from '../models';

// Util
import { logError } from './error';

type NodeOutputsID = { [key: number]: NodeOutput }; // Represents the original read
type NodeOutputsNodeID = { [key: number]: NodeOutput[] }; // Node output records by NodeID
const defaultState = {
  zaps: null as null | Zap[],
  currentZap: null as null | Zap,
  zapPartnerMetadata: null as Map<string, any> | null,
  zapRecordsByRootRecordID: {} as NodeOutputsID,
  nodeOutputsByRootNodeID: {} as NodeOutputsNodeID,
  givesByNodeID: {} as { [key: number]: Give[] },

  loadingZap: false,
  loadingZaps: false,
  loadingRecords: false,
  loadingGives: false,
  writingToNode: false,
  writingToZap: false,
  activeWrites: {} as WritesByRootID, // IDs of the records being written w Inputs
  successfulWrites: {} as WritesByRootID, // IDs of the records being written w Inputs
  writeErrors: {} as WritesWithErrors, // IDs of the records being written w Inputs
  totalWriteCount: 0, // Number of total in progress writes

  error: null as string | null, // Zap Reducer specific error
  errorZapID: undefined as number | undefined, // Zap ID to fix
};

export type ZapState = typeof defaultState;

function reducer(state = defaultState, action: ZapActions): ZapState {
  switch (action.type) {
    /**
     * STATE CALLS
     */

    case ZapTypeKeys.FETCHING_ZAPS: {
      return {
        ...state,
        loadingZaps: true,
      };
    }

    case ZapTypeKeys.FETCHING_ZAP: {
      const nodeOutputsByRootNodeID = {[action.zapID]: []};
      return {
        ...state,
        loadingZap: true,
        currentZap: null,
        zapPartnerMetadata: null,
        zapRecordsByRootRecordID: {},
        nodeOutputsByRootNodeID,
        givesByNodeID: {},
        activeWrites: {},
        successfulWrites: {},
        writeErrors: {},
        totalWriteCount: 0,
        error: null,
      };
    }
    case ZapTypeKeys.FETCHING_RECORDS: {
      return {
        ...state,
        loadingRecords: true,
      };
    }

    case ZapTypeKeys.FETCHING_GIVES: {
      return { ...state };
    }

    case ZapTypeKeys.WRITING_RECORDS_TO_ZAP: {
      const activeWrites = action.records.reduce(
        (
          writes: WritesByRootID,
          nodeInput: FormattedNodeInput | NodeOutput
        ) => {
          writes[nodeInput.rootID] = nodeInput;
          return writes;
        },
        {}
      );

      return {
        ...state,
        activeWrites,
        totalWriteCount: action.records.length,
        writingToZap: true,
      };
    }

    case ZapTypeKeys.WRITING_RECORDS_TO_NODE: {
      const activeWrites = action.records.reduce(
        (
          writes: WritesByRootID,
          nodeInput: FormattedNodeInput | NodeOutput
        ) => {
          writes[nodeInput.rootID] = nodeInput;
          return writes;
        },
        {}
      );

      return {
        ...state,
        activeWrites,
        totalWriteCount: action.records.length,
        nodeOutputsByRootNodeID: {
          ...state.nodeOutputsByRootNodeID,
          [action.nodeID]: [],
        },
        writingToNode: true,
      };
    }

    case ZapTypeKeys.RESET_ZAP_STATE: {
      return { ...defaultState };
    }

    /**
     * RESOURCE CALLS
     */

    case ZapTypeKeys.FETCHED_ZAP: {
      action.zap.nodes = sortNodes(action.zap.nodes);
      addBreadcrumb({ data: action.zap, category: 'zap' });
      return {
        ...state,
        loadingZap: false,
        currentZap: action.zap,
        error: null,
      };
    }

    case ZapTypeKeys.FETCHED_ZAP_USER_METADATA: {
      return {
        ...state,
        zapPartnerMetadata: action.metadata
      };
    }

    case ZapTypeKeys.FETCHED_ZAPS: {
      addBreadcrumb({ data: action.zaps, category: 'zaps' });
      const sortedZaps = sortBy(action.zaps, 'lastchanged').reverse();
      return {
        ...state,
        loadingZaps: false,
        zaps: sortedZaps,
        error: null,
      };
    }

    case ZapTypeKeys.ERROR_FETCHING_ZAP: {
      logError(action.error);
      return { ...state, loadingZap: false, error: action.error.message, errorZapID: action.externalID };
    }

    case ZapTypeKeys.ERROR_FETCHING_ZAPS: {
      logError(action.error);
      return { ...state, loadingZaps: false, error: action.error.message };
    }

    case ZapTypeKeys.FETCHED_RECORDS: {
      let recordsByRootID = state.zapRecordsByRootRecordID;
      let nodeOutputsByRootNodeID = state.nodeOutputsByRootNodeID[action.nodeID];

      // Optionally replace the existing if this action contains records. Otherwise it's
      // assumed this action call is just used to signal the end of a batch process
      if (action.records) {
        recordsByRootID = indexBy(action.records, 'rootID');
        nodeOutputsByRootNodeID = Object.values(recordsByRootID);
      }

      return {
        ...state,
        loadingRecords: false,
        zapRecordsByRootRecordID: recordsByRootID,
        nodeOutputsByRootNodeID: {
          [action.nodeID]: nodeOutputsByRootNodeID,
        },
      };
    }

    case ZapTypeKeys.FETCHED_RECORDS_BATCH: {
      console.log('records: ', action.records);
      const recordsByRootID = indexBy(action.records, 'rootID');
      console.log('recordsByRootID ', recordsByRootID);
      const zapRecordsByRootRecordID = {
        ...state.zapRecordsByRootRecordID,
        ...recordsByRootID
      };

      return {
        ...state,
        zapRecordsByRootRecordID,
        nodeOutputsByRootNodeID: {
          [action.nodeID]: [
            ...(state.nodeOutputsByRootNodeID[action.nodeID] || {}),
            ...Object.values(recordsByRootID)
          ],
        },
      };
    }

    case ZapTypeKeys.FETCHED_GIVES: {
      addBreadcrumb({ data: action.gives, category: 'gives' });
      return {
        ...state,
        loadingGives: false,
        givesByNodeID: {
          ...state.givesByNodeID,
          [action.nodeID]: action.gives,
        },
      };
    }

    case ZapTypeKeys.WROTE_RECORD_TO_NODE:
    case ZapTypeKeys.WROTE_RECORD_TO_ZAP: {
      const newWrites = { ...state.activeWrites };
      delete newWrites[action.input.rootID];
      return {
        ...state,
        successfulWrites: {
          ...state.successfulWrites,
          [action.input.rootID]: action.input,
        },
        activeWrites: newWrites,
      };
    }

    case ZapTypeKeys.ERROR_WRITING_RECORD_TO_NODE: {
      const activeWrites = { ...state.activeWrites };
      delete activeWrites[action.input.rootID];
      const writeErrors = {
        ...state.writeErrors,
        [action.input.rootID]: action.error,
      };

      return {
        ...state,
        writeErrors,
        activeWrites,
      };
    }

    case ZapTypeKeys.WROTE_ALL_RECORDS_TO_NODE: {
      return {
        ...state,
        activeWrites: {},
        nodeOutputsByRootNodeID: {
          ...state.nodeOutputsByRootNodeID,
          [action.nodeID]: action.results,
        },
        totalWriteCount: 0,
        writingToNode: false,
      };
    }

    case ZapTypeKeys.WROTE_ALL_RECORDS_TO_ZAP: {
      return {
        ...state,
        activeWrites: {},
        totalWriteCount: 0,
        writingToZap: false,
      };
    }

    case ZapTypeKeys.RESET_WRITE_STATE: {
      return {
        ...state,
        activeWrites: {},
        writeErrors: {},
        successfulWrites: {},
        totalWriteCount: 0,
        writingToZap: false,
      };
    }

    default:
      return state;
  }
}

const sortNodes = (nodes: Node[]): Node[] => {
  const newNodes: Node[] = [];
  let currentNode = nodes.find((node: Node) => !node.parent_id);
  if (!currentNode) {
    throw new Error('Invalid zap - no root node found');
  }
  newNodes.push(currentNode);
  while (currentNode) {
    currentNode = undefined;
    for (const node of nodes) {
      if (node.parent_id === newNodes[newNodes.length - 1].id) {
        newNodes.push(node);
        currentNode = node;
        break;
      }
    }
  }
  return newNodes;
};

export default reducer;
