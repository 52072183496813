import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import Analytics from './common/utils/analytics'

// Setup Bug tracking and Analytics
Analytics.init()

const render = () => {
  ReactDOM.render(<App />, document.getElementById('root'));
};
render();
