import './style.scss';

import React from 'react';
import { OptionProps } from 'react-select';

import AppIconButton from '../../../AppIconButton';

// Utils
import Classname from '../../../../utils/classname';

// Models
import * as model from '../../../../models';

type Props = {
  app?: model.App;
  optionProps: OptionProps<any>;
};

export const OptionGroupHeader = (props: Props) => {
  const { children, label } = props.optionProps;

  const className = Classname({
    'custom-select-container': true,
    'option-group': true,
    'd-flex align-items-center': true,
  });
  return (
    <div className={className} key={label}>
      <AppIconButton app={props.app} size={40} padding={8} radius={8} />
      <p className="option-heading">{children}</p>
    </div>
  );
};
