import './style.scss';

import * as React from 'react';
import * as RB from 'react-bootstrap';
import * as Feather from 'react-feather';

// Components
import ActionSetupForm from '../../../common/components/ActionSetupForm';

// Model
import * as model from '../../../common/models';
import Analytics, { Action } from 'src/common/utils/analytics';

type Props = {
  title: string;
  icon: Feather.Icon;
  app: model.App;
  onSave: () => void;
  onCancel: () => void;
};

const initialState = {
  show: false,
};
type State = typeof initialState;

/**
 * Builds the table Tab Menu
 */
class TabMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...initialState };
  }

  handleToggle = () => {
    if (!this.state.show) {
      Analytics.trackAction(Action.ColumnHeaderClick);
    }
    this.setState({ show: !this.state.show });
  };

  /**
   * Builds the toggle UI element.
   */
  buildToggle = () => {
    const { title, icon } = this.props;
    const FeatherIcon = icon;
    return (
      <RB.Dropdown.Toggle
        className="d-flex align-items-center justify-content-between"
        variant="link"
        id="dropdown-basic"
      >
        <div className="d-flex align-items-center" style={{ width: '100%' }}>
          <FeatherIcon size={16} />
          <p className="table-header-label">{title}</p>
        </div>
        <Feather.ChevronDown size={16} />
      </RB.Dropdown.Toggle>
    );
  };

  /**
   * Builds the menu UI element.
   */
  buildMenu = () => {
    const { app } = this.props;
    const actionType = model.ActionType.Write;
    return (
      <RB.Dropdown.Menu className="table-header-menu">
        <ActionSetupForm
          app={app}
          actionType={actionType}
          onSave={this.handleToggle}
          onCancel={this.handleToggle}
        />
      </RB.Dropdown.Menu>
    );
  };

  render() {
    const { show } = this.state;
    return (
      <RB.Dropdown
        className="table-header-dropdown"
        onToggle={this.handleToggle}
        show={show}
      >
        {this.buildToggle()}
        {this.buildMenu()}
      </RB.Dropdown>
    );
  }
}

export default TabMenu;
