import './style.scss';

// Render Prop
import React from 'react';
// import * as RB from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { FormikProps, FieldProps } from 'formik';
import { flatten } from 'underscore';

// Actions
import { AppActions } from '../../actions/app';
import { TaskActions } from '../../actions/task';
import { ActionActions } from '../../actions/action';
import { NeedActions } from '../../actions/need';

// State
import { RootReducerState } from '../../reducers';
import { ActionForKeyAndType } from '../../reducers/action';

// Components
// import AppColumn from './AppColumn';
import AppHeader from './AppHeader';
import Button, { ButtonWidth } from '../Button';
import FormComponent from '../Form';
import { InputOption } from '../Inputs/types';

// Utils
import { sync } from '../../../utils/sync';
import * as Logger from '../../../utils/logger';

// Service
import { RecordType } from '../../services/transfer';
import { BuildFieldInput, FormState } from './inputs';

// Models
import * as model from '../../models';

type ComponentProps = {
  /**
   * The Zap for the component.
   */
  task: model.Task;

  /**
   * Boolean to display confirmation message.
   */
  confirmation?: boolean;

  /**
   * On Save Handler.
   */
  onSave?: (params: { [key: string]: string }) => void;

  /**
   * On Cancel Handler.
   */
  onCancel?: () => void;
};
type ConnectedState = ReturnType<typeof mapStateToProps>;
type ConnectedActions = ReturnType<typeof mapDispatchToProps>;
type Props = ComponentProps & ConnectedState & ConnectedActions;

const initialState = {
  loading: false,
  fieldCount: 1,
  exportFields: [] as string[],
};
type State = typeof initialState;

/**
 * Renders the FieldMapper component.
 */
class FieldMapper extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...initialState };
  }

  //-------------------------------
  // Network Requests
  //-------------------------------

  componentDidMount() {
    this.fetchDataForComponent();
  }

  fetchDataForComponent = async () => {
    const { task } = this.props;
    if (!task) return;

    this.setState({ loading: true });
    this.fetchRecordsForZap(task, RecordType.Source);
    this.fetchActionsForStep(task.export_step);
    this.fetchActionsForStep(task.import_step);
    this.fetchNeedsForStep(task.import_step!);
    this.setState({ loading: false });
  };

  /**
   * Fetches all records for a Zap.
   */
  fetchRecordsForZap = async (task: model.Task, type: RecordType) => {
    if (!task || !task.id) return;

    const [err] = await sync(this.props.fetchRecordsForTask(task, type) as any);
    if (err) {
      Logger.LogInfo('Failed to fetched records for Zap with error:', err);
    }
  };

  /**
   * Ensures an action exists.
   */
  fetchActionsForStep = (step?: model.Step) => {
    if (!step) return;

    const { appsByID } = this.props.appState;
    const appID = step.app;
    const app = appsByID[appID!];
    const action = this.getAction(step.action!, step.action_type!);
    if (!action) {
      this.fetchActionsForApp(app);
    }
  };

  /**
   * Fetches needs for an app.
   */
  fetchActionsForApp = async (app: model.App) => {
    if (!app || !app.currentImplementationId) return;
    const selectedAppID = app.currentImplementationId;
    const [err] = await sync(
      this.props.fetchActionsForApp(selectedAppID) as any
    );
    if (err) {
      Logger.LogInfo('Failed to needs for app with err:', err);
    }
  };

  /**
   * Fetches all needs for the current form values.
   */
  fetchNeedsForStep = async (step: model.Step) => {
    const appID = step.app;
    const actionKey = step.action;

    const { authsByID } = this.props;
    const auth = authsByID[step.auth!];

    const action = this.props.actionState.actions.find((a: model.Action) => a.key === actionKey);
    const needs = step.needs;
    const [err] = await sync(
      this.props.fetchNeedsForWriteAction(action!, appID, needs!, auth) as any
    );
    if (err) {
      Logger.LogInfo('Failed to needs for app with err:', err);
    }
  };

  //-------------------------------
  // Action Handlers
  //-------------------------------

  /**
   * Handles changes to our input selection.
   *
   * @discussion We only care about export fields, as those must be unique.
   */
  handleChange = (option: InputOption, fieldProps: FieldProps) => {
    const { name } = fieldProps.field;
    if (!name.includes('exportFields')) return;

    const exportFields = [...this.state.exportFields];
    exportFields.push(option.value);
    this.setState({ exportFields });
  };

  /**
   * Handles clicks on the save button.
   */
  handleSubmit = (form: FormState) => {
    if (!this.props.onSave) return;
    let params = {} as { [key: string]: string };
    form.exportFields.forEach((exportFields: any, index: number) => {
      params[exportFields] = form.importFields[index];
    });
    this.props.onSave(params);
  };

  /**
   * Handles incrementing the field count.
   */
  handleAddClick = () => {
    const count = this.state.fieldCount + 1;
    this.setState({ fieldCount: count });
  };

  //-------------------------------
  // Data Helpers
  //-------------------------------

  /**
   * Helper to find an action for a app.
   */
  getAction = (actionKey: string, type: model.ActionType) => {
    const { actionState } = this.props;
    return ActionForKeyAndType(actionState, actionKey, type)
  };

  getZapRecord = () => {
    const { task } = this.props;
    const { taskRecords } = this.props.taskState;
    const records = taskRecords[task.id!];
    if (!records || !records.length) return {};
    return records[0];
  };

  /**
   * Get select choices for a Zap record.
   */
  getRecordChoices = () => {
    const record = this.getZapRecord();
    const sourceGives = Object.keys(record);
    return sourceGives.map((key: any) => {
      return { value: key, label: key };
    });
  };

  /**
   * Gets our input options.
   */
  getInputs = () => {
    const inputs = [];
    for (let i = 0; i < this.state.fieldCount; i++) {
      const importChoices = this.getRecordChoices();
      const exportChoices = this.getNeedChoices();
      const input = BuildFieldInput(i, importChoices, exportChoices);
      inputs.push(input);
    }
    return flatten(inputs);
  };

  /**
   * Get select choices for needs.
   */
  getNeedChoices = () => {
    const { task } = this.props;

    const step = task.import_step;
    const actionKey = step?.action;
    const type = model.ActionType.Write;
    const action = this.getAction(actionKey!, type);
    if (!action) return [];

    return this.props.actionState.currentNeeds
      .filter((need: model.Need) => {
        const custom = need.custom_field;
        const mapped = this.state.exportFields.includes(need.key);
        return custom && !mapped;
      })
      .map((need: model.Need) => {
        return { label: need.label, value: need.key };
      });
  };

  //-------------------------------
  // UI Builders
  //-------------------------------

  buildHeaderForStep = (step?: model.Step) => {
    if (!step) return;
    const appID = step.app;
    const actionKey = step.action;
    return this.buildHeader(appID!, actionKey!, model.ActionType.Read);
  };

  /**
   * Builds a col header.
   */
  buildHeader = (appID: string, actionKey: string, type: model.ActionType) => {
    const { appsByID } = this.props.appState;
    const app = appsByID[appID!];
    const action = this.getAction(actionKey!, type);
    return <AppHeader app={app} action={action!} type={type} />;
  };

  /**
   * Builds the action button row.
   */
  buildButtonRow = (formikProps: FormikProps<any>) => {
    return (
      <div className="zap-mapper-button-row d-flex justify-content-between">
        <Button
          title="Back"
          width={ButtonWidth.Large}
          variant="secondary"
          onClick={this.props.onCancel}
        />
        <Button
          title="Next"
          width={ButtonWidth.Large}
          variant="primary"
          onClick={formikProps.submitForm}
        />
      </div>
    );
  };

  buildHeaderRow = () => {
    const { task } = this.props;
    return (
      <div className="d-flex justify-content-between">
        {this.buildHeaderForStep(task.export_step)}
        {this.buildHeaderForStep(task.import_step)}
      </div>
    );
  };

  buildAddFieldRow = () => {
    return (
      <div className="d-flex justify-content-end">
        <Button
          title="Add Mapping"
          variant="secondary"
          onClick={this.handleAddClick}
        />
      </div>
    );
  };

  buildInputs = (children: any) => {
    return (
      <>
        {children}
        {this.buildAddFieldRow()}
      </>
    );
  };

  buildCard = (children?: any, formikProps?: FormikProps<any>) => {
    return (
      <div className="zap-mapper-container">
        {this.buildHeaderRow()}
        {this.buildInputs(children)}
        {this.buildButtonRow(formikProps!)}
      </div>
    );
  };

  render() {
    const { task } = this.props;
    if (!task) return <div />;

    const inputs = this.getInputs();
    const initialValues = {};

    return (
      <div className="app-field-list-container">
        <FormComponent
          enableReinitialize
          inputs={inputs}
          initialValues={initialValues}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          renderFunc={(children: any, formikProps: FormikProps<any>) => {
            return this.buildCard(children, formikProps);
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: RootReducerState) => ({
  taskState: state.task,
  appState: state.app,
  actionState: state.action,
  authsByID: state.auth.authsByID,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  const creators = {
    ...AppActions,
    ...TaskActions,
    ...ActionActions,
    ...NeedActions,
  };
  return bindActionCreators(creators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(FieldMapper);
