import './style.scss';

import React from 'react';
import * as RS from 'react-bootstrap';
import { Field, FieldProps } from 'formik';

// Components
import AppSelector from '../AppSelector';

// Inputs
import TextInput from './Text';
import SelectInput from './Select';

// Entity Specific Inputs
import AppSelectInput from './AppSelectInput';
import AuthSelectInput from './AuthSelectInput';
import ActionSelectInput from './ActionSelectInput';
import NeedSelectInput from './NeedSelectInput';

// Types
import { Input, InputType } from './types';

// Utils
import classname from '../../utils/classname';

type Props = {
  key?: string;
  input: Input;
  onOpen: (key: string, fieldProps: FieldProps) => void;
  onChange: (option: any, fieldProps: FieldProps) => void;
  onSearch: (search: string, fieldProps: FieldProps) => void;
  onError: (error: any) => void;
};

/**
 * Renders an input component based on the `input` property it receives.
 */
const InputComponent = (props: Props) => {
  /**
   * Builds an individual form input component.
   */
  const buildFormInput = (input: Input, fieldProps: FieldProps) => {
    switch (input.type) {
      case InputType.Text:
        return buildTextInput(input, fieldProps);
      case InputType.Select:
        return buildSelectInput(input, fieldProps);
      case InputType.AppGrid:
        return buildAppGridInput(input, fieldProps);
      case InputType.AppSelect:
        return buildAppSelectInput(input, fieldProps);
      case InputType.AuthSelect:
        return buildAuthSelectInput(input, fieldProps);
      case InputType.AuthAndAppSelect:
        return buildAuthAndAppSelectInput(input, fieldProps);
      case InputType.ActionSelect:
        return buildActionSelectInput(input, fieldProps);
      case InputType.NeedSelect:
        return buildNeedSelectInput(input, fieldProps);
      default:
    }
  };

  /**
   * Builds a simple text input component.
   */
  const buildTextInput = (input: Input, fieldProps: FieldProps) => {
    return (
      <TextInput
        key={input.key}
        input={input}
        fieldProps={fieldProps}
        onChange={props.onChange}
      />
    );
  };

  /**
   * Builds a select input component.
   */
  const buildSelectInput = (input: Input, fieldProps: FieldProps) => {
    return (
      <SelectInput
        key={input.key}
        input={input}
        fieldProps={fieldProps}
        onOpen={props.onOpen}
        onChange={props.onChange}
        onSearch={props.onSearch}
      />
    );
  };

  /**
   * Builds the app grid input.
   */
  const buildAppGridInput = (input: Input, fieldProps: FieldProps) => {
    const onAppSelected = (app: any) => {
      const option = { value: app };
      props.onChange(option, fieldProps);
    };
    return (
      <AppSelector
        key={input.key}
        iconSize={72}
        onAppSelected={onAppSelected}
      />
    );
  };

  /**
   * Builds a select input component.
   */
  const buildAppSelectInput = (input: Input, fieldProps: FieldProps) => {
    const { onError } = props;
    const { field } = fieldProps;
    return (
      <AppSelectInput
        key={input.key}
        app={field.value}
        fieldProps={fieldProps}
        onOpen={props.onOpen}
        onChange={props.onChange}
        onSearch={props.onSearch}
        onError={onError}
      />
    );
  };

  /**
   * Builds a select input component.
   */
  const buildAuthSelectInput = (input: Input, fieldProps: FieldProps) => {
    const { onError } = props;
    const { field, form } = fieldProps;
    return (
      <AuthSelectInput
        key={input.key}
        auth={field.value}
        app={form.values.app}
        fieldProps={fieldProps}
        onOpen={props.onOpen}
        onChange={props.onChange}
        onSearch={props.onSearch}
        onError={onError}
        authAndApp={false}
      />
    );
  };

  /**
   * Builds a select input component.
   */
  const buildAuthAndAppSelectInput = (input: Input, fieldProps: FieldProps) => {
    const { onError } = props;
    const { field, form } = fieldProps;
    return (
      <AuthSelectInput
        key={input.key}
        auth={field.value}
        app={form.values.app}
        fieldProps={fieldProps}
        onOpen={props.onOpen}
        onChange={props.onChange}
        onSearch={props.onSearch}
        onError={onError}
        authAndApp
      />
    );
  };

  /**
   * Builds a select input component.
   */
  const buildActionSelectInput = (input: Input, fieldProps: FieldProps) => {
    const { onError } = props;
    const { field, form } = fieldProps;
    return (
      <ActionSelectInput
        key={input.key}
        action={field.value}
        app={form.values.app}
        type={form.values.type}
        fieldProps={fieldProps}
        onOpen={props.onOpen}
        onChange={props.onChange}
        onSearch={props.onSearch}
        onError={onError}
      />
    );
  };

  /**
   * Builds a select input component.
   */
  const buildNeedSelectInput = (input: Input, fieldProps: FieldProps) => {
    const { onError } = props;
    const { field, form } = fieldProps;
    return (
      <NeedSelectInput
        key={input.key}
        need={field.value}
        app={form.values.app}
        auth={form.values.auth}
        action={form.values.action}
        needs={form.values.needs}
        type={form.values.type}
        record={form.values.record}
        fieldProps={fieldProps}
        onOpen={props.onOpen}
        onChange={props.onChange}
        onSearch={props.onSearch}
        onError={onError}
      />
    );
  };

  /**
   * Builds a Form Field component.
   */
  const buildFieldComponent = (input: Input, fieldProps: FieldProps) => {
    const child = buildFormInput(input, fieldProps);
    const inputs = [child];

    const { form } = fieldProps;
    if (form.errors && form.errors[input.key!]) {
      const message = form.errors[input.key!];
      inputs.push(
        <p className="error-message" key={`${input.key}-1`}>
          {message}
        </p>
      );
    }
    return inputs;
  };

  const buildLabel = (input: Input) => {
    if (!input.label) return;
    if (input.required) {
      return (
        <RS.Form.Label className="form-label">{input.label}</RS.Form.Label>
      );
    }
    return (
      <div className="d-flex">
        <RS.Form.Label className="form-label">{input.label}</RS.Form.Label>
        <p className="optional-label">(optional)</p>
      </div>
    );
  };
  /**
   * Builds a complete form input group, along with a label and error message.
   */
  const buildFormInputGroup = (input: Input) => {
    const label = buildLabel(input);
    const size = input.size || 12;
    const className = classname({
      'form-input-col': true,
      'constrain-width': size < 12,
    });

    return (
      <RS.Col className={className} key={input.key} sm={size}>
        <RS.Form.Group key={input.key}>
          {label}
          <Field key={input.key} name={input.key}>
            {(fieldProps: FieldProps) => buildFieldComponent(input, fieldProps)}
          </Field>
        </RS.Form.Group>
      </RS.Col>
    );
  };

  return buildFormInputGroup(props.input);
};

export default InputComponent;
