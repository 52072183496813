import './style.scss';

import * as React from 'react';
import * as RB from 'react-bootstrap';

import Classname from '../../utils/classname';

type Props = {
  large?: boolean;
  animation?: string;
};

/**
 * Renders a loading spinner.
 */
export const Spinner = (props: Props) => {
  let animation = "grow" as any
  if (props.animation) animation = props.animation
  const className = Classname({
    'transfer-spinner': true,
    'd-flex justify-content-center': true,
  });
  const size = props.large ? undefined : 'sm';
  return (
    <div className={className}>
      <RB.Spinner animation={animation} size={size} role="status">
        <span className="sr-only">Loading...</span>
      </RB.Spinner>
    </div>
  );
};

export default Spinner;
