import { indexBy } from 'underscore';
import { createSelector } from 'reselect';

// RootState
import { RootReducerState } from './index';

// Action
import { Actions as AppActions, TypeKeys as AppTypeKeys } from '../actions/app';
// import { Actions as ActionActions } from '../actions/action';
import {
  Actions as UserActions,
  TypeKeys as UserTypeKeys,
} from '../actions/user';
// Models
import * as model from '../models';
// import { App } from '../models';

export type AppState = typeof defaultState;

const defaultState = {
  appsByID: ({} as unknown) as { [key: string]: model.App },
  privateAppsByID: ({} as unknown) as { [key: string]: model.App },
};

function app(
  state = defaultState,
  action: AppActions | UserActions
): AppState {
  switch (action.type) {
    case UserTypeKeys.FETCHED_STATE:
    case AppTypeKeys.FETCHED_APPS: {
      action.apps.forEach((app: model.App) => sanitizeApp(app));
      const indexedApps = indexBy(action.apps, 'currentImplementationId');
      const indexedPrivateApps = indexBy((action as any).privateApps, 'id') as any as { [key: string]: model.App };
      return { ...state, appsByID: indexedApps, privateAppsByID: indexedPrivateApps };
    }

    case AppTypeKeys.FETCHED_APP: {
      const appID = action.app.selectedApi;
      const existing = state.appsByID[appID];
      const appToUpdate = existing ? existing : {};
      const updated = { ...appToUpdate, ...action.app };
      const app = sanitizeApp(updated);
      state.appsByID[appID] = app;
      return { ...state };
    }

    case AppTypeKeys.FETCHED_IMPLEMENTATIONS: {
      for (const impl of action.apps) {
        const appID = impl.selectedApi;
        const existing = state.appsByID[appID];
        const appToUpdate = existing ? existing : {};
        const updated = { ...appToUpdate, ...impl };
        const app = sanitizeApp(updated);
        state.appsByID[appID] = app;
      }
      return { ...state };
    }

    default:
      return state;
  }
}

/**
 * !!!Hacky Function Alert!!
 *
 * In order to work around the issue with private apps, whenever Transfer
 * fetches Apps it also fetches implementations for Private Apps for the user.
 * Implementation and App records are different, but Transfer effectively
 * treats them as the same model.
 *
 * One key difference between the two models is the currentImplementationID and the
 * selectedApi feature. They have different property names in the Zapier models, but
 * they represent the same value. So here, we are unsuring the every Transfer app record has a
 * currentImplementationId (implementations from Zapier API don't have one).
 *
 * Also, some private apps don't have names!
 * @param app
 */
const sanitizeApp = (app: model.App) => {
  if (!app.currentImplementationId) {
    app.currentImplementationId = app.selectedApi;
  }
  if (!app.name) {
    app.name = '';
  }
  return app;
};

const appsByID = (state: RootReducerState) => state.app.appsByID;
const privateAppsByID = (state: RootReducerState) => state.app.privateAppsByID;

/**
 * Map of apps keyed by their appID.
 * @returns { [id: string]: models.App }
 */
export const appListSelector = createSelector(
  [appsByID],
  (appsMap: { [key: string]: model.App }) => {
    if (!appsMap) return [];
    return Object.values(appsMap);
  }
);

export const privateAppListSelector = createSelector(
  [privateAppsByID],
  (appsMap: { [key: string]: model.App }) => {
    if (!appsMap) return [];
    return Object.values(appsMap);
  }
);

export default app;
