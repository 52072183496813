import { isObject, isString, isError } from 'underscore';

export const normalizeErrorMessage = (error: string | Error) => {
  if (isError(error) && error.message) {
    error = (error as Error).message;
  } else if (isObject(error) && (error as any).detail) {
    error = (error as any).detail;
  }

  if (isString(error) && (error as string).indexOf('ECONNREFUSED') > 0) {
    return "Hmmm... looks like we're having connection issues.";
  } if (isString(error)) {
    return error;
  }

  return 'Ouch... Something went wrong.';
};
