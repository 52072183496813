// Components
import { InputType, ZapRecord } from '../Inputs/types';

// Models
import * as model from '../../models';

import { FormType } from './helper';

export interface FormState {
  type: FormType;
  app?: model.App;
  action?: model.Action;
  auth?: model.Auth;
  record: ZapRecord;
  needs: { [key: string]: any };
  mapping: { [key: string]: any };
  search: { [key: string]: any };
}

export const BuildNeedInputs = (
  needs: model.Need[],
  type: model.ActionType
) => {
  return needs.map((need: model.Need) => {
    // KC - TODO Need to validate this is what we want.
    const readForm =
      type === model.ActionType.Read || type === model.ActionType.ReadBulk;
    const needKey = need.prefill && !need.searchfill;
    const key =
      needKey || readForm ? `needs.${need.key}` : `mapping.${need.key}`;
    const needInput = {
      key,
      label: need.label ? need.label : need.key,
      type: InputType.NeedSelect,
      required: need.required,
    };
    return needInput;
  });
};
