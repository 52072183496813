import * as Yup from 'yup';

import { InputType } from './types';

export const getInputConfigForType = (type: InputType) => {
  switch (type) {
    case InputType.AppGrid:
      return buildAppGridConfig();
    case InputType.AppSelect:
      return buildAppInputConfig();
    case InputType.AuthSelect:
      return buildAuthInputConfig();
    case InputType.AuthAndAppSelect:
      return buildAuthAndAppInputConfig();
    case InputType.ActionSelect:
      return buildActionInputConfig();
    case InputType.NeedSelect:
      return buildNeedInputConfig();
    default:
      return buildNeedInputConfig();
  }
};

const buildAppGridConfig = () => {
  return {
    key: 'app',
    type: InputType.AppGrid,
    required: true,
    validation: Yup.string().required('Please Select an App'),
  };
};

const buildAppInputConfig = () => {
  return {
    key: 'app',
    label: 'Choose an App',
    type: InputType.AppSelect,
    required: true,
    validation: Yup.string().required('Please Select an App'),
  };
};

const buildAuthInputConfig = () => {
  return {
    key: 'auth',
    label: 'Choose an Account',
    type: InputType.AuthSelect,
    required: true,
  };
};

const buildAuthAndAppInputConfig = () => {
  return {
    key: 'auth',
    label: 'Choose a Connection',
    type: InputType.AuthAndAppSelect,
    required: true,
  };
};

const buildActionInputConfig = () => {
  return {
    key: 'action',
    label: 'Choose an Resource',
    type: InputType.ActionSelect,
    required: true,
    validation: Yup.string().required('Please Select a Resource'),
  };
};

const buildNeedInputConfig = () => {
  return {
    key: 'needs',
    label: 'Configure App',
    type: InputType.NeedSelect,
    required: true,
  };
};
