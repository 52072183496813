import './style.scss';

import * as React from 'react';
import { FormikProps } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as RB from 'react-bootstrap';

// Actions
import { AppActions } from '../../actions/app';
import { AuthActions } from '../../actions/auth';
import { TaskActions } from '../../actions/task';
import { ActionActions } from '../../actions/action';
import { NeedActions } from '../../actions/need';

// Components
import Modal from '../Modals/Modal';
import AppIcon from '../AppIconButton';
import StepButton from '../StepButton';
import BaseSetupForm from '../BaseSetupForm';
import { FormState } from '../BaseSetupForm/inputs';
import { FormType } from '../BaseSetupForm/helper';
import Spinner from '../Spinner';

// Model
import * as model from '../../models';

// Form
import * as form from './inputs';

// Utils
import classname from '../../utils/classname';

export enum FormStep {
  SelectApp = 0,
  ConfigureApp,
  ConfigureNeeds,
  Confirmation,
}

type ConnectedActions = ReturnType<typeof mapDispatchToProps>;
type ComponentProps = {
  /**
   * Boolean to indicate if the modal should be shown or not.
   */
  show: boolean;

  /**
   * The Action Type for the form. IE Read or Write.
   */
  type: FormType;

  /**
   * Existing Task for the modal.
   */
  task?: model.Task;

  /**
   * Current step for the modal.
   */
  step?: FormStep;

  /**
   * Called when the modal is toggled.
   */
  onToggle: () => void;

  /**
   * Called when the modal is submitted.
   */
  onSubmit: (values: any) => Promise<void> | void;
};
type Props = ComponentProps & ConnectedActions;

const initialState = {
  loading: false,
  step: FormStep.ConfigureApp,
  formState: {} as FormState,
  errMessage: '',
};
type State = typeof initialState;

/**
 * Displays a modal for creating or editing a Task.
 */
class TaskSetupModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const state = { ...initialState };
    if (props.step) {
      state.step = props.step;
    }
    this.state = { ...state };
  }

  componentDidUpdate(prevProps: Props) {
    const { step } = this.props;
    if (step !== prevProps.step) {
      this.setState({ step: step! });
    }
  }

  //------------------------------------------------------
  // Form Change Handlers
  //------------------------------------------------------

  handleError = (error: any) => {
    this.setState({ errMessage: error.detail });
  };

  handleHideError = () => {
    this.setState({ errMessage: '' });
  };

  //------------------------------------
  // Modal Handlers
  //------------------------------------

  /**
   * Handles form submission.
   */
  handleSubmit = async (formState: FormState) => {
    this.setState({ loading: true });
    const confirmStep = this.state.step === FormStep.Confirmation;
    if (confirmStep && !formState.action) {
      await this.props.onSubmit(formState);
      return;
    }

    const configureStep = this.state.step === FormStep.ConfigureApp;
    if (configureStep && formState.action) {
      const step = FormStep.ConfigureNeeds;
      this.props.setCurrentAction(formState.action)
      const state = { formState, step, loading: false, errMessage: '' };
      this.setState(state);
      return;
    }

    const exportForm = this.props.type === FormType.Write;
    const configureFields = this.state.step === FormStep.ConfigureNeeds;
    if (exportForm && configureFields) {
      const step = FormStep.Confirmation;
      const state = { formState, step, loading: false, errMessage: '' };
      this.setState(state);
      return;
    }
    await this.props.onSubmit(formState);
  };

  /**
   * Handles toggling form.
   */
  handleToggle = () => {
    this.props.onToggle();
  };

  //------------------------------------
  // UI Builders
  //------------------------------------

  buildAlertComponent = (message: string, variant: string | any) => {
    return (
      <div className="alert-container">
        <RB.Alert
          variant={variant}
          onClose={() => this.handleHideError()}
          dismissible
        >
          {message}
        </RB.Alert>
      </div>
    );
  };

  buildAppStepButton = () => {
    const stepTitle = 'Choose app, action, & account';
    const onClick = () => this.setState({ step: FormStep.ConfigureApp });
    return <StepButton title={stepTitle} onClick={onClick} />;
  };

  buildNeedsStepButton = () => {
    const { app } = this.state.formState;
    const stepTitle = `Configure ${app!.name} Fields`;
    const onClick = () => this.setState({ step: FormStep.ConfigureNeeds });
    return <StepButton title={stepTitle} onClick={onClick} />;
  };

  /**
   * Builds the configure fields component.
   */
  buildConfigureFieldsComponent = (children: any) => {
    const { app } = this.state.formState;
    const appStepButton = this.buildAppStepButton();

    return (
      <div className="configure-fields-container">
        {appStepButton}
        <div className="configure-fields-form">
          <p className="form-heading">{`Configure ${app!.name} Fields`}</p>
          {children}
        </div>
      </div>
    );
  };

  /**
   * Builds the confirmation component.
   */
  buildConfirmationComponent = () => {
    const { action } = this.state.formState;
    const appStepButton = action ? this.buildAppStepButton() : null;
    const needsStepButton = action ? this.buildNeedsStepButton() : null;

    const message =
      'We are ready to run your Bulk Zap. Click below to confirm.';
    return (
      <div className="confirmation-container">
        {appStepButton}
        {needsStepButton}
        {this.buildAlertComponent(message, 'primary')}
      </div>
    );
  };

  /**
   * Builds the appropriate form children for the current form step.
   */
  buildChildrenForStep = (children: any) => {
    switch (this.state.step) {
      case FormStep.ConfigureNeeds:
        return this.buildConfigureFieldsComponent(children);
      case FormStep.Confirmation:
        return this.buildConfirmationComponent();
      case FormStep.ConfigureApp:
      default:
        return children;
    }
  };

  /**
   * Builds the appropriate form inputs for the current form step.
   */
  buildInputsForStep = () => {
    switch (this.state.step) {
      case FormStep.ConfigureApp:
        return form.BuildAppInputs();
      case FormStep.ConfigureNeeds:
        return form.BuildNeedInputs();
      case FormStep.SelectApp:
      case FormStep.Confirmation:
      default:
        return [];
    }
  };

  buildHeaderForStep = () => {
    const { app } = this.state.formState;
    if (!app) return null;

    let title;
    const importForm = this.props.type === FormType.Read;
    const action = importForm ? 'Import Records From' : 'Send Records To';
    const appName = app.name ? app.name : '...';
    title = `${action} ${appName}`;

    const confirmation = this.state.step === FormStep.Confirmation;
    if (confirmation) {
      title = 'Confirmation';
    }

    const className = classname({
      'd-flex align-items-center': true,
    });
    return (
      <div className={className}>
        <AppIcon app={app} />
        <p className="form-header-text">{title}</p>
      </div>
    );
  };

  getSubmitButtonTitle = () => {
    const { step } = this.state;
    const { type } = this.props;

    const fieldStep = step === FormStep.ConfigureNeeds;
    const importForm = type === FormType.Read;
    if (fieldStep && importForm) {
      return 'Import';
    }

    const confirmationStep = step === FormStep.Confirmation;
    if (confirmationStep && !importForm) {
      return 'Confirm';
    }
    return 'Next';
  };

  getModalClassForStep = () => {
    switch (this.state.step) {
      case FormStep.ConfigureNeeds:
      case FormStep.Confirmation:
        return 'export-modal-form no-padding';
      case FormStep.ConfigureApp:
      case FormStep.SelectApp:
      default:
        return 'export-modal-form';
    }
  };

  buildSubmitButton = (formikProps: FormikProps<any>) => {
    return {
      loading: this.state.loading,
      title: this.getSubmitButtonTitle(),
      variant: 'primary',
      action: formikProps.submitForm,
    };
  };

  buildCancelButton = () => {
    return {
      title: 'Cancel',
      variant: 'secondary',
      action: this.handleToggle,
    };
  };

  buildErrorAlert = () => {
    const { errMessage } = this.state;
    if (!errMessage) return;

    return this.buildAlertComponent(errMessage, 'danger');
  };

  /**
   * Helper function to build the modal component..
   */
  buildModal = (
    children: any,
    formikProps: FormikProps<any>,
    loading: boolean
  ) => {
    const className = this.getModalClassForStep();
    const importForm = this.props.type === FormType.Read;
    const header = this.buildHeaderForStep();
    const title = importForm ? 'Import Data' : 'Export Data';
    const submitButton = this.buildSubmitButton(formikProps);
    const cancelButton = this.buildCancelButton();
    const errorAlert = this.buildErrorAlert();
    const component = loading ? <Spinner /> : children;
    return (
      <Modal
        title={title}
        header={header}
        customClass={className}
        show={this.props.show}
        loading={formikProps.isSubmitting}
        submitButton={submitButton}
        cancelButton={cancelButton}
        onToggle={this.handleToggle}
      >
        {errorAlert}
        {component}
      </Modal>
    );
  };

  render() {
    const { task } = this.props;
    const inputs = this.buildInputsForStep();
    return (
      <BaseSetupForm
        type={this.props.type}
        inputs={inputs}
        task={task}
        onSubmit={this.handleSubmit}
        onError={this.handleError}
        renderFunc={(
          children: any,
          formikProps: FormikProps<any>,
          loading: boolean
        ) => {
          const stepChildren = this.buildChildrenForStep(children);
          return this.buildModal(stepChildren, formikProps, loading);
        }}
      />
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  const creators = {
    ...AppActions,
    ...AuthActions,
    ...TaskActions,
    ...ActionActions,
    ...NeedActions,
  };
  return bindActionCreators(creators, dispatch);
};

export default connect(null, mapDispatchToProps)(TaskSetupModal);
