import { find } from 'underscore'

// Actions
import { Actions as ActionActions, TypeKeys } from '../actions/action';

import { Actions as AppActions, TypeKeys as AppTypeKeys } from '../actions/app';
import {
  Actions as NeedActions,
  TypeKeys as NeedTypeKeys,
} from '../actions/need';

import {
  Actions as GiveActions,
  TypeKeys as GiveTypeKeys,
} from '../actions/give';

// Model
import * as model from '../models';
import { FormType } from '../components/BaseSetupForm/helper';

export type ActionState = typeof defaultState;

const defaultState = {
  actions: [] as model.Action[],

  // Can only have one current action in memory. Corresponding gives/needs
  currentAction: null as (null | model.Action),
  currentNeeds: [] as model.Need[],
  currentGives: [] as model.Give[],
};

export default (
  state = defaultState,
  a: ActionActions | NeedActions | AppActions | GiveActions
): undefined | ActionState => {
  switch (a.type) {
    case TypeKeys.FETCHED_ACTIONS_FOR_APP: {
      // Fetching actions will cause a reset of state
      return {
        actions: a.actions,
        currentAction: null,
        currentGives: [],
        currentNeeds: [],
      }
    }

    case AppTypeKeys.SET_CURRENT_ACTION:
      return {
        ...state,
        currentAction: a.action,
        currentNeeds: a.action.needs
      }

    case AppTypeKeys.FETCHED_NEED_RECORDS:
      const actionNeed = a.need
      if (state.currentNeeds) {
        const stateNeed = find(state.currentNeeds, (need: model.Need) =>
          need.action === actionNeed.action && need.key === actionNeed.key
        )
        stateNeed!.records = a.records
        return {
          ...state,
          currentNeeds: [...state.currentNeeds]
        }
      }
      return state

    case NeedTypeKeys.FETCHED_NEEDS_FOR_ACTION:
      return {
        ...state,
        currentNeeds: a.needs
      }

    case GiveTypeKeys.FETCHED_GIVES_FOR_ACTION:
      return {
        ...state,
        currentGives: a.gives
      }

    default:
      return state;
  }
}

/**
 * Convenience find
 * @param state
 * @param key
 * @param type
 */
export const ActionForKeyAndType = (state: ActionState, key: string, type: string): model.Action | undefined => {
  return state.actions.find((action: model.Action) => {
    if (action.key === key && action.type === type) {
      return action
    }
    return null
  })
}


/**
 * This compares action types on a 'softer' basis. eg We have a
 * `read_bulk` type on some actions which is really a read. Can accomodate
 * subsets of different action types in the future.
 * @param action
 */
export const SoftCompareActionType = (
  formType: FormType,
  actionType: model.ActionType
) => {
  // Reads can either be `read` or `read_bulk`
  if (formType === FormType.Read) {
    if (
      actionType === model.ActionType.Read ||
      actionType === model.ActionType.ReadBulk
    ) {
      return true; // Filter action is a `read` the action is a `read` or `read_bulk`
    }
  }
  if (formType === FormType.Write) {
    if (
      actionType === model.ActionType.Write ||
      actionType === model.ActionType.WriteBulk
    ) {
      return true; // Filter action is a `read` the action is a `read` or `read_bulk`
    }
  }
  return false;
};
