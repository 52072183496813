// External Dependencies
import { Dispatch, Action } from 'redux';

// Models
import * as model from '../models';

// Service
import Transfer from '../services/transfer';

const service = new Transfer();

// Actions
export enum TypeKeys {
  FETCHED_ACTIONS_FOR_APP = 'FETCHED_ACTIONS_FOR_APP',
}

export type Actions = FetchedActionsForApp;

// -----------------------------------------------------------------------------
// Fetch Actions
// -----------------------------------------------------------------------------

export interface FetchedActionsForApp extends Action {
  type: TypeKeys.FETCHED_ACTIONS_FOR_APP;
  actions: model.Action[];
  appID: string;
}

/**
 * Fetches actions for the given appID
 * @param appID A currentImplementationId value.
 * @discussion We itterate each action's needs and inject the action key value as well.
 */
const fetchActionsForApp = (appID: string) => async (
  dispatch: Dispatch<FetchedActionsForApp>
) => {
  const actions = await service.getActionsForApp(appID);
  const type = TypeKeys.FETCHED_ACTIONS_FOR_APP;
  const action = { type, actions, appID };
  dispatch(action);
};

// -----------------------------------------------------------------------------
// Exports
// -----------------------------------------------------------------------------

export const ActionActions = {
  fetchActionsForApp,
};
