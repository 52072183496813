import * as Yup from 'yup';
import * as model from '../../models';

export interface InputOption {
  label?: string;
  value?: any;
}

export interface InputOptionGroup {
  label?: string;
  options?: InputOption[];
}

export interface Input {
  id?: string;
  key?: string;
  size?: number;
  width?: number;
  type: InputType;
  placeholder?: string;
  label?: string;
  options?: InputOption[];
  groupedOptions?: InputOptionGroup[];
  notifyOnOpen?: boolean;
  required?: boolean;
  disabled?: boolean;
  helpText?: string;
  validation?: Yup.ObjectSchema | Yup.MixedSchema;
}

export interface ZapRecord {
  gives: model.Give[];
  noun?: string;
  appName?: string;
  appID?: string;
}

export enum InputType {
  Text = 1,
  Select,
  AppGrid,
  AppSelect,
  AuthSelect,
  AuthAndAppSelect,
  ActionSelect,
  NeedSelect,
}
