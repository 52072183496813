import './style.scss';

import * as React from 'react';
import { FormikProps } from 'formik';
import * as RB from 'react-bootstrap';

// Components
import Modal from '../Modal';
import FormComponent from '../../Form';

// Inputs
import { BuildInputs, FormState } from './inputs';

// Utils
import { sync } from '../../../../utils/sync';

enum TestState {
  Default = 0,
  Success,
  Failure,
}

type Props = {
  show: boolean;
  testRecord: any;
  onToggle: () => void;
  onSubmit: (url: string) => void;
  onTest: (url: string) => void;
};

const initialState = {
  testing: false,
  testState: TestState.Default,
  showTestScuccess: false,
};
type State = typeof initialState;

class WebhookModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...initialState };
  }

  //--------------------------------------
  // Button Handlers
  //--------------------------------------

  /**
   * Handles form submission.
   * @param form
   */
  handleSubmit = (form: FormState) => {
    this.props.onSubmit(form.url);
  };

  /**
   * Handle Test.
   */
  handleTest = async (formikProps: FormikProps<any>) => {
    const errors = await formikProps.validateForm();
    if (Object.keys(errors).length) return;

    this.setState({ testing: true });
    const webhookURL = formikProps.values.url;
    const body = JSON.stringify(this.props.testRecord);
    const opts = { method: 'POST', body };
    const [err] = await sync(fetch(webhookURL, opts));
    if (err) {
      this.updateTestState(TestState.Failure);
      return;
    }
    this.updateTestState(TestState.Success);
  };

  /**
   * Updates the test state, then updates to default after relad.
   */
  updateTestState = (state: TestState) => {
    this.setState({ testing: false, testState: state });
    setTimeout(() => {
      this.setState({ testState: TestState.Default });
    }, 5000);
  };

  //--------------------------------------
  // Button Builders.
  //--------------------------------------

  buildSubmitButton = (formikProps: FormikProps<any>) => {
    const title = 'SAVE';
    const action = formikProps.submitForm;
    const variant = 'primary';
    return { title, action, variant };
  };

  buildTestButton = (formikProps: FormikProps<any>) => {
    const title = 'TEST';
    const loading = this.state.testing;
    const action = () => this.handleTest(formikProps);
    const variant = 'outline-primary';
    return { title, action, variant, loading };
  };

  buildCancelButton = () => {
    const title = 'CANCEL';
    const action = this.props.onToggle;
    const variant = 'secondary';
    return { title, action, variant };
  };

  //--------------------------------------
  // Form Builders.
  //--------------------------------------

  buildAlert = () => {
    switch (this.state.testState) {
      case TestState.Success:
        const successMessage = 'Your test was successful!';
        return <RB.Alert variant={'primary'}>{successMessage}</RB.Alert>;
      case TestState.Failure:
        const failureMessage = 'Your test failed. Please try again.';
        return <RB.Alert variant={'danger'}>{failureMessage}</RB.Alert>;
      case TestState.Default:
        return;
    }
  };

  /**
   * BuildModal builds the modal.
   * @param children
   * @param formikProps
   */
  buildModal = (children: any, formikProps: FormikProps<any>) => {
    return (
      <Modal
        title="Export to Webhook"
        loading={false}
        submitButton={this.buildSubmitButton(formikProps)}
        secondaryButton={this.buildTestButton(formikProps)}
        cancelButton={this.buildCancelButton()}
        show={this.props.show}
        onToggle={this.props.onToggle}
      >
        {children}
        {this.buildAlert()}
      </Modal>
    );
  };

  buildForm = () => {
    const inputs = BuildInputs();
    return (
      <FormComponent
        enableReinitialize
        inputs={inputs}
        initialValues={{}}
        onSubmit={this.handleSubmit}
        renderFunc={(children: any, formikProps: FormikProps<any>) => {
          return this.buildModal(children, formikProps);
        }}
      />
    );
  };

  render() {
    return this.buildForm();
  }
}

export default WebhookModal;
