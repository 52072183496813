import { indexBy, groupBy } from 'underscore';
import { createSelector } from 'reselect';

// RootState
import { RootReducerState } from './index';

// Actions
import { Actions, TypeKeys } from '../actions/auth';
import {
  Actions as UserActions,
  TypeKeys as UserTypeKeys,
} from '../actions/user';

// Models
import * as models from '../models';

export type AuthState = typeof defaultState;

const defaultState = {
  authsByID: {} as { [key: string]: models.Auth },
  fetching: false,
};

function auth(state = defaultState, action: Actions | UserActions): AuthState {
  switch (action.type) {
    case TypeKeys.CLEAR_AUTHS:
      return { ...state, authsByID: {} };
    case TypeKeys.FETCHING_AUTHS:
      return { ...state, fetching: true };
    case UserTypeKeys.FETCHED_STATE:
    case TypeKeys.FETCHED_AUTHS:
      const { auths } = action;
      const filtered = auths.filter((auth: models.Auth) => !auth.is_stale);
      const indexed = indexBy(filtered, 'id');
      const authsByID = { ...state.authsByID, ...indexed };
      return { ...state, authsByID, fetching: false };
    default:
      return state;
  }
}

const authsByID = (state: RootReducerState) => state.auth.authsByID;

/**
 * Map of auths keyed by their id.
 * @returns { [id: string]: models.App }
 */
export const authsByAppIDSelector = createSelector(
  authsByID,
  (authsMap: { [key: string]: models.Auth }) => {
    if (!authsByID) return {};
    const allAuths = Object.values(authsMap);
    for (const auth of allAuths) {
      console.log("Auth here: ", (auth as any).selectedApi)
    }
    return groupBy(allAuths, 'selected_api');
  }
);

export default auth;
