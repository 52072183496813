import './style.scss';

import React from 'react';
import { flatten } from 'underscore';
import { FieldProps } from 'formik';
import { SingleValueProps, OptionProps, InputActionMeta } from 'react-select';
import CreatableSelect from 'react-select/creatable';


// Components
import { Input, InputOption, InputOptionGroup } from '../types';

// Utils
import { Colors } from '../../../utils/constants';

// Models
import * as model from '../../../models';

// Options
import { ValueComponent, OptionComponent } from './CustomComponents';
import { OptionGroupHeader } from './OptionGroupHeader';

type Props = {
  input: Input;
  fieldProps: FieldProps;
  app?: model.App;
  onSearch: (search: string, fieldProps: FieldProps) => void;
  onOpen: (key: string, fieldProps: FieldProps) => void;
  onChange: (option: any, fieldProps: FieldProps) => void;
};

/**
 * SelectInput renders a select input for use in Formik.
 */
const SelectInput = (props: Props) => {
  const { input, fieldProps, onChange } = props;
  const { field } = fieldProps;

  //----------------------------------------
  // Select Handlers
  //----------------------------------------

  /**
   * Notifes parents that a select was opened.
   */
  const handleFocus = (event: React.FocusEvent<HTMLElement>) => {
    if (event.type === 'blur') return;

    const { id } = event.target;
    return props.onOpen(id, props.fieldProps);
  };

  /**
   * Handles search input.
   */
  const handleInputChange = (newValue: string, change: InputActionMeta) => {
    if (change.action === 'input-change') {
      props.onSearch(newValue, props.fieldProps);
    }
  };

  //----------------------------------------
  // Custom Select Components
  //----------------------------------------

  const Option = (optionProps: OptionProps<any>) => {
    return OptionComponent(optionProps, props.app!);
  };

  const SingleValue = (valueProps: SingleValueProps<any>) => {
    return ValueComponent(valueProps, props.app!);
  };

  const GroupHeading = (optionProps: OptionProps<any>) => {
    return <OptionGroupHeader optionProps={optionProps} app={props.app} />;
  };

  //----------------------------------------
  // Helpers
  //----------------------------------------

  const getDefaultValue = () => {
    const { value } = field;
    const valueExists = value !== undefined && value !== null;
    if (input.options && valueExists) {
      return getDefaultValueForOptions(input.options);
    }
    if (input.groupedOptions && valueExists) {
      return getDefaultValueForGroupedOptions(input.groupedOptions);
    }
    return null;
  };

  const getDefaultValueForOptions = (options: InputOption[]) => {
    return options.find((choice: InputOption) => {
      if (choice.value.id) {
        return choice.value.id === field.value.id;
      }
      return choice.value === field.value;
    });
  };

  const getDefaultValueForGroupedOptions = (groups: InputOptionGroup[]) => {
    const allOptions = groups.map((g: InputOptionGroup) => g.options);
    const flattened = flatten(allOptions);
    return getDefaultValueForOptions(flattened);
  };

  //----------------------------------------
  // Select Styles
  //----------------------------------------

  const styles = {
    container: (provided: any) => ({
      ...provided,
      borderRadius: '4px',
      border: '1px solid var(--light-gray-color)',
    }),
    control: (provided: any, state: any) => {
      const focusedAndOpen = state.isFocused && state.menuIsOpen;
      return {
        ...provided,
        boxShadow: focusedAndOpen ? '0 0 0 3px var(--blue-color)' : 'none',
        border: 'none',
        borderColor: 'transparent',
        borderRadius: '0.25rem',
        overflow: 'hidden',
      };
    },
    valueContainer: (provided: any) => ({
      ...provided,
      padding: '0.5rem 0.75rem',
      backgroundColor: Colors.InputGray,
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      padding: '0.25rem 0.75rem',
      backgroundColor: Colors.InputGray,
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      backgroundColor: 'transparent',
    }),
  };

  const options = input.options || input.groupedOptions;
  const value = getDefaultValue();
  return (
    <CreatableSelect
      id={input.key}
      isClearable
      name={field.name}
      options={options}
      styles={styles}
      components={{ SingleValue, Option, GroupHeading }}
      value={value}
      onBlur={field.onBlur}
      onFocus={handleFocus}
      onInputChange={handleInputChange}
      isDisabled={input.disabled}
      onChange={(option: any) => {
        onChange(option, fieldProps);
      }}
    />
  );
};

export default SelectInput;
