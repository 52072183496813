import './style.scss';

import * as React from 'react';
import { Icon, Zap, Database, ChevronRight } from 'react-feather';

import Modal from '../Modal';

export enum ExportOption {
  App = 'app',
  Webhook = 'webhook',
}
type Props = {
  show: boolean;
  onToggle: () => void;
  onClick: (option: ExportOption) => void;
};

export const ExportOptionMenu = (props: Props) => {
  /**
   * Builds a menu option.
   */
  const buildMenuItemWithOption = (option: ExportOption) => {
    const onClick = () => props.onClick(option);
    let icon, title, subtitle;
    switch (option) {
      case ExportOption.App:
        icon = Database;
        title = 'Export to App';
        subtitle = 'Export data to a Zapier app.';
        break;
      case ExportOption.Webhook:
        icon = Zap;
        title = 'Export to Zap';
        subtitle = 'Export data to Webhook Zap.';
        break;
      default:
        icon = Zap;
        title = 'Export to Zap';
        subtitle = 'Export data to a Zapier app.';
        break;
    }
    return buildMenuItem(icon, title, subtitle, onClick);
  };

  const buildMenuItem = (
    icon: Icon,
    title: string,
    subtitle: string,
    onClick: any
  ) => {
    const FeatherIcon = icon;
    return (
      <div
        className="option-container d-flex align-items-center"
        onClick={onClick}
      >
        <div className="option-icon-container">
          <FeatherIcon className="option-icon" size={36} />
        </div>
        <div className="option-text-container">
          <h5>{title}</h5>
          <p>{subtitle}</p>
        </div>
        <div className="option-chevron-container">
          <ChevronRight size={24} />
        </div>
      </div>
    );
  };

  return (
    <Modal
      title="Export Data"
      loading={false}
      show={props.show}
      onToggle={props.onToggle}
    >
      <div className="export-menu-container">
        {buildMenuItemWithOption(ExportOption.App)}
        {buildMenuItemWithOption(ExportOption.Webhook)}
      </div>
    </Modal>
  );
};

export default ExportOptionMenu;
