import * as React from 'react';
import { connect } from 'react-redux';
import { FieldProps } from 'formik';
import { isEmpty } from 'underscore';
import { bindActionCreators, Dispatch } from 'redux';
import { debounce } from 'underscore';

// Actions
import { AppActions } from '../../../actions/app';

// State
import { RootReducerState } from '../../../reducers';
import { appListSelector, privateAppListSelector } from '../../../reducers/app';

// Models
import * as model from '../../../models';

// Components
import SelectInput from '../Select';

// Utils
import * as Logger from '../../../../utils/logger';
import { SPINNER_OPTION } from '../../../utils/form';
import { sync } from '../../../../utils/sync';

// Types
import { Input, InputType, InputOption } from '../types';

type ComponentProps = {
  /**
   * The selected app for the component.
   */
  app?: model.App;

  /**
   * Formik field props for the component.
   */
  fieldProps: FieldProps;

  /**
   * On Open handler. Called when the select is opened.
   */
  onOpen: (key: string, fieldProps: FieldProps) => void;

  /**
   * On Change handler. Called when a selection is made.
   */
  onChange: (option: any, fieldProps: FieldProps) => void;

  /**
   * On Search handler. Called when user input is made.
   */
  onSearch: (search: string, fieldProps: FieldProps) => void;

  /**
   * On Error handler.
   */
  onError: (error: any) => void;
};
type ConnectedState = ReturnType<typeof mapStateToProps>;
type ConnectedActions = ReturnType<typeof mapDispatchToProps>;
type Props = ComponentProps & ConnectedState & ConnectedActions;

const initialState = {
  /**
   * Search filter form the component.
   */
  filter: '',
};
type State = typeof initialState;

/**
 * Select component for selecing an App.
 */
class AppSelectInput extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...initialState };
  }

  //-----------------------------------------
  // Handlers
  //-----------------------------------------

  /**
   * Handles user search input.
   */
  handleSearch = (search: string, fieldProps: FieldProps) => {
    this.setState({ filter: search });
    if (!this.props.onSearch) return;
    this.props.onSearch(search, fieldProps);
  };

  //-----------------------------------------
  // Network Requests
  //-----------------------------------------

  /**
   * Fetches apps. Optionally with a search filter.
   */
  fetchApps = async () => {
    const { filter } = this.state;
    const [err] = await sync(this.props.fetchApps(filter) as any);
    if (err) {
      Logger.LogInfo('Failed to `refreshAppState` with err:', err);
      this.props.onError(err);
    }
  };

  //-----------------------------------------
  // Data Helpers
  //-----------------------------------------

  /**
   * Returns an array of InputOptions for available apps.
   * @discussion Returns a SPINNER_OPTION if the apps don't exist.
   * This implies we are fetching the apps.
   */
  public getAppChoices = (): InputOption[] => {
    const { apps, privateApps } = this.props;
    if (isEmpty(apps)) return SPINNER_OPTION;

    const { filter } = this.state;
    const lowerFilter = filter.toLowerCase();

    const filteredApps = apps.filter((a: model.App) => {
      const lowerName = a.name.toLowerCase();
      return lowerName.includes(lowerFilter);
    });
    const filteredPrivateApps = privateApps.filter((a: model.App) => {
      const lowerName = a.name.toLowerCase();
      return lowerName.includes(lowerFilter);
    });
    const filtered = filteredApps.concat(filteredPrivateApps)
    const sliced = filtered.slice(0, 25);
    return sliced.map((app: model.App) => ({ label: app.name, value: app }));
  };

  /**
   * Helper for building an input.
   */
  buildInput = (): Input => {
    return {
      id: 'app-select',
      type: InputType.Select,
      key: 'app',
      placeholder: 'Select...',
      label: 'Choose an app',
      required: true,
      options: this.getAppChoices(),
    };
  };

  render() {
    const input = this.buildInput();
    const { fieldProps, onOpen, onChange } = this.props;

    const handleSearch = debounce(this.handleSearch, 250);
    return (
      <SelectInput
        key={input.key}
        input={input}
        fieldProps={fieldProps}
        onOpen={onOpen}
        onChange={onChange}
        onSearch={handleSearch}
      />
    );
  }
}

const mapStateToProps = (state: RootReducerState) => ({
  apps: appListSelector(state),
  privateApps: privateAppListSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  const creators = {
    ...AppActions,
  };
  return bindActionCreators(creators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AppSelectInput);
