// Model
import { find } from 'underscore';
import * as model from '../models';

import { FormType } from '../components/BaseSetupForm/helper';

// Types
import {
  Input,
  InputOption,
  InputType,
  ZapRecord,
} from '../components/Inputs/types';

// Form Utils
import { SPINNER_OPTION } from './form';

export type Prefil = {
  actionKey: string;
  targetKey: string;
  labelKey: string;
};

/**
 * Builds an input for a need.
 * @param need The need.
 * @param record A source record.
 */
export const buildInput = (need: model.Need, record: any, type: FormType) => {
  if (!need) {
    return buildSpinnerInput();
  }

  // If it is a custom field or a searchfill, we use gives as the choices.
  const needLabel = need.label ? need.label : '';
  const updateField = needLabel.toLowerCase().includes('update');
  if (need.custom_field && need.searchfill && updateField) {
    return buildGivesInput(need, record, type);
  }
  // We have a boolean input.
  if (need.type === 'bool') {
    return buildBooleanInput(need);
  }

  // We have explicit choices.
  if (need.choices) {
    return buildChoicesInput(need);
  }

  // We have a dynamic input.
  if (need.prefill) {
    return buildDynamicInput(need);
  }

  // Fallback to text for reads.
  if (type === FormType.Read) {
    return buildTextInput(need);
  }

  // Fallback to params for write.
  return buildGivesInput(need, record, type);
};

/**
 * Builds input config for a need!
 */
export const buildGivesInput = (
  need: model.Need,
  record: ZapRecord,
  type: FormType
) => {
  const label = `${record.noun} from ${record.appName}`;
  const options = getChoicesFromZapRecord(record);
  const groupedOptions = [{ label, options }];
  const key = type === FormType.Read ? 'needs' : 'mapping';
  return {
    id: need.key,
    type: InputType.Select,
    key: `${key}.${need.key}`,
    placeholder: 'Type or insert...',
    label: need.label ? need.label : need.key,
    options: record ? undefined : options,
    groupedOptions: record ? groupedOptions : undefined,
    optional: need.required,
    helpText: need.help_text,
  };
};

/**
 * Builds a boolean input config.
 */
export const buildBooleanInput = (need: model.Need) => {
  const options = getChoicesForBoolean();
  return {
    id: need.key,
    type: InputType.Select,
    key: `needs.${need.key}`,
    placeholder: 'Select...',
    label: need.label ? need.label : need.key,
    options,
    required: need.required,
    helpText: need.help_text,
  };
};

/**
 * Builds a boolean input config.
 */
export const buildSpinnerInput = () => {
  return {
    id: 'need-id',
    type: InputType.Select,
    key: 'needs',
    placeholder: 'Select...',
    label: 'Need',
    options: SPINNER_OPTION,
  };
};

/**
 * Builds a choices input config.
 */
export const buildChoicesInput = (need: model.Need) => {
  const options = getChoicesForChoices(need.choices);
  return {
    id: need.key,
    type: InputType.Select,
    key: `needs.${need.key}`,
    placeholder: 'Select...',
    label: need.label ? need.label : need.key,
    options,
    required: need.required,
    helpText: need.help_text,
  };
};

/**
 * Builds input config for a dynamic need.
 */
export const buildDynamicInput = (need: model.Need): Input => {
  const options = getChoicesForRecords(need);
  return {
    id: need.key,
    type: InputType.Select,
    key: `needs.${need.key}`,
    placeholder: 'Select...',
    label: need.label ? need.label : need.key,
    options,
    required: need.required,
    helpText: need.help_text,
  };
};

/**
 * Builds input config for a text need.
 */
export const buildTextInput = (need: model.Need) => {
  return {
    id: need.key,
    type: InputType.Text,
    key: `needs.${need.key}`,
    placeholder: 'Select...',
    label: need.label ? need.label : need.key,
    optional: need.required,
    helpText: need.help_text,
  };
};

/**
 * Returns an array of InputOptions for need record choices.
 * @discussion Returns a SPINNER_OPTION if no records exist.
 * This implies we are fetching auths and/or an app.
 */
const getChoicesForRecords = (need: model.Need): InputOption[] => {
  const { records, prefill } = need;
  if (!records) return SPINNER_OPTION;

  const parsed = parsePrefil(prefill, records[0]);
  return records.map((record: any) => {
    const targetValue = record[parsed.targetKey];
    let displayValue = record[parsed.labelKey];
    if (!displayValue) {
      const fallbackKey = getLabelKey(record, 'No Name');
      displayValue = record[fallbackKey];
    }
    return { value: targetValue, label: displayValue };
  });
};

const getChoicesForChoices = (choices: any): InputOption[] => {
  if (typeof choices === 'string') {
    const options = choices.split(',');
    return options.map((key: string) => {
      return { value: key, label: key };
    });
  }

  if (choices.length) return choices;
  const choiceKeys = Object.keys(choices);
  return choiceKeys.map((key: string) => {
    return { value: key, label: choices[key] };
  });
};

/**
 * Builds choices based on Zap Record keys.
 *
 * For the time being, we are inferring gives for the import app
 * based on an actual record. We are doing this until we can
 * actually fetch dynamic gives.
 */
export const getChoicesFromZapRecord = (record: ZapRecord) => {
  if (!record || !record.gives) return SPINNER_OPTION;
  return record.gives.map((give: model.Give) => {
    const label = give.label ? give.label : give.key;
    return { value: give.key, label };
  });
};

const getChoicesForBoolean = () => {
  return [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ];
};

export const parsePrefil = (prefil: string, record: any): Prefil => {
  const split = prefil.split('.');
  const actionKey = split[0];
  const targetKey = split[1];
  const labelKey = split.length > 2 ? split[2] : getLabelKey(record, split[1]);
  return { actionKey, targetKey, labelKey };
};

export const getLabelKey = (record: any, fallback: string) => {
  if (!record) return fallback;
  const defaults = [
    'name',
    'Name',
    'display',
    'Display',
    'title',
    'Title',
    'subject',
    'Subject',
    'id',
  ];
  const key = find(defaults, defaultKey => {
    return record[defaultKey] && record[defaultKey] !== '';
  });
  return key || fallback;
};
