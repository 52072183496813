// External Dependencies
import { Dispatch, Action } from 'redux';

// Models
import * as model from '../models';

// Service
import Transfer from '../services/transfer';

const service = new Transfer();

// Actions
export enum TypeKeys {
  FETCHED_GIVES_FOR_ACTION = 'FETCHED_GIVES_FOR_ACTION',
}

export type Actions = FetchedGivesAction;

// -----------------------------------------------------------------------------
// Fetch Gives
// -----------------------------------------------------------------------------

export interface FetchedGivesAction extends Action {
  type: TypeKeys;
  gives: model.Give[];
}

const fetchGivesForStep = (step: model.Step) => async (
  dispatch: Dispatch<FetchedGivesAction>
): Promise<void> => {
  const actionKey = step.action;
  const actionType = step.action_type! as model.ActionType;
  const appID = step.app!;
  const auth = step.auth!;
  if (!actionKey || !appID || !auth) throw new Error('Invalid params');
  const gives = await service.getGivesForAction(
    actionKey!,
    actionType,
    appID,
    step.needs,
    auth,
  );

  // Update the needs for our action.
  const type = TypeKeys.FETCHED_GIVES_FOR_ACTION;
  dispatch({ type, gives });
};

export const GiveActions = {
  fetchGivesForStep,
};
