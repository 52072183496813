// Internal Dependencies
import { Actions, TypeKeys } from '../actions/nav';

export type NavState = typeof defaultState;

const defaultState = {
  showNav: true,
};

function flash(state = defaultState, action: Actions) {
  switch (action.type) {
    case TypeKeys.SHOW_NAV:
      return { showNav: true };
    case TypeKeys.HIDE_NAV:
      return { showNav: false };
    default:
      return state;
  }
}
export default flash;
