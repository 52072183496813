import './style.scss';

import * as React from 'react';
import { Zap } from 'react-feather';

// Models
import { App } from '../../models/app';

// Utils
import Classname from '../../utils/classname';
import { Colors } from '../../utils/constants';

type Props = {
  app?: App;
  webhookURL?: string;
  icon?: any;
  size?: number;
  border?: boolean;
  radius?: number;
  padding?: number;
  onClick?: (app?: App) => void;
};

const DEFAULT_SIZE = 48;
const DEFAULT_PADDING = 8;
const DEFAULT_RADIUS = 12;
/**
 * Renders a button containing an app icon or Zap icon id none exists.
 */
class AppIcon extends React.Component<Props, any> {
  /**
   * Handles clicks on an app icon.
   */
  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick(this.props.app);
    }
  };

  /**
   * Builds an icon image.
   */
  getImageOrIcon = () => {
    if (this.props.webhookURL) {
      return <Zap size={24} color={Colors.Orange} />;
    }
    if (!this.props.app || !this.props.app?.id || !this.props.app?.image) {
      return <Zap size={24} />;
    }
    const imageURL = this.props.app?.image;
    return <img className="icon-img" src={imageURL} alt="icon" />;
  };

  render() {
    const size = this.props.size || DEFAULT_SIZE;
    const padding = this.props.padding || DEFAULT_PADDING;
    const radius = this.props.radius || DEFAULT_RADIUS;

    const className = Classname({
      'app-icon-container': true,
      'd-flex': true,
      'justify-content-center': true,
      'align-items-center': true,
    });
    return (
      <div
        className={className}
        style={{
          width: `${size}px`,
          minWidth: `${size}px`,
          height: `${size}px`,
          minHeight: `${size}px`,
          padding: `${padding}px`,
          borderRadius: `${radius}px`,
        }}
      >
        {this.getImageOrIcon()}
      </div>
    );
  }
}

export default AppIcon;
