import './style.scss';

import * as React from 'react';
import * as RB from 'react-bootstrap';
import { connect } from 'react-redux';

// Utils
import Classname from '../../../utils/classname';

// State
import { RootReducerState } from '../../../reducers';
import { appListSelector } from '../../../reducers/app';

import { TaskStep, TaskSetupStep } from '../shared';

// Props
type ConnectedState = ReturnType<typeof mapStateToProps>;
type ComponentProps = {
  currentStepIndex: number;
  steps: TaskStep[];
  onStepClick: (step: TaskSetupStep) => void;
};
type Props = ConnectedState & ComponentProps;

// State
const initialState = {};
type State = typeof initialState;

/**
 * Renders a nav bar with steps for the TaskSetup component.
 */
class TaskSetupNav extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...initialState };
  }

  buildNavLink = (setupStep: TaskStep, index: number) => {
    if (setupStep.step === TaskSetupStep.Confirmation) return;
    const { onStepClick, currentStepIndex } = this.props;
    const className = Classname({
      'task-setup-col': true,
      'd-flex': true,
      'justify-content-center': true,
      'align-items-center': true,
      'nav-link': true,
      active: currentStepIndex === index,
      complete: currentStepIndex > index,
    });
    const formType = setupStep.formType;
    const labels = setupStep.labels;
    const title = labels.nav[formType];
    return (
      <RB.Col
        className={className}
        key={title}
        onClick={() => onStepClick(index)}
      >
        <div className="number-conatainer d-flex justify-content-center align-items-center">
          <p className="task-setup-number-label">{index + 1}</p>
        </div>
        <p className="task-setup-nav-label">{title}</p>
      </RB.Col>
    );
  };

  buildNavCols = () => {
    const { steps } = this.props;
    return steps.map((step: TaskStep, index: number) => {
      return this.buildNavLink(step, index);
    });
  };

  render() {
    const className = Classname({
      'task-setup-nav-container': true,
      'd-flex': true,
      'justify-content-center': true,
    });
    return (
      <RB.Container className={className}>
        <RB.Row className="task-setup-nav-row">{this.buildNavCols()}</RB.Row>
      </RB.Container>
    );
  }
}

const mapStateToProps = (state: RootReducerState) => ({
  appState: state.app,
  apps: appListSelector(state),
});

export default connect(mapStateToProps, {})(TaskSetupNav);
