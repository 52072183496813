import './style.scss';

import * as React from 'react';
import * as RB from 'react-bootstrap';
import * as RF from 'react-feather';
import { connect } from 'react-redux';

// Components
import AppIcon from '../../common/components/AppIconButton';
import Button, { ButtonWidth } from '../../common/components/Button';
import TaskProgressBar from '../TaskProgressBar';

// Models
import { Task } from '../../common/models/task';

// Utils
import Classname from '../../common/utils/classname';

// State
import { RootReducerState } from '../../common/reducers';
import { appListSelector } from '../../common/reducers/app';

// Props
type ConnectedState = ReturnType<typeof mapStateToProps>;
type ComponentProps = {
  task: Task;
  running?: boolean;
  onClick: () => void;
  onRunClick?: () => void;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
};
type Props = ConnectedState & ComponentProps;

// State
const initialState = {};
type State = typeof initialState;

/**
 * Renders a row that represents a Task
 */
class TaskRow extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...initialState };
  }

  handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const classname: string = (event.target as any).className;
    if (classname && classname.includes('task-row-container')) {
      this.props.onClick();
    }
  };

  /**
   * Builds the icon container.
   */
  buildIconContainer = () => {
    const { task } = this.props;
    const { appsByID } = this.props.appState;

    const exportAppID = task.export_step?.app;
    const importAppID = task.import_step?.app;
    const source = appsByID[exportAppID!];
    const destination = importAppID ? appsByID[importAppID!] : undefined;
    return (
      <div className="icon-container d-flex align-items-center">
        <AppIcon app={source} padding={10} />
        <div className="task-row-chevron">
          <RF.ChevronRight size={20} />
        </div>
        <AppIcon app={destination} padding={10} webhookURL={task.webhook_url} />
      </div>
    );
  };

  /**
   * Builds the Zap title container.
   */
  buildZapTitleContainer = () => {
    const taskName = this.props.task.name;
    const lastUpdated = new Date().toLocaleDateString();

    return (
      <div className="task-title-container" onClick={this.handleClick}>
        <h5 className="task-title-label">{taskName || 'Untitled Task'}</h5>
        <p className="task-subtitle-label">{`Last Updated: ${lastUpdated}`}</p>
      </div>
    );
  };

  /**
   * Builds the action button container.
   */
  buildActionButtonContainer = () => {
    return (
      <div className="icon-container d-flex">
        {this.buildRunButton()}
        {this.buildMenuButton()}
      </div>
    );
  };

  buildRunButton = () => {
    return (
      <Button
        loading={this.props.running}
        customClass="task-row-button"
        width={ButtonWidth.Medium}
        title="Run"
        variant="outline-primary"
        icon={RF.Play}
        onClick={this.props.onRunClick}
      />
    );
  };

  buildMenuButton = () => {
    const { onEditClick, onDeleteClick } = this.props;
    return (
      <RB.Dropdown className="task-menu-dropdown">
        <RB.Dropdown.Toggle
          className="task-dropdown-toggle"
          variant="outline-secondary"
          id="dropdown-basic"
        />
        <RB.Dropdown.Menu alignRight>
          {this.buildMenuItem(RF.Edit, 'Edit', onEditClick)}
          <RB.Dropdown.Divider />
          {this.buildMenuItem(RF.XCircle, 'Delete', onDeleteClick)}
        </RB.Dropdown.Menu>
      </RB.Dropdown>
    );
  };

  buildMenuItem = (icon: RF.Icon, title: string, onClick: any) => {
    const FeatherIcon = icon;
    return (
      <RB.Dropdown.Item className="d-flex align-items-center" onClick={onClick}>
        <FeatherIcon className="dropdown-icon" size={20} />
        {title}
      </RB.Dropdown.Item>
    );
  };

  render() {
    const className = Classname({
      'task-row-container': true,
      'd-flex ': true,
      'align-items-center': true,
      'justify-content-between': true,
    });
    return (
      <RB.Row
        key="task-row-container"
        className="task-row"
        onClick={this.handleClick}
      >
        <TaskProgressBar task={this.props.task} />
        <div className={className}>
          <div className="d-flex">
            {this.buildIconContainer()}
            {this.buildZapTitleContainer()}
          </div>
          {this.buildActionButtonContainer()}
        </div>
      </RB.Row>
    );
  }
}

const mapStateToProps = (state: RootReducerState) => ({
  appState: state.app,
  apps: appListSelector(state),
});

export default connect(mapStateToProps, {})(TaskRow);
