// External Dependencies
import { Dispatch, Action } from 'redux';

// Service
import Transfer from '../services/transfer';

// Model
import * as model from '../models';

const service = new Transfer();

// Actions
export enum TypeKeys {
  FETCHING_AUTHS = 'FETCHING_AUTHS',
  FETCHED_AUTHS = 'FETCHED_AUTHS',
  VALIDATED_ATUH = 'VALIDATED_ATUH',
  CLEAR_AUTHS = 'CLEAR_AUTHS',
}

export type Actions =
  | FetchedAuthsAction
  | FetchingAuthsAction
  | ClearAuthsAction;

// -----------------------------------------------------------------------------
// Fetch Auths
// -----------------------------------------------------------------------------
export interface FetchingAuthsAction extends Action {
  type: TypeKeys.FETCHING_AUTHS;
}

export interface FetchedAuthsAction extends Action {
  type: TypeKeys.FETCHED_AUTHS;
  auths: model.Auth[];
}

const fetchAuths = (selected_api?: string) => async (
  dispatch: Dispatch<FetchingAuthsAction | FetchedAuthsAction>
) => {
  dispatch({ type: TypeKeys.FETCHING_AUTHS });
  const auths = await service.getAuths(selected_api);
  dispatch({ type: TypeKeys.FETCHED_AUTHS, auths });
};

// -----------------------------------------------------------------------------
// Test Auth
// -----------------------------------------------------------------------------

export interface ValidatedAuthAction extends Action {
  type: TypeKeys.VALIDATED_ATUH;
  auth: model.Auth;
}

const validateAuth = (auth: model.Auth) => async (
  dispatch: Dispatch<ValidatedAuthAction>
) => {
  await service.validateAuth(auth.id);
  dispatch({ type: TypeKeys.VALIDATED_ATUH, auth });
};

// -----------------------------------------------------------------------------
// Clear Auths
// -----------------------------------------------------------------------------

export interface ClearAuthsAction extends Action {
  type: TypeKeys.CLEAR_AUTHS;
}

const clearAuths = () => async (dispatch: Dispatch<ClearAuthsAction>) => {
  dispatch({ type: TypeKeys.CLEAR_AUTHS });
};

// -----------------------------------------------------------------------------
// Exports
// -----------------------------------------------------------------------------

export const AuthActions = {
  clearAuths,
  fetchAuths,
  validateAuth,
};
