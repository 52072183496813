/* eslint-disable no-param-reassign */

import { keys, isObject, isArray, isString, mapObject } from 'underscore';

/**
 * Regex for testing the zapier templated hashes
 *
 * {{913__COL$B}} => COL$B
 * {{913__COL$B__foo}} => COL$B__foo
 * {{913__COL$B__foo__3}} => COL$B__foo__3
 */

/**
 * Return a modified nodeWriteParams given a set of input params
 * eg
 * nodeWriteParams: {text: "Yo, my name is {{5555_foo}}, what's {{5555_bar_baz}}"}
 * inputParams: {foo: "Taylor", bar: { baz: 'up'}}
 * => {text: "Yo, my name is Taylor, what's up"}
 * With and `inputNode
 * @param inputParams
 * @param nodeWriteParams
 */
export const mapInputParamsToNodeWriteParams = (
  inputParams: { [key: string]: any },
  inputNodeID: number,
  nodeWriteParams: { [key: string]: any }
): { [key: string]: any } => {
  const flattenedInputParams = flattenNestedParams(inputParams);
  return mapFlattenedParamsToNodeParams(
    flattenedInputParams,
    inputNodeID,
    nodeWriteParams
  );
};

/**
 * Same as `mapInputParamsToNodeWriteParams` with a flattened set of input params
 * We check for a strings for templating, and any plainObjects,
 * or arrays to further search for any templated strings
 * @param inputParams
 * @param nodeWriteParams
 */
const mapFlattenedParamsToNodeParams = (
  inputParams: { [key: string]: any },
  inputNodeID: number,
  nodeWriteParam: any
): any => {
  // Test for String and Regex
  if (isString(nodeWriteParam)) {
    let writeParamStr = nodeWriteParam.slice(); // Copy. Don't want to mutate original!
    const regPattern = `{{${inputNodeID}__([^}}]*)}}`;
    const reg = new RegExp(regPattern, 'g');
    const matches = writeParamStr.match(reg);
    if (!matches || !matches.length) {
      return writeParamStr;
    }

    // Iterate through our matches
    for (const match of matches!) {
      const parts = new RegExp(regPattern, 'g').exec(match);
      const extractedVar = parts![1];
      writeParamStr = writeParamStr.replace(match, inputParams[extractedVar]);
    }
    return writeParamStr;
  }
  if (isObject(nodeWriteParam) && !isArray(nodeWriteParam)) {
    // Test for object
    return mapObject(nodeWriteParam, (_: string, key: string) =>
      mapFlattenedParamsToNodeParams(
        inputParams,
        inputNodeID,
        nodeWriteParam[key]
      )
    );
  }
  if (isArray(nodeWriteParam)) {
    // Test for object
    const copy = [];
    for (const val of nodeWriteParam) {
      copy.push(mapFlattenedParamsToNodeParams(inputParams, inputNodeID, val));
    }
    return copy;
  }
  return nodeWriteParam; // :shrug:
};

/**
 * Takes an object of {a: {foo: bar}, b: baz} => {a__foo: bar, b: baz}
 * ... A version of this would be a good programming interview q. Theres a few ways to tackle it
 * @param candidate
 */
export const flattenNestedParams = (
  inputParams: { [key: string]: any },
  flattenedParams: { [key: string]: any } = {},
  keyPrefix = ''
): { [key: string]: any } => {
  if (isObject(inputParams) && !isArray(inputParams)) {
    for (const key of keys(inputParams)) {
      const keyWithPrefix = `${keyPrefix}${key}`;
      if (isObject(inputParams[key]) && !isArray(inputParams[key])) {
        // obj
        flattenNestedParams(
          inputParams[key],
          flattenedParams,
          `${keyWithPrefix}__`
        );
      } else if (isArray(inputParams[key])) {
        // array
        flattenedParams[keyWithPrefix] = inputParams[key];
        for (let i = 0; i < flattenedParams[keyWithPrefix].length; i++) {
          flattenedParams[keyWithPrefix][i] = flattenNestedParams(
            flattenedParams[keyWithPrefix][i]
          );
        }
      } else if (isString(inputParams[key])) {
        // str
        flattenedParams[keyWithPrefix] = inputParams[key].slice();
      } else {
        flattenedParams[keyWithPrefix] = inputParams[key]; // 🤷‍♀️
      }
    }
    return flattenedParams;
  }
  return inputParams;
};
