// External Dependencies
import { Dispatch, Action } from 'redux';

// Models
import { App } from '../models/app';
import { Need } from '../models/need';
import { Action as ActionModel } from '../models/action';

import * as model from '../models';

// Service
import Transfer from '../services/transfer';

const service = new Transfer();

// Actions
export enum TypeKeys {
  FETCHED_APPS = 'FETCHED_APPS',
  FETCHED_IMPLEMENTATIONS = 'FETCHED_IMPLEMENTATIONS',
  FETCHED_APP = 'FETCHED_APP',
  FETCHED_NEED_RECORDS = 'FETCHED_NEED_RECORDS',
  FETCHED_GIVE_RECORDS = 'FETCHED_GIVE_RECORDS',
  SET_CURRENT_ACTION = 'SET_CURRENT_ACTION',
}

export type Actions =
  FetchedAppsAction |
  FetchedAppAction |
  FetchedRecordsForNeedAction |
  FetchedImplementations |
  SetCurrentAction;

// -----------------------------------------------------------------------------
// Set Current Action
// -----------------------------------------------------------------------------

export interface SetCurrentAction extends Action {
  type: TypeKeys.SET_CURRENT_ACTION;
  action: ActionModel;
}

const setCurrentAction = (action: ActionModel) => async (
  dispatch: Dispatch<SetCurrentAction>
) => {
  const type = TypeKeys.SET_CURRENT_ACTION;
  dispatch({ type, action });
};

// -----------------------------------------------------------------------------
// Fetch Apps
// -----------------------------------------------------------------------------

export interface FetchedAppsAction extends Action {
  type: TypeKeys.FETCHED_APPS;
  apps: App[];
}

const fetchApps = (filter?: string) => async (
  dispatch: Dispatch<FetchedAppsAction>
) => {
  // Fetch the rest of the apps.
  const apps = await service.getApps(filter);
  const type = TypeKeys.FETCHED_APPS;
  dispatch({ type, apps });
};

// -----------------------------------------------------------------------------
// Fetch App
// -----------------------------------------------------------------------------

export interface FetchedAppAction extends Action {
  type: TypeKeys.FETCHED_APP;
  app: App;
}

const fetchApp = (appID: string) => async (
  dispatch: Dispatch<FetchedAppAction>
) => {
  const app = await service.getApp(appID);
  if (!app.selectedApi) return;

  const type = TypeKeys.FETCHED_APP;
  dispatch({ type, app });
};

// -----------------------------------------------------------------------------
// Fetch Private Apps
// -----------------------------------------------------------------------------

const fetchPrivateApps = () => async (
  dispatch: Dispatch<FetchedImplementations>
) => {
  const apps = await service.getPrivateApps();
  // We dispatch an action for each app because private apps are actually implementation records.
  // These records have actions on them. The action reducer listens for `FETCHED_APP` redux actions.
  dispatch({ type: TypeKeys.FETCHED_IMPLEMENTATIONS, apps });
};

export interface FetchedImplementations extends Action {
  type: TypeKeys.FETCHED_IMPLEMENTATIONS;
  apps: App[];
}

// -----------------------------------------------------------------------------
// Fetch Records for Need
// -----------------------------------------------------------------------------

export interface FetchedRecordsForNeedAction extends Action {
  type: TypeKeys.FETCHED_NEED_RECORDS;
  need: model.Need;
  records: any[]
}

const fetchRecordsForNeed = (
  selectedAPI: string,
  need: Need,
  params: any,
  authID?: number,
) => async (dispatch: Dispatch<FetchedRecordsForNeedAction>) => {
  if (!need) return;

  const action_key = need.prefill ? need.prefill : need.key;

  const records = await service.getRecords(
    action_key,
    selectedAPI,
    params || {},
    'read',
    authID,
  );

  const type = TypeKeys.FETCHED_NEED_RECORDS;
  dispatch({ type, need, records });
};

// -----------------------------------------------------------------------------
// Exports
// -----------------------------------------------------------------------------

export const AppActions = {
  fetchApps,
  fetchApp,
  fetchPrivateApps,
  fetchRecordsForNeed,
  setCurrentAction
};
