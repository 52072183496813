import './style.scss';

import * as React from 'react';
import * as formik from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { flatten } from 'underscore';

// Actions
import * as actions from '../../actions';

// State
import { RootReducerState } from '../../reducers';

// Components
import FormComponent from '../Form';
import { Input, InputOption, ZapRecord, InputType } from '../Inputs/types';

// Model
import * as model from '../../models';

// Form
import * as form from './inputs';
import { FormType } from './helper';
import TaskStateHelper from './state';

// Utils
import { sync } from '../../../utils/sync';

type ComponentProps = {
  /**
   * Inputs for the form.
   */
  inputs: Input[];

  /**
   * The Action Type for the form. IE Read or Write.
   */
  type: FormType;

  /**
   * Existing Task for the modal.
   */
  task?: model.Task;

  /**
   * Called when a change happens in the form.
   */
  onChange?: (option: InputOption, fieldProps: formik.FieldProps) => any;

  /**
   * Called when the modal is submitted.
   */
  onSubmit?: (values: any) => Promise<void> | void;

  /**
   * Called if the component encounters an error.
   */
  onError?: (error: any) => void;

  /**
   * Indicates whether or not form state should clear on Submit.
   */
  clearStateOnSubmit?: boolean;

  /**
   * Boolean which indicated if we should ignore dynamic needs or not.
   */
  ignoreDynamicNeeds?: boolean;

  /**
   * Called when rendering.
   */
  renderFunc?: (
    children: any,
    formikProps: formik.FormikProps<any>,
    loading: boolean
  ) => any;
};

type ConnectedState = ReturnType<typeof mapStateToProps>;
type ConnectedActions = ReturnType<typeof mapDispatchToProps>;
export type Props = ComponentProps & ConnectedState & ConnectedActions;

const initialState = {
  /**
   * The appID for the form.
   */
  appID: '',

  /**
   * The authID for the form.
   */
  authID: '',

  /**
   * The action key for the form.
   */
  actionKey: '',

  /**
   * The action type for the form.
   */
  actionType: '' as model.ActionType,

  /**
   * The needs for the form.
   */
  needs: {} as { [key: string]: any },

  /**
   * The mapping for the form.
   */
  mapping: {} as { [key: string]: any },

  /**
   * The search values for the form.
   */
  search: {} as { [key: string]: any },

  /**
   * The error message for the form.
   */
  errMessage: '',
  /**
   * Boolean that indicates whether or not the component is loading.
   */
  loading: false,
};
type State = typeof initialState;

/**
 * BaseSetupForm encapsulates logic needed to display a form component
 * that allows users to configure a BulkZap.
 *
 * Note - The component is designed to be embeded in a parent component.
 */
class BaseSetupForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...initialState };
  }

  //------------------------------------
  // Lifecycle Calls
  //------------------------------------

  componentDidMount = () => {
    this.setupZapState();
  };

  componentDidUpdate(prevProps: Props) {
    const { task, type } = this.props;
    const zapChanged = task !== prevProps.task;
    const typeChanged = type !== prevProps.type;

    // If the zap or form type change, we reset our state.
    if (zapChanged || typeChanged) {
      this.setupZapState();
    }
  }

  //------------------------------------
  // Form Setup
  //------------------------------------

  /**
   * Sets up Zap state for the form.
   */
  setupZapState = async () => {
    const { task, type } = this.props;
    if (!task) return;

    this.setState({ loading: true });

    // Get the form state from the zap.
    const helper = new TaskStateHelper({ ...this.props });
    const zapState = await helper.getFormState(task, type);

    // KC IMPORTANT - Our `type` can change while we are waiting to fetch
    // zap state. So we need to ensure we have the same action type before updating
    // form state. If it changed, we bail.
    if (this.props.type !== type) {
      this.setState({ loading: false });
      return;
    }
    const state = { ...this.state, ...zapState };
    this.setState({ ...state, loading: false });
  };

  /**
   * Resets our form state.
   */
  resetState = () => {
    this.setState({ ...initialState });
  };

  //------------------------------------
  // Network Requests
  //------------------------------------

  /**
   * Fetches all needs for the current form values.
   */
  fetchNeedsForWriteAction = async () => {
    const { onError, ignoreDynamicNeeds } = this.props;
    if (ignoreDynamicNeeds) return;
    if (this.props.type === FormType.Read) return;

    const { appID, needs, search } = this.state;
    const action = this.getCurrentAction();
    const auth = this.getCurrentAuth();
    if (!appID || !action || !auth) return;

    // We include search here to work around searchfill
    const params = { ...needs, ...search };
    const fetchPromise = this.props.fetchNeedsForWriteAction(
      action,
      appID,
      params,
      auth,
    );
    const [err] = await sync(fetchPromise as any);
    if (err) {
      if (onError) onError(err);
      return;
    }
  };

  /**
   * Opens a modal to facilate setting up a new Auth with Zapier.
   */
  openSignInWindow = (appID: string) => {
    const t = this;
    const url = `https://zapier.com/engine/auth/start/${appID}/`;
    const target = '_blank';
    const features = 'toolbar=0,status=0,width=980,height=700';
    const authWindow = window.open(url, target, features);

    var timer = setInterval(async function () {
      if (authWindow && authWindow.closed) {
        clearInterval(timer);
        t.props.fetchAuths();
        t.setState({ authID: '' });
      }
    }, 500);
  };

  //------------------------------------------------------
  // Form Handlers
  //------------------------------------------------------

  /**
   * Handles rendering the form.
   */
  handleRender = (children: any, formikProps: formik.FormikProps<any>) => {
    if (!this.props.renderFunc) return children;
    return this.props.renderFunc(children, formikProps, this.state.loading);
  };

  /**
   * Handles form submit clicks.
   */
  handleSubmit = async (
    values: form.FormState,
    helpers: formik.FormikHelpers<form.FormState>
  ) => {
    if (!this.props.onSubmit) return;
    await this.props.onSubmit(values);

    // Clear State if we need to.
    if (this.props.clearStateOnSubmit) {
      this.resetState();
      helpers.resetForm();
    }
  };

  /**
   * Handles form changes.
   */
  handleChange = async (option: InputOption, fieldProps: formik.FieldProps) => {
    const { field } = fieldProps;

    if (this.props.onChange) {
      this.props.onChange(option, fieldProps);
    }

    if (field.name === 'app') {
      await this.handleAppChange(option.value);
    }

    if (field.name.includes('auth')) {
      await this.handleAuthChange(option.value);
    }

    if (field.name === 'action') {
      await this.handleActionChange(option.value);
    }

    if (field.name.includes('need')) {
      await this.handleNeedChange(option.value, field);
    }

    if (field.name.includes('mapping')) {
      await this.handleMappingChange(option.value, field);
    }
  };

  //------------------------------------------------------
  // Model Change Handlers
  //------------------------------------------------------

  /**
   * Handles changes to the App select input.
   */
  handleAppChange = async (app: model.App) => {
    // Reset state with our app.
    const appID = app.currentImplementationId;
    const state = { ...initialState, appID };
    this.setState(state);

    // Ensure we have actions fetched for our app.
    return this.props.fetchActionsForApp(appID);
  };

  /**
   * Handles changes to the Auth select input.
   */
  handleAuthChange = async (auth: model.Auth) => {
    this.setState({ authID: `${auth.id}` });
  };

  /**
   * Handles changes to a Action select input.
   */
  handleActionChange = (action: model.Action) => {
    const actionKey = action.key;
    const actionType = action.type;
    this.setState({ actionKey, actionType }, () => {
      this.fetchNeedsForWriteAction();
    });
  };

  /**
   * Handles changes to a Need select input.
   */
  handleNeedChange = (needValue: any, field: formik.FieldInputProps<any>) => {
    const needKey = field.name.split('.')[1];
    const needs = { ...this.state.needs };
    needs[needKey] = needValue;

    this.setState({ needs }, () => {
      this.fetchNeedsForWriteAction();
    });
  };

  /**
   * Handles changes to a dynamic need (mapping) select input.
   */
  handleMappingChange = (
    mappingValue: any,
    field: formik.FieldInputProps<any>
  ) => {
    const mappingKey = field.name.split('.')[1];
    const mapping = { ...this.state.mapping };
    mapping[mappingKey] = mappingValue;

    // Hack for searchfill workaroud.
    const search = { ...this.state.search };
    search[mappingKey] = mappingValue;

    this.setState({ mapping, search }, () => {
      this.fetchNeedsForWriteAction();
    });
  };

  //------------------------------------
  // Data Helpers
  //------------------------------------

  getCurrentApp = () => {
    const { appsByID } = this.props;
    const { appID } = this.state;
    if (!appID) return;
    return appsByID[appID];
  };

  getCurrentAuth = () => {
    const { authsByID } = this.props;
    const { authID } = this.state;
    if (!authID) return;
    return authsByID[authID];
  };

  getCurrentAction = () => {
    const { actionKey, actionType } = this.state;

    // Fetch our action from state.
    const helper = new TaskStateHelper({ ...this.props });
    return helper.getAction(actionKey, actionType);
  };

  //------------------------------------------------------
  // FORM SELECT HELPER FUNCTIONS
  //
  // The below are a set of helper functions which help
  // build InputOption arrays for Form select dropdowns.
  //------------------------------------------------------

  /**
   * Helper to build form values for our form.
   */
  getFormValues = (): form.FormState => {
    const { type } = this.props;
    const { needs, mapping, search } = this.state;

    const app = this.getCurrentApp();
    const action = this.getCurrentAction();
    const auth = this.getCurrentAuth();
    const record = this.getZapRecordForForm();
    return {
      type,
      app,
      action,
      auth,
      needs,
      mapping,
      record,
      search,
    };
  };

  /**
   * Builds the input config for TakSetupModal ConfigureNeeds form step.
   */
  public expandNeedInputs = () => {
    const action = this.getCurrentAction();
    if (!action) return [];

    return form.BuildNeedInputs(this.props.actionState.currentNeeds, action.type);
  };

  /**
   * Builds a ZapRecord object.
   */
  getZapRecordForForm = () => {
    const { task, appsByID } = this.props;
    let record = {} as ZapRecord;

    // App Info
    const appID = task?.export_step?.app;
    if (!appID) return record;

    const app = appsByID[appID];
    const appName = app.name;
    record = { ...record, appID, appName };

    // Action Info
    const key = task?.export_step?.action;
    const type = task?.export_step?.action_type!;
    const helper = new TaskStateHelper(this.props);
    const action = helper.getAction(key!, type);
    if (!action) return record;

    const noun = action.noun ? action.noun : action.name;
    const gives = this.props.actionState.currentGives;
    return { ...record, noun, gives };
  };

  /**
   * Expands Need inputs specifically. Every other input type has a 1:1 mapping
   * of model to input. Needs have multiple.
   */
  expandInputs = (inputs: Input[]) => {
    const expanded = inputs.map((input: Input) => {
      if (input.type === InputType.NeedSelect) {
        return this.expandNeedInputs();
      }
      return input;
    });
    return flatten(expanded);
  };

  render() {
    const { inputs, onError } = this.props;
    const expandedInputs = this.expandInputs(inputs);
    const initialValues = this.getFormValues();

    return (
      <FormComponent
        enableReinitialize
        inputs={expandedInputs}
        initialValues={initialValues}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        onError={onError}
        renderFunc={(children: any, formikProps: formik.FormikProps<any>) => {
          return this.handleRender(children, formikProps);
        }}
      />
    );
  }
}

const mapStateToProps = (state: RootReducerState) => ({
  taskState: state.task,
  actionState: state.action,
  appsByID: state.app.appsByID,
  authsByID: state.auth.authsByID,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  const creators = {
    ...actions.AppActions,
    ...actions.AuthActions,
    ...actions.TaskActions,
    ...actions.ActionActions,
    ...actions.NeedActions,
    ...actions.GiveActions,
  };
  return bindActionCreators(creators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(BaseSetupForm);
