// Render Prop
import React from 'react';
import * as Yup from 'yup';
import * as RB from 'react-bootstrap';
import { Formik, Form, FormikProps, FieldProps, FormikHelpers } from 'formik';

// Components
import InputComponent from '../Inputs';
import { Input } from '../Inputs/types';

type Props = {
  initialValues: any;
  inputs: Input[];
  enableReinitialize: boolean;
  onSubmit?: (values: any, helper: FormikHelpers<any>) => Promise<void> | void;
  onOpen?: (key: string, fieldProps: FieldProps) => void;
  onChange?: (value: any, fieldProps: FieldProps) => void;
  onSearch?: (search: string, fieldProps: FieldProps) => void;
  onError?: (error: any) => void;

  // Allows parent components to embed forms.
  // Allows for hooking up buttons to the form component etc.
  renderFunc: (
    children: any,
    formProps: FormikProps<any>,
    loading?: boolean
  ) => any;
};

/**
 * Renders a Formik form component with a few modifications.
 */
class FormComponent extends React.Component<Props, any> {
  /**
   * Handles changes in an input component. Also notifies parent component as well.
   */
  handleChange = (value: any, fieldProps: FieldProps) => {
    const { field, form } = fieldProps;
    if (!value) return;

    if (form) {
      form.setFieldValue(field.name, value.value);
    }
    if (this.props.onChange) {
      this.props.onChange(value, fieldProps);
    }
  };

  /**
   * Handles opens in our form select components.
   */
  handleOpen = async (key: string, fieldProps: FieldProps) => {
    if (!this.props.onOpen) return;
    return this.props.onOpen(key, fieldProps);
  };

  /**
   * Handles submitting our form.
   */
  handlSubmit = async (values: any, helpers: FormikHelpers<any>) => {
    if (!this.props.onSubmit) return;
    return this.props.onSubmit(values, helpers);
  };

  /**
   * Handles submitting our form.
   */
  handlSearch = async (search: string, fieldProps: FieldProps) => {
    if (!this.props.onSearch) return;
    return this.props.onSearch(search, fieldProps);
  };

  /**
   * Handles submitting our form.
   */
  handleError = async (error: any) => {
    if (!this.props.onError) return;
    return this.props.onError(error);
  };

  /**
   * Builds our acual form components.
   */
  buildFormComponents = (inputs: Input[]) => {
    const components = inputs.map((input: Input) => {
      return InputComponent({
        input,
        onChange: this.handleChange,
        onOpen: this.handleOpen,
        onSearch: this.handlSearch,
        onError: this.handleError,
      });
    });
    return (
      <Form>
        <RB.Container>
          <RB.Row className="justify-content-between">{components}</RB.Row>
        </RB.Container>
      </Form>
    );
  };

  /**
   * Builds a validation schema object for Formik.
   */
  buildValidationSchema = () => {
    const schema: any = {};
    this.props.inputs.forEach((input: Input) => {
      schema[input.key!] = input.validation;
    });
    return Yup.object().shape(schema);
  };

  render() {
    const schema = this.buildValidationSchema();
    return (
      <Formik
        enableReinitialize={this.props.enableReinitialize}
        initialValues={this.props.initialValues}
        onSubmit={this.handlSubmit}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(props: FormikProps<any>) => {
          const children = this.buildFormComponents(this.props.inputs);
          return this.props.renderFunc(children, props);
        }}
      </Formik>
    );
  }
}

export default FormComponent;
