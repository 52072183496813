import {Action, ActionType} from '../../models';
/**
 * This file provides temporary meta overrides given the context of a
 * ZapTemplate ID. Eventually, this information will be embedded in the meta
 * of the corresponding non-bulk action, but for now we're doing manual mapping
 */

export const remapSelectedAPIWithZapTemplateID = (zapTemplateID: number): string | null => {
    switch(zapTemplateID) {
        case 345779: // WooCommerce	Customers
            return 'WooCommerceCLIAPI@2.99.0';
        case 345785: // WooCommerce	Order
            return 'WooCommerceCLIAPI@2.99.0';
        case 345818: // Shopify	New Paid Order
            return null; // Non CLI App, but leaving incase
        case 345807: // Shopify	New Customer
            return null; // Non CLI App, but leaving incase
        case 345840: // Salesforce	New Record
            return 'SalesforceCLIAPI@1.2.0';
        case 345875: // Hubspot	New Contact
            return null;
    }
    return null;
  };


export const remapActionKeyWithZapTemplateID = (zapTemplateID: number): string | null => {
    switch(zapTemplateID) {
        case 345779: // WooCommerce	Customers
            return 'customer';
        case 345785: // WooCommerce	Order
            return 'order';
        case 345818: // Shopify	New Paid Order
            return 'order'; // Non CLI App, but leaving incase
        case 345807: // Shopify	New Customer
            return null; // Non CLI App, but leaving incase
        case 345840: // Salesforce	New Record
            return 'get_records';
        case 345875: // Hubspot	New Contact
            return null;
    }
    return null;
  };

export const remapActionTypeWithZapTemplateID = (zapTemplateID: number): ActionType | null => {
    switch(zapTemplateID) {
        case 345779: // WooCommerce	Customers
            return ActionType.ReadBulk;
        case 345785: // WooCommerce	Order
            return ActionType.ReadBulk;
        case 345818: // Shopify	New Paid Order
            return ActionType.ReadBulk;
        case 345807: // Shopify	New Customer
            return ActionType.ReadBulk;
        case 345840: // Salesforce	New Record
            return ActionType.ReadBulk;
        case 345875: // Hubspot	New Contact
            return null;
    }
    return null;
  };

interface AppAndAction {
    selected_api: string,
    action: Action
}

export const remapAppAndAction = (appAndAction: AppAndAction): AppAndAction => {
    if (!appAndAction.selected_api || !appAndAction.action.key || !appAndAction.action.type) {
        return appAndAction;
    }

    const result: AppAndAction = {
        action: {
            ...appAndAction.action
        },
        selected_api: appAndAction.selected_api
    };


    // Look for hardcoded overrides (FB Example Workaround)
    const versionlessApp = result.selected_api.split('@', 1)[0];
    switch(versionlessApp) {

        case 'SalesforceCLIAPI': // Salesforce
            result.selected_api = 'SalesforceCLIAPI@1.2.0';
            if (appAndAction.action.key === 'get_records_for_object' || appAndAction.action.key === 'new_record') {
                result.action.key = 'get_records';
                result.action.type = ActionType.ReadBulk;
            }
            break;
        case 'WooCommerceCLIAPI': // WooCommerce	Customers
            result.selected_api = 'WooCommerceCLIAPI@2.99.0';
            if (appAndAction.action.key === 'orderHook') {
                result.action.key = 'order';
                result.action.type = ActionType.ReadBulk;
            }
            break;
        case 'App123629CLIAPI': // https://admin.zapier.com/admin/developer_cli/appversion/260461/change/?_changelist_filters=q%3DApp123629CLIAPI
            result.selected_api = 'App123629CLIAPI@1.0.0';
            if (appAndAction.action.key === 'media') {
                result.action.key = 'media';
                result.action.type = ActionType.ReadBulk;
            }
            break;

    }

    // ReMap the Action baed on presence of `bulk_action` in the meta
    if (appAndAction.action?.meta?.bulk_action) {
        const actionPath = appAndAction.action.meta.bulk_action;
        const actionKey = actionPath.split('.').pop()!;
        result.action.key = actionKey;
        result.action.type = ActionType.ReadBulk;
    }


    return result;
  };
