import './style.scss';

import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

// Actions
import { AppActions } from '../../../actions/app';
import { TaskActions } from '../../../actions/task';
import { ActionActions } from '../../../actions/action';

// Reduces
import { ActionForKeyAndType } from '../../../reducers/action';

// State
import { RootReducerState } from '../../../reducers';

// Components
import AppIcon from '../../AppIcon';
import TaskSetupButtonContainer from '../TaskSetupButtonContainer';
import { TaskStep } from '../shared';
import { ButtonWidth } from '../../Button';
import TaskProgressBar from '../../../../components/TaskProgressBar';

// Utils
import Classname from '../../../utils/classname';

// Model
import * as model from '../../../models';

// Props
type ComponentProps = {
  task: model.Task;
  step: TaskStep;
  onConfirm: () => void;
  onCancel: () => void;
};
type ConnectedState = ReturnType<typeof mapStateToProps>;
type ConnectedProps = ReturnType<typeof mapDispatchToProps>;
type Props = ConnectedState & ConnectedProps & ComponentProps;

// State
const initialState = {};
type State = typeof initialState;

/**
 * Renders a confirmation component.
 */
class TaskSetupConfirmation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...initialState };
  }

  getAction = (
    actionKey: string,
    actionType: model.ActionType
  ) => {
    const { actionState } = this.props;
    return ActionForKeyAndType(actionState, actionKey, actionType);
  };

  //------------------------------------
  // UI Builders
  //------------------------------------

  buildProgressBar = (task: model.Task) => {
    if (!task.running) return;

    return (
      <div className="zap-progress-bar-container">
        <TaskProgressBar task={task} />
      </div>
    );
  };

  buildRow = (step: model.Step, subtitle: string) => {
    const { appsByID } = this.props;
    const appID = step.app!;
    const app = appsByID[appID];
    const actionKey = step.action;
    const actionType = step.action_type;
    const action = this.getAction(actionKey!, actionType!);

    return this.buildActionRow(app, action!, subtitle);
  };

  buildActionRow = (app: model.App, action: model.Action, subtitle: string) => {
    if (!app || !action) return;

    const title = `${action?.name} in ${app.name}`;
    const className = Classname({
      'confirmation-action-row': true,
      'd-flex': true,
      'align-items-center': true,
    });
    return (
      <div className={className}>
        <AppIcon app={app} />
        <div>
          <p className="action-subtitle">{subtitle}</p>
          <p className="action-title">{title}</p>
        </div>
      </div>
    );
  };

  buildConfirmationRows = () => {
    const { task } = this.props;
    return (
      <div className="confirmation-rows-container">
        {this.buildRow(task.export_step!, 'For Every')}
        <div className="divider" />
        {this.buildRow(task.import_step!, 'Zapier will do this ...')}
      </div>
    );
  };

  buildButtonContainer = () => {
    const leftButton = {
      title: 'Back',
      variant: 'transparent',
      onClick: this.props.onCancel,
      width: ButtonWidth.Medium,
    };
    const rightButton = {
      title: 'Done',
      variant: 'primary',
      onClick: this.props.onConfirm,
      width: ButtonWidth.Large,
    };
    return (
      <TaskSetupButtonContainer
        leftButton={leftButton}
        rightButton={rightButton}
      />
    );
  };

  render() {
    // We gran the updated zap out of state to ensure we get updates for our progress bar!.
    const { task, tasksByID } = this.props;
    const updated = tasksByID[task!.id!];
    return (
      <div className="confirmation-container">
        {this.buildProgressBar(updated)}
        {this.buildConfirmationRows()}
        {this.buildButtonContainer()}
      </div>
    );
  }
}

const mapStateToProps = (state: RootReducerState) => ({
  actionState: state.action,
  tasksByID: state.task.tasksByID,
  appsByID: state.app.appsByID,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  const creators = {
    ...AppActions,
    ...TaskActions,
    ...ActionActions,
  };
  return bindActionCreators(creators, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskSetupConfirmation);
