import './style.scss';

import * as React from 'react';
import * as RB from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

// State
import { RootReducerState } from '../../common/reducers';

// Actions
import { FlashActions } from '../../common/actions/flash';

// Utils
import Classname from '../../common/utils/classname';

// Props
type ConnectedState = ReturnType<typeof mapStateToProps>;
type ConnectedActions = ReturnType<typeof mapDispatchToProps>;
type Props = ConnectedState & ConnectedActions;

export const Flash = (props: Props) => {
  const { message, showFlash, flashType } = props.flashState;
  if (!showFlash) return <></>;

  const variant = flashType as any;
  const className = Classname({
    'flash-container': true,
    'd-flex': true,
    'justify-content-center': true,
  });
  return (
    <div className={className}>
      <RB.Alert key={flashType} variant={variant}>
        {message}
      </RB.Alert>
    </div>
  );
};

const mapStateToProps = (state: RootReducerState) => ({
  flashState: state.flash,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...FlashActions,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Flash);
