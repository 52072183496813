// External Deps
import React from 'react';
import qs from 'querystring'
import { connect } from 'react-redux';
import { Redirect } from '@reach/router';
import { isEmpty } from 'underscore';

import { LoginToTransfer } from '../services/session'

// Reducers
import { RootReducerState } from '../common/reducers';

/**
 * ProtectedPath is a connected HOC for checking if we have
 * any user state. Assuming that (for now) indicates an active session.
 *
 * @param props {object}
 * @returns {}
 */
const ProtectedPath = (params: any) => {
  const { user, location } = params;
  const queryParams = qs.parse(location.search || '')
  const sourceIsZapMenu = queryParams['utm_source'] === 'zap_menu'
  const Komponent: React.ElementType = params.component;
  const allowedRender = (<Komponent className='router1s' {...params} />); // prettier-ignore

  // Assuming that (for now) presence of the user in state is enough
  // to assume we have an active session.
  if (!user || !user.me || isEmpty(user.me)) {
    if (sourceIsZapMenu) {
      // Attempt to auto-oauth them
      LoginToTransfer()
      return <></>;
    }

    return <Redirect noThrow to="/login" />;
  }
  return <>{allowedRender}</>;
};

/**
 * UnprotectedPath is simple HOC for passing through a component
 *
 * @param props {object}
 * @returns {}
 */
export const UnprotectedPath = (params: any) => {
  const Komponent: React.ElementType = params.component;
  const allowedRender = (<Komponent className='test' {...params} />); // prettier-ignore
  return <>{allowedRender}</>;
};

const mapStateToProps = (state: RootReducerState) => {
  const { user } = state;

  return {
    user,
  };
};

export const ProtectedLink = connect(mapStateToProps)(ProtectedPath);
