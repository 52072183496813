import ReactGA from 'react-ga';
import { FullStoryAPI } from 'react-fullstory';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { WindowLocation } from '@reach/router';
import * as model from '../models';

import {UNAUTHORIZED_ACCESS_NOTIFICATION} from './fetch';

declare global {
  interface Window {
    analytics: any; // Segment
    drift: any; // Drift chat
  }
}

export enum Page {
  Login = 'Login',
  MyZaps = 'My Zaps',
  Editor = 'Editor',
}

export enum Action {
  LoginClick = 'LoginClick',
  LoginSuccess = 'LoginSuccess',

  LogoutClick = 'LogoutClick',
  LogoutSuccess = 'LoginSuccess',

  ViewZapClick = 'ViewZapClick',
  NewZapClick = 'NewZapClick',
  RunZapClick = 'RunZapClick',
  UpdateZapClick = 'UpdateZapClick',
  EditZapClick = 'EditZapClick',
  DeleteZapClick = 'DeleteZapClick',

  ImportSubmitClick = 'ImportSubmit',
  ExportToApp = 'ExportToApp',
  ExportToAppSubmit = 'ExportToAppSubmit',
  ExportToWebhook = 'ExportToWebhookClick',
  ExportToWebhookSubmit = 'ExportToWebhookSubmit',
  ConfirmRunClick = 'ConfirmRunClick',

  ExportDataClick = 'ExportDataClick',
  ColumnHeaderClick = 'ColumnHeaderClick',
  ExpandDataClick = 'ExpandDataClick',

  ProfileClick = 'ProfileClick',
  ClickFixZap = 'ClickFixZap',
}

export default class Analytics {
  public static init = () => {
    ReactGA.initialize('UA-177610578-1', {
      debug: false,
      titleCase: false,
    });

    Sentry.init({
      dsn: 'https://b5dffe686472437099fdfb943a69d74f@sentry.io/2277689',
      environment: process.env.NODE_ENV,
      enabled: process.env.NODE_ENV === 'production',
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
      maxBreadcrumbs: 20,
      sendDefaultPii: true,
      ignoreErrors: [
        UNAUTHORIZED_ACCESS_NOTIFICATION
      ]
    } as any);

  };

  /**
   * Tracks a user session.
   */
  public static identifyUser = (user: model.User) => {
    if (!user) {
      Analytics.logUserOut();
      FullStoryAPI('identify', `${0}`, {});
      Sentry.configureScope(scope => {
        scope.setUser(null);
      });
    }

    // Segment
    if (window.analytics) {
      window.analytics.identify(user.id, {
        ...user,
      });
    }


    // Full Story
    FullStoryAPI('identify', `${user.id}`, {
      email: user.email,
      firstName: user.first_name,
      last_name: user.last_name,
      custom_user_id: user.zapier_customuser_id,
    });

    Sentry.configureScope(scope => {
      scope.setUser({
        ...user,
        id: `${user.id}`,
      });
    });

    ReactGA.set({
      userId: `${user.id}`,
      email: user.email,
      firstName: user.first_name,
      last_name: user.last_name,
    });

    if (window.Appcues) {
      window.Appcues.identify(`${user.id}`, {
        createdAt: Date.now(), // Unix timestamp of user signup date
        firstName: user.first_name, // current user's first name
        companyName: 'Acme Corp', // Current user’s company name
        email: user.email,
      });
    }
  };

  /**
   * Logs a user out of Analytics
   */
  public static logUserOut = () => {
    FullStoryAPI('identify', `${0}`, {});
    Sentry.configureScope(scope => {
      scope.setUser(null);
    });
  };

  /**
   * Tracks a page view.
   */
  public static trackPage = (location?: WindowLocation) => {
    if (location) {
      ReactGA.pageview(location.pathname + location.search);
      if (window.analytics) {
        window.analytics.page(location.pathname);
      }
    }
  };

  /**
   * Tracks an action.
   */
  public static trackAction = (
    action: Action,
    info?: { [key: string]: any }
  ) => {
    if (ReactGA) {
      ReactGA.event({ action, category: action });
    }
    if (window.analytics) {
      window.analytics.track(action, info);
    }
  };
}
