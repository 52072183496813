import './style.scss';

import React from 'react';

import Button, { ButtonWidth } from '../../../Button';
import AppIcon from '../../../AppIcon';

// Models
import * as model from '../../../../models';

type Props = {
  app: model.App;
  title: string;
  onClick: any;
};

const NewAuthButton = (props: Props) => {
  const { app, title, onClick } = props;
  return (
    <Button
      onClick={onClick}
      width={ButtonWidth.FullWidth}
      style={{ height: '48px' }}
    >
      <div className="auth-button-container d-flex justify-content-between align-items-center">
        <AppIcon app={app} size={32} padding={4} radius={4} />
        <p className="auth-button-label">{title}</p>
        <AppIcon app={app} size={32} padding={4} radius={4} />
      </div>
    </Button>
  );
};

export default NewAuthButton;
