// External Dependencies
import { combineReducers, AnyAction } from 'redux';

// Reducers
import user, { UserState } from './user';
import action, { ActionState } from './action';
import app, { AppState } from './app';
import auth, { AuthState } from './auth';
import task, { TaskState } from './task';
import flash, { FlashState } from './flash';
import nav, { NavState } from './nav';
import zap, { ZapState } from './zap';
import error, { ErrorState } from './error';

// Actions
import { TypeKeys as SessionActionKeys } from '../actions/session';

// Util
import { clearState } from '../store/local';

export interface RootReducerState {
  user: UserState;
  task: TaskState;
  auth: AuthState;
  app: AppState;
  action: ActionState;
  flash: FlashState;
  nav: NavState;
  zap: ZapState;
  error: ErrorState;
}

const rootReducer = (
  state: RootReducerState | undefined,
  reduxAction: AnyAction
) => {
  // Check if the User is signing out - clear the store if so
  if (reduxAction.type === SessionActionKeys.LOGGED_OUT) {
    clearState();
    // SentryController.removeUserContext();
    return appReducer(undefined, reduxAction);
  }

  return appReducer(state, reduxAction);
};

const appReducer = combineReducers<RootReducerState>({
  user,
  task,
  auth,
  app,
  action,
  flash,
  nav,
  zap,
  error,
} as any);

export default rootReducer;
