import * as React from 'react';
import * as RB from 'react-bootstrap';
import { FormikProps, FieldProps } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

// Actions
import * as actions from '../../../actions';

// State
import { RootReducerState } from '../../../reducers';

// Models
import * as model from '../../../models';

// Components
import BaseSetupForm from '../../BaseSetupForm';
import { FormType } from '../../BaseSetupForm/helper';
import ZapMapper from '../../ZapMapper';
import TaskSetupHeader, { IconType } from '../TaskSetupHeader';
import TaskSetupTest from '../TaskSetupTest';
import TaskSetupConfirmation from '../TaskSetupConfirmation';
import TaskSetupButtonContainer from '../TaskSetupButtonContainer';
import Spinner from '../../Spinner';
import { ButtonWidth } from '../../Button';

// Inputs
import { BuildInputsForSetup } from './inputs';
import { Input } from 'src/common/components/Inputs/types';

// Steps
import { TaskSetupStep, TaskStep } from '../shared';

type ComponentProps = {
  /**
   * Zap for the component.
   */
  task?: model.Task;

  /**
   * The current step for the component.
   */
  currentStep: TaskStep;

  /**
   * Notifies parent to changes in form.
   */
  onChange: (values: any) => void;

  /**
   * Notifies parent that next has been clicked.
   */
  onNext: () => void;

  /**
   * Notifies parent that back has been clicked.
   */
  onBack: () => void;

  /**
   * Notifies parent that cancel has been clicked.
   */
  onCancel: () => void;

  /**
   * Notifies parent that submit has been clicked.
   */
  onSubmit: (values: any) => void;

  /**
   * Indicated whether or not a `next click` should be implied by an app selection or not.
   */
  nextOnAppSelect: boolean;
};
type ConnectedState = ReturnType<typeof mapStateToProps>;
type ConnectedActions = ReturnType<typeof mapDispatchToProps>;
type Props = ComponentProps & ConnectedState & ConnectedActions;

const initialState = {
  /**
   * Boolean value to indicate whether or not a test step was succesful.
   */
  testSuccess: false,
  /**
   * Boolean value to indeicate whether or not a test step failed.
   */
  testError: false,
};
type State = typeof initialState;

/**
 * Component is responsible to displaying a series of forms that coorespond
 * to the "steps" required to setup a Bulk Zap.
 */
class TaskSetupForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...initialState };
  }

  //------------------------------------
  // Form Handlers
  //------------------------------------

  /**
   * Handles next clicks.
   *
   * Next clicks occur outside of the BaseInputForm.
   */
  handleNext = () => {
    const { testSuccess } = this.state;
    this.props.onNext();
    if (testSuccess) this.setState({ testSuccess: false });
  };

  /**
   * Handles submit clicks.
   *
   * Submit clicks occur inside of the BaseInputForm.
   */
  handleSubmit = (values?: any) => {
    this.props.onSubmit(values);
  };

  /**
   * Handles back clicks.
   */
  handleBack = () => {
    this.props.onBack();
  };

  /**
   * Handles test success.
   */
  handleTestSuccess = () => {
    this.setState({ testSuccess: true });
  };

  /**
   * Handles test error.
   */
  handleTestError = () => {
    this.setState({ testError: true });
  };

  /**
   * Handle save click from a Zap Mapper component.
   */
  handleZapMapperSave = (params: any) => {
    const values = { mapping: params };
    this.props.onSubmit(values);
  };

  /**
   * Handles changes to a form.
   *
   * We only listed for `app` change for now.
   */
  handleChange = (option: any, fieldProps: FieldProps) => {
    const { field } = fieldProps;
    if (field.name !== 'app') return;

    const { value } = option; // Grab our app.
    if (this.props.nextOnAppSelect) {
      this.props.onSubmit({ app: value });
    } else {
      this.props.onChange({ app: value });
    }
  };

  //------------------------------------
  // UI Builders
  //------------------------------------

  /**
   * Gets the icon to be used, if any.
   */
  getIconType = () => {
    const { currentStep } = this.props;
    const { testSuccess, testError } = this.state;
    if (currentStep.step === TaskSetupStep.Confirmation) {
      return IconType.Party;
    }
    if (testSuccess) {
      return IconType.Success;
    }
    if (testError) {
      return IconType.Error;
    }

    return IconType.None;
  };

  /**
   * Gets the propper submit button title.
   */
  getSubmitButtonTitle = () => {
    const { currentStep } = this.props;
    if (currentStep.step === TaskSetupStep.Confirmation) {
      return 'Done';
    }
    return 'Next';
  };

  /**
   * Builds the header component.
   */
  buildHeader = () => {
    const { task, currentStep } = this.props;
    if (!currentStep) return;

    const { testSuccess, testError } = this.state;
    const iconType = this.getIconType();
    const formType = currentStep.formType;
    return (
      <TaskSetupHeader
        task={task}
        step={currentStep}
        iconType={iconType}
        formType={formType}
        testSuccess={testSuccess}
        testError={testError}
      />
    );
  };

  /**
   * Builds our buttons.
   */
  buildButtonContainer = (onSubmit: any) => {
    const submitButtonTitle = this.getSubmitButtonTitle();
    const leftButton = {
      title: 'Back',
      variant: 'transparent',
      width: ButtonWidth.Medium,
      onClick: this.handleBack,
    };
    const rightButton = {
      title: submitButtonTitle,
      variant: 'primary',
      width: ButtonWidth.Large,
      onClick: onSubmit,
    };

    return (
      <TaskSetupButtonContainer
        leftButton={leftButton}
        rightButton={rightButton}
      />
    );
  };

  buildFormBody = () => {
    const { currentStep } = this.props;
    if (!currentStep) return;

    const formType = currentStep.formType;
    const inputs = BuildInputsForSetup(currentStep.step);
    switch (currentStep.step) {
      case TaskSetupStep.Import:
      case TaskSetupStep.Export:
      case TaskSetupStep.AppGrid:
      case TaskSetupStep.App:
      case TaskSetupStep.Auth:
      case TaskSetupStep.AppAndAuth:
      case TaskSetupStep.Action:
      case TaskSetupStep.Needs:
        return this.buildBaseSetupForm(inputs, formType);
      case TaskSetupStep.Mapping:
        return this.buildZapMapper();
      case TaskSetupStep.Test:
        return this.buildTestComponent();
      case TaskSetupStep.Confirmation:
        return this.buildConfirmation();
      default:
        return;
    }
  };

  getFormType = () => {};

  /**
   * Builds the Zap mapper compoent.
   */
  buildZapMapper = () => {
    const { task } = this.props;
    return (
      <div className="lg-container">
        <ZapMapper
          task={task!}
          onCancel={this.handleBack}
          onSave={this.handleZapMapperSave}
        />
      </div>
    );
  };

  /**
   * Builds the confirmation component.
   */
  buildConfirmation = () => {
    const { task, currentStep } = this.props;
    return (
      <TaskSetupConfirmation
        task={task!}
        step={currentStep}
        onConfirm={this.handleSubmit}
        onCancel={this.handleBack}
      />
    );
  };

  /**
   * Builds the test component.
   */
  buildTestComponent = () => {
    const { task, currentStep } = this.props;
    return (
      <TaskSetupTest
        task={task!}
        step={currentStep}
        onSuccess={this.handleTestSuccess}
        onNext={this.handleNext}
        onError={this.handleTestError}
        onSkip={this.handleNext}
        onBack={this.handleBack}
      />
    );
  };

  /**
   * Builds the BaseSetupForm component.
   */
  buildBaseSetupForm = (inputs: Input[], type: FormType) => {
    const { task } = this.props;
    return (
      <div className="md-container">
        <BaseSetupForm
          task={task}
          inputs={inputs}
          type={type}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          renderFunc={this.handleRender}
          clearStateOnSubmit={false}
          ignoreDynamicNeeds={false}
        />
      </div>
    );
  };

  /**
   * Render handler for the BaseSetupForm.
   */
  handleRender = (
    children: any,
    formikProps: FormikProps<any>,
    loading: boolean
  ) => {
    if (loading) return <Spinner />;
    return (
      <>
        {children}
        {this.buildButtonContainer(formikProps.submitForm)}
      </>
    );
  };

  render() {
    return (
      <RB.Container className="task-setup-form-container">
        <RB.Row className="justify-content-md-center">
          <RB.Col className="d-flex justify-content-center">
            <RB.Card className="form-card">
              {this.buildHeader()}
              {this.buildFormBody()}
            </RB.Card>
          </RB.Col>
        </RB.Row>
      </RB.Container>
    );
  }
}

const mapStateToProps = (state: RootReducerState) => ({
  appState: state.app,
  appsByID: state.app.appsByID,
  taskState: state.task,
  actionState: state.action,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...actions.AppActions,
      ...actions.ActionActions,
      ...actions.NeedActions,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskSetupForm);
