// External Dependencies
import { Dispatch, Action } from 'redux';

export enum FlashType {
  None = '',
  Primary = 'primary',
  Success = 'success',
  Warn = 'warning',
  Error = 'danger',
}

// Actions
export enum TypeKeys {
  DISPLAY_FLASH = 'DISPLAY_FLASH',
  CLEAR_FLASH = 'CLEAR_FLASH',
}

export type Actions = DisplayFlashAction;

// -----------------------------------------------------------------------------
// Flash Message Actions
// -----------------------------------------------------------------------------

export interface DisplayFlashAction extends Action {
  type: TypeKeys;
  flashType: FlashType;
  message: string;
}

const flashPrimary = (message: string) => async (
  dispatch: Dispatch<DisplayFlashAction>
) => {
  const flashType = FlashType.Primary;
  fireFlash(flashType, message, dispatch);
};

const flashSuccess = (message: string) => async (
  dispatch: Dispatch<DisplayFlashAction>
) => {
  const flashType = FlashType.Success;
  fireFlash(flashType, message, dispatch);
};

const flashWarn = (message: string) => async (
  dispatch: Dispatch<DisplayFlashAction>
) => {
  const flashType = FlashType.Warn;
  fireFlash(flashType, message, dispatch);
};

const flashError = (message: string) => async (
  dispatch: Dispatch<DisplayFlashAction>
) => {
  const flashType = FlashType.Error;
  fireFlash(flashType, message, dispatch);
};

const clearFlash = () => async (dispatch: Dispatch<DisplayFlashAction>) => {
  dispatch(clearFlashMessage());
};

const fireFlash = (
  flashType: FlashType,
  message: string,
  dispatch: Dispatch<DisplayFlashAction>
) => {
  const type = TypeKeys.DISPLAY_FLASH;
  const action = { type, flashType, message };
  dispatch(action);
  setTimeout(() => {
    dispatch(clearFlashMessage());
  }, 3000);
};

const clearFlashMessage = () => {
  return {
    type: TypeKeys.CLEAR_FLASH,
    flashType: FlashType.None,
    message: '',
  };
};

export const FlashActions = {
  clearFlash,
  flashPrimary,
  flashSuccess,
  flashWarn,
  flashError,
};
