import * as model from '../../models';
import { FormType } from '../BaseSetupForm/helper';

export enum TaskSetupStep {
  Export = 0,
  Import,
  AppGrid,
  App,
  Auth,
  AppAndAuth,
  Action,
  Needs,
  Mapping,
  Test,
  Confirmation,
}

export type TaskStep = {
  step: TaskSetupStep;
  formType: FormType;
  labels: TaskStepLabels;
};

export const BuildTaskStep = (
  step: TaskSetupStep,
  formType: FormType,
  app?: model.App
) => {
  const labels = LabelsForStep(step, app);
  return { step, formType, labels };
};

type ActionMap = { [key: string]: string };
export type TaskStepLabels = {
  nav: ActionMap;
  title: ActionMap;
  subtitle?: ActionMap;
  success?: ActionMap;
};

export const LabelsForStep = (
  step: TaskSetupStep,
  app?: model.App
): TaskStepLabels => {
  switch (step) {
    case TaskSetupStep.Import:
      return ImportLabels();
    case TaskSetupStep.Export:
      return ExportLabels();
    case TaskSetupStep.AppGrid:
      return AppGridLabels();
    case TaskSetupStep.App:
      return AppLabels();
    case TaskSetupStep.Auth:
      return AuthLabels(app);
    case TaskSetupStep.AppAndAuth:
      return AppAndAuthLabels();
    case TaskSetupStep.Action:
      return ActionLabels();
    case TaskSetupStep.Needs:
      return NeedLabels();
    case TaskSetupStep.Mapping:
      return MappingLabels();
    case TaskSetupStep.Test:
      return TestLabels();
    case TaskSetupStep.Confirmation:
      return ConfirmationLabels();
  }
};

export const ImportLabels = () => {
  const nav = 'Export Data';
  const title = 'Where is your data coming from?';
  return {
    nav: {
      [FormType.Read]: nav,
    },
    title: {
      [FormType.Read]: title,
    },
  };
};

export const ExportLabels = () => {
  const nav = 'Export Data';
  const title = 'Where would you like to send your data?';
  return {
    nav: {
      [FormType.Write]: nav,
    },
    title: {
      [FormType.Write]: title,
    },
  };
};

export const AppGridLabels = () => {
  const nav = 'Select an app';
  const readTitle = 'Where would you like to import your data from?';
  const writeTitle = 'Where would you like to send your data?';
  return {
    nav: {
      [FormType.Read]: nav,
      [FormType.Write]: nav,
    },
    title: {
      [FormType.Read]: readTitle,
      [FormType.Write]: writeTitle,
    },
  };
};

export const AppLabels = () => {
  const nav = 'Select an App';
  const readTitle = 'Where would you like to your import data from?';
  const writeTitle = 'Where would you like to send your data?';
  return {
    nav: {
      [FormType.Read]: nav,
    },
    title: {
      [FormType.Read]: readTitle,
      [FormType.Write]: writeTitle,
    },
  };
};

export const AuthLabels = (app?: model.App) => {
  const nav = app ? `Connect ${app.name}` : 'Connect App';
  return {
    nav: {
      [FormType.Read]: nav,
      [FormType.Write]: nav,
    },
    title: {
      [FormType.Read]: nav,
      [FormType.Write]: nav,
    },
  };
};

export const AppAndAuthLabels = () => {
  const nav = 'Select an App';
  const readTitle = 'Where would you like to import data from?';
  const writeTitle = 'Where would you like to send your data?';
  return {
    nav: {
      [FormType.Read]: nav,
    },
    title: {
      [FormType.Read]: readTitle,
      [FormType.Write]: writeTitle,
    },
  };
};

export const ActionLabels = () => {
  const nav = 'Select your data';
  const readTitle = 'What type of data would you like to import?';
  const wirteTitle = 'What would you like to do with your data?';
  return {
    nav: {
      [FormType.Read]: nav,
    },
    title: {
      [FormType.Read]: readTitle,
      [FormType.Write]: wirteTitle,
    },
  };
};

export const NeedLabels = () => {
  const readNav = 'Configure Export';
  const writeNav = 'Map Import Fields';
  const readTitle = 'Configure export details';
  const writeTitle = 'Map import fields';
  return {
    nav: {
      [FormType.Read]: readNav,
      [FormType.Write]: writeNav,
    },
    title: {
      [FormType.Read]: readTitle,
      [FormType.Write]: writeTitle,
    },
  };
};

export const MappingLabels = () => {
  const nav = 'Map Fields';
  const title = "Let's map your fields";
  return {
    nav: {
      [FormType.Read]: nav,
      [FormType.Write]: nav,
    },
    title: {
      [FormType.Read]: title,
      [FormType.Write]: title,
    },
  };
};

export const TestLabels = () => {
  const nav = 'Test Import';
  const title = 'Test your import';
  const subtitle =
    "Your import is fully configured. Click below to test the integration, and we'll import five records. You can also skip this step.";
  return {
    nav: {
      [FormType.Read]: nav,
      [FormType.Write]: nav,
    },
    title: {
      [FormType.Read]: title,
      [FormType.Write]: title,
    },
    subtitle: {
      [FormType.Read]: subtitle,
      [FormType.Write]: subtitle,
    },
    success: {
      title: 'Test Success',
      subtitle:
        'Your test was successful and everything is good to go! Click the "Run" button below to start your import!',
    },
  };
};

export const ConfirmationLabels = () => {
  const nav = 'Confirmation';
  const readTitle = 'Your import is running!';
  const writeTitle = 'Your export is running!';
  const readSubtitle =
    'You are all set for now. Your import will automatically run like this:';
  const writeSubtitle =
    'You are all set for now. Your export will automatically run like this:';
  return {
    nav: {
      [FormType.Read]: nav,
      [FormType.Write]: nav,
    },
    title: {
      [FormType.Read]: readTitle,
      [FormType.Write]: writeTitle,
    },
    subtitle: {
      [FormType.Read]: readSubtitle,
      [FormType.Write]: writeSubtitle,
    },
  };
};
