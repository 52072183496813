// External Deps
import { find, sortBy } from 'underscore';
import { addBreadcrumb } from '@sentry/browser';

// Models
import { Zap, Node, Action } from '../models';

export const rootNodeForZap = (zap: Zap): Node | undefined =>
  find(zap.nodes, (node: Node) => !node.root_id);

export const finalNodeForZap = (zap: Zap): Node | undefined =>
  sortBy(zap.nodes, 'id').reverse()[0];

export const actionForNode = (node: Node): Action | undefined => {
  if (!node.implementation) {
    return undefined;
  }

  return find(
    node.implementation.actions,
    (action: Action) => action.key === node.action
  );
};

export const validateZapForListing = (zap: Zap): boolean => {
  for (const node of zap.nodes) {
    if (!node.selected_api || !node.action) {
      return false;
    }
  }
  return true;
};

/**
 * This method evaluates a zap and returns a string describing why
 * we can't process the zap if there's an error.
 * @param zap
 */
export const validateZapForLoad = (zap: Zap): string | null => {
  // Check for Auths for all nodes
  for (let i = 0; i < zap.nodes.length; i++) {
    const node = zap.nodes[i];
    if (
      !node.authentication_id &&
      node.implementation?.auth_type &&
      node.implementation.auth_type.length &&
      node.implementation.auth_type !== 'None' &&
      node.implementation.auth_type !== 'Not Specified'
    ) {
      addBreadcrumb({ data: { node }, message: 'validateZap' });
      return `The app ${node.selected_api} doesn't have an account selected`;
    }
    if (i === 0 && node.selected_api === 'WebHookAPI') {
      return 'Transfer doesn\'t support Zaps with a webhook trigger';
    }
  }

  return null;
};
