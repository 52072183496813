// External Dependencies
import { Dispatch, Action } from 'redux';

// Models
import { Task } from '../models/task';
import { ActionType } from '../models/action';

// Service
import Transfer, { RecordType } from '../services/transfer';

// Acitons
import { AppActions } from './app';
import { AuthActions } from './auth';

const service = new Transfer();

// Actions
export enum TypeKeys {
  FETCHED_TASKS,
  CREATED_TASK,
  UPDATED_TASK,
  DELETED_TASK,
  RAN_TASK,
  TESTED_TASK,
  SET_CURRENT_TASK,
  FETCHED_RECORDS_FOR_TASK,
  FETCHING_DATA_FOR_TASK,
  FETCHED_DATA_FOR_TASK,
}

export type Actions =
  FetchedTasksAction |
  CreatedTaskAction |
  UpdatedTaskAction |
  DeletedTaskAction |
  RanTaskAction |
  TestedTaskAction |
  SetCurrentTaskAction |
  FetchedTaskRecordsAction |
  FetchingDataForTaskAction |
  FetchedDataForTaskAction;

// -----------------------------------------------------------------------------
// Fetch Tasks
// -----------------------------------------------------------------------------

export interface FetchedTasksAction extends Action {
  type: TypeKeys.FETCHED_TASKS;
  tasks: Task[];
}

/**
 * Fetches a list of all Tasks for the current user.
 */
const fetchTasks = () => async (dispatch: Dispatch<FetchedTasksAction>) => {
  const tasks = await service.getTasks();
  const type = TypeKeys.FETCHED_TASKS;
  const action: FetchedTasksAction = { type, tasks };
  dispatch(action);
};

// -----------------------------------------------------------------------------
// Create Task
// -----------------------------------------------------------------------------

export interface CreatedTaskAction extends Action {
  type: TypeKeys.CREATED_TASK;
  task: Task;
}

/**
 * Creates a new Task.
 * @param task The Task to create.
 */
const createTask = (task: Task) => async (
  dispatch: Dispatch<CreatedTaskAction>
) => {
  const created = await service.createTask(task);

  const type = TypeKeys.CREATED_TASK;
  const action: CreatedTaskAction = { type, task: created };
  dispatch(action);
  return created;
};

// -----------------------------------------------------------------------------
// Update Task
// -----------------------------------------------------------------------------

export interface UpdatedTaskAction extends Action {
  type: TypeKeys.UPDATED_TASK;
  task: Task;
}

/**
 * Updates an existing Task.
 * @param task The Task to update.
 */
const updateTask = (task: Task) => async (
  dispatch: Dispatch<UpdatedTaskAction>
) => {
  const updated = await service.updateTask(task);
  const type = TypeKeys.UPDATED_TASK;
  const action: UpdatedTaskAction = { type, task: updated };
  dispatch(action);
  return updated;
};

// -----------------------------------------------------------------------------
// Delete Task
// -----------------------------------------------------------------------------

export interface DeletedTaskAction extends Action {
  type: TypeKeys.DELETED_TASK;
  taskID: string;
}

/**
 * Deletes an existing Task.
 * @param taskID The id of the Task to delete.
 */
const deleteTask = (taskID: string) => async (
  dispatch: Dispatch<DeletedTaskAction>
) => {
  await service.deleteTask(taskID);
  const type = TypeKeys.DELETED_TASK;
  const action: DeletedTaskAction = { type, taskID };
  dispatch(action);
};

// -----------------------------------------------------------------------------
// Run Task
// -----------------------------------------------------------------------------

export interface RanTaskAction extends Action {
  type: TypeKeys.RAN_TASK;
  taskID: string;
}

/**
 * Runs an existing Task.
 * @param taskID The id of the Task to run.
 */
const runTask = (taskID: string) => async (dispatch: Dispatch<any>) => {
  await service.runTask(taskID);
  const type = TypeKeys.RAN_TASK;

  dispatch(fetchTasks());
  dispatch({ type, taskID });
};

// -----------------------------------------------------------------------------
// Test Task
// -----------------------------------------------------------------------------

export interface TestedTaskAction extends Action {
  type: TypeKeys.TESTED_TASK;
  taskID: string;
}

/**
 * Runs an existing Task.
 * @param taskID The id of the Task to run.
 */
const testTask = (taskID: string) => async (
  dispatch: Dispatch<TestedTaskAction>
) => {
  await service.testTask(taskID);
  const type = TypeKeys.TESTED_TASK;
  dispatch({ type, taskID });
};

// -----------------------------------------------------------------------------
// Current Task
// -----------------------------------------------------------------------------

export interface SetCurrentTaskAction extends Action {
  type: TypeKeys.SET_CURRENT_TASK;
  task: Task;
}

/**
 * Sets the current Task in state.
 */
const setCurrentTask = (task: Task) => async (
  dispatch: Dispatch<SetCurrentTaskAction>
) => {
  const type = TypeKeys.SET_CURRENT_TASK;
  dispatch({ type, task });
};

// -----------------------------------------------------------------------------
// Fetch Records for Task
// -----------------------------------------------------------------------------

export interface FetchedTaskRecordsAction extends Action {
  type: TypeKeys.FETCHED_RECORDS_FOR_TASK;
  task: Task;
  records: any[];
}

const fetchRecordsForTask = (task: Task, recType: RecordType) => async (
  dispatch: Dispatch<FetchedTaskRecordsAction>
) => {
  const records = await service.getRecordsForTask(task, recType);
  const type = TypeKeys.FETCHED_RECORDS_FOR_TASK;
  const action: FetchedTaskRecordsAction = { type, task, records };
  dispatch(action);
};

// -----------------------------------------------------------------------------
// Fetch Records for Task
// -----------------------------------------------------------------------------

export interface FetchingDataForTaskAction extends Action {
  type: TypeKeys.FETCHING_DATA_FOR_TASK;
}

export interface FetchedDataForTaskAction extends Action {
  type: TypeKeys.FETCHED_DATA_FOR_TASK;
  task: Task;
}

const fetchDataForTask = (task: Task, actionType: ActionType) => async (
  dispatch: Dispatch<FetchedDataForTaskAction | FetchingDataForTaskAction>
) => {
  dispatch({ type: TypeKeys.FETCHING_DATA_FOR_TASK });
  let app;
  if (actionType === ActionType.Read) {
    app = task.export_step?.app;
  } else {
    app = task.import_step?.app;
  }
  await AppActions.fetchApp(app!);
  await AuthActions.fetchAuths(app!);
  const type = TypeKeys.FETCHED_DATA_FOR_TASK;
  const action: FetchedDataForTaskAction = { type, task };
  dispatch(action);
};

// -----------------------------------------------------------------------------
// Exports
// -----------------------------------------------------------------------------

export const TaskActions = {
  fetchTasks,
  createTask,
  updateTask,
  deleteTask,
  testTask,
  runTask,
  setCurrentTask,
  fetchRecordsForTask,
  fetchDataForTask,
};
