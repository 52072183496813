import './style.scss';

import * as React from 'react';
import { connect } from 'react-redux';
import { FieldProps } from 'formik';
import { bindActionCreators, Dispatch } from 'redux';
import marked from 'marked';

// State
import { RootReducerState } from '../../../reducers';

// Actions
import { AppActions } from '../../../actions/app';

// Models
import * as model from '../../../models';

// Components
import SelectInput from '../Select';
import TextInput from '../Text';
import { FormType } from '../../BaseSetupForm/helper';

// Utils
import * as form from '../../../utils/needs';
import { sync } from '../../../../utils/sync';
import * as Logger from '../../../../utils/logger';

// Types
import { Input, InputType, ZapRecord } from '../types';

type ComponentProps = {
  /**
   * The type for the form.
   */
  type: FormType;

  /**
   * The selected app for the component.
   */
  need: model.Need;

  /**
   * The selected app for the component.
   */
  app: model.App;

  /**
   * The selected app for the component.
   */
  auth: model.Auth;

  /**
   * The selected app for the component.
   */
  action: model.Action;

  /**
   * Current needs for the form.
   */
  needs: { [key: string]: string };

  /**
   * Gives record.
   */
  record: ZapRecord;

  /**
   * Formik field props for the component.
   */
  fieldProps: FieldProps;

  /**
   * On Open handler. Called when the select is opened.
   */
  onOpen: (key: string, fieldProps: FieldProps) => void;

  /**
   * On Change handler. Called when a selection is made.
   */
  onChange: (option: any, fieldProps: FieldProps) => void;

  /**
   * On Search handler. Called when user input is made.
   */
  onSearch: (search: string, fieldProps: FieldProps) => void;

  /**
   * On Error handler.
   */
  onError: (error: any) => void;
};
type ConnectedState = ReturnType<typeof mapStateToProps>;
type ConnectedActions = ReturnType<typeof mapDispatchToProps>;
type Props = ComponentProps & ConnectedState & ConnectedActions;

const initialState = {};
type State = typeof initialState;

/**
 * Select component for selecing a Need.
 */
class NeedSelectInput extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...initialState };
  }

  //-----------------------------------------
  // Input Handlers
  //-----------------------------------------

  handleOpen = (key: string, fieldProps: FieldProps) => {
    this.getRecordsForNeed(false);
    if (!this.props.onOpen) return;
    this.props.onOpen(key, fieldProps);
  };

  //-----------------------------------------
  // Network Requests
  //-----------------------------------------

  /**
   * Fetches records for a need.
   */
  getRecordsForNeed = async (silent: boolean) => {
    const need = this.getNeed();
    if (!need || !need.prefill) return;

    const { app, auth, needs } = this.props;
    const authID = auth?.id
    const appID = app.currentImplementationId;
    const [err] = await sync(
      this.props.fetchRecordsForNeed(appID, need, needs, authID) as any
    );
    if (err) {
      Logger.LogInfo('Failed to records for need with err:', err);
      if (!silent) this.props.onError(err);
    }
  };

  //-----------------------------------------
  // Data Helpers
  //-----------------------------------------

  getNeed = () => {
    const { fieldProps } = this.props;

    // Parse our need key.
    const { name } = fieldProps.field;
    const needKey = name.split('.')[1];

    // Find the action need.
    const action = this.props.actionState.currentAction;
    if (!action) return;
    const { currentNeeds } = this.props.actionState;
    return currentNeeds.find((n: model.Need) => n.key === needKey)!;
  };

  //-----------------------------------------
  // Input Builders
  //-----------------------------------------

  buildInputComponent = (input: Input, fieldProps: FieldProps) => {
    let component;
    switch (input.type) {
      case InputType.Text:
        component = this.buildTextInput(input, fieldProps);
        break;
      case InputType.Select:
        component = this.buildSelectInput(input, fieldProps);
        break;
    }

    const helpText = input.helpText ? input.helpText : '';
    const parsed = marked(helpText);
    return (
      <>
        {component}
        <div
          className="help-text-container"
          dangerouslySetInnerHTML={{ __html: parsed }}
        />
      </>
    );
  };

  /**
   * Builds a simple text input component.
   */
  buildTextInput = (input: Input, fieldProps: FieldProps) => {
    return (
      <TextInput
        key={input.key}
        input={input}
        fieldProps={fieldProps}
        onChange={this.props.onChange}
      />
    );
  };

  /**
   * Builds a select input component.
   */
  buildSelectInput = (input: Input, fieldProps: FieldProps) => {
    const { app, record, appsByID } = this.props;
    let inputApp = app;
    if (input.key?.includes('mapping')) {
      inputApp = appsByID[record.appID!];
    }

    return (
      <SelectInput
        key={input.key}
        input={input}
        app={inputApp}
        fieldProps={fieldProps}
        onOpen={this.handleOpen}
        onChange={this.props.onChange}
        onSearch={this.props.onSearch}
      />
    );
  };

  render() {
    const { fieldProps, type } = this.props;
    const need = this.getNeed();

    const { record } = this.props;
    const input = form.buildInput(need!, record, type);
    return this.buildInputComponent(input, fieldProps);
  }
}

const mapStateToProps = (state: RootReducerState) => ({
  authState: state.auth,
  actionState: state.action,
  appsByID: state.app.appsByID,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  const creators = {
    ...AppActions,
  };
  return bindActionCreators(creators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(NeedSelectInput);
