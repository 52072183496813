// External Dependencies
import { Dispatch, Action } from 'redux';
import { navigate } from '@reach/router';

// Internal Deps
import { LogoutOfTransfer } from '../../services/session';

// Actions
export enum TypeKeys {
  LOGGED_OUT = 'LOGGED_OUT',
}

export type Actions = LogoutAction;

// -----------------------------------------------------------------------------
// Fetch Me
// -----------------------------------------------------------------------------

export interface LogoutAction extends Action {
  type: TypeKeys.LOGGED_OUT;
}

const logout = () => async (dispatch: Dispatch<LogoutAction>) => {
  const type = TypeKeys.LOGGED_OUT;
  const action: LogoutAction = { type };
  await navigate('/login?logout=true', { state: { logout: true } });
  await LogoutOfTransfer();
  await dispatch(action);
};

// -----------------------------------------------------------------------------
// Exports
// -----------------------------------------------------------------------------

export const SessionActions = {
  logout,
};
