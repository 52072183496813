import './style.scss';

import * as React from 'react';
import { connect } from 'react-redux';
import { navigate, RouterProps } from '@reach/router';
import { bindActionCreators, Dispatch } from 'redux';

// Actions
import * as actions from '../../actions';

// Redux
import { RootReducerState } from '../../reducers';
import { FormState } from '../BaseSetupForm/inputs';
import { ActionForKeyAndType } from '../../reducers/action';

// Components
import { TaskStep, TaskSetupStep } from './shared';
import TaskSetupNav from './TaskSetupNav';
import TaskSetupForm from './TaskSetupForm';
import Spinner from '../Spinner';
import { FormType, FormHelper } from '../BaseSetupForm/helper';

// Models
import * as model from '../../models';

// Utils
import { sync } from '../../../utils/sync';
import * as Logger from '../../../utils/logger';
import Classname from '../../utils/classname';

// Fixtures
import { BuildTaskStep } from './shared';

// Props
type ConnectedState = ReturnType<typeof mapStateToProps>;
type ConnectedActions = ReturnType<typeof mapDispatchToProps>;
type Props = ConnectedState & ConnectedActions & RouterProps;

// State
const initialState = {
  /**
   * The Task for the component.
   */
  task: ({} as unknown) as model.Task,

  /**
   * The setup steps for the component.
   */
  steps: [] as TaskStep[],

  /**
   * Current step index.
   */
  currentStepIndex: 0,

  /**
   * Boolean that indicates whether or not the component is loading.
   */
  loading: false,
};
type State = typeof initialState;

/**
 * TaskSetup renders a component for setting up a Task.
 */
class TaskSetup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...initialState };
    this.props.hideNav(); // Ensure the Global Navbar is hidden.
  }

  // Look at traffic to the bulk import page.

  componentDidMount() {
    this.setState({ loading: true });

    this.setupZapState();
    this.setCurrentStepIndex();

    this.setState({ loading: false });
  }

  componentDidUpdate() {
    this.setCurrentStepIndex();
  }

  //------------------------------------
  // Form Setup
  //------------------------------------

  /**
   * Sets up the Zap state for the component.
   *
   * If a `taskID` query param is passed, we fetch that Task and updated state.
   * Otherwise, we look for a `task` query param and parse state from there.
   */
  setupZapState = async () => {
    const { currentTask } = this.props;
    if (!currentTask) return;

    const steps = this.buildTaskSteps(currentTask, false);
    this.setState({ task: currentTask, steps });
  };

  /**
   * Parses the `step` query param and updates state.
   */
  setCurrentStepIndex = async () => {
    // Grab the step param.
    const { currentStepIndex } = this.state;
    const stepIndexParam = this.getQueryParam('step');
    if (!stepIndexParam) return;

    // If the step matches the current one, we can bail.
    const stepIndex = Number(stepIndexParam);
    if (stepIndex === currentStepIndex) return;

    // Otherwise we update state.
    this.setState({ currentStepIndex: stepIndex });
  };

  //------------------------------------
  // Helpers
  //------------------------------------

  /**
   * Parses a query param for the given key.
   */
  getQueryParam = (key: string) => {
    const location = this.props.location;
    const params = new URLSearchParams(location!.search);
    return params.get(key);
  };

  /**
   * Navigation helper.
   */
  navigate = (step: TaskSetupStep) => {
    const { task } = this.state;
    if (task && task.id) {
      navigate(`/setup?taskID=${task.id}&step=${step}`);
    } else {
      navigate(`/setup?step=${step}`);
    }
  };

  /**
   * Updates the Task state for the component.
   */
  updateTaskState = async (formState: FormState) => {
    const { currentStepIndex, steps } = this.state;
    const currentStep = steps[currentStepIndex];

    const currentTask = this.mergeFormWithCurrenZap(formState);
    switch (currentStep.step) {
      case TaskSetupStep.Needs:
      case TaskSetupStep.Import:
        if (currentStep.formType === FormType.Read) {
          this.setState({ task: currentTask });
          return;
        }
        const created = await this.createNewZap(currentTask);
        this.setState({ task: created! });
        break;
      case TaskSetupStep.Export:
      case TaskSetupStep.Mapping:
        const updated = await this.updateZap(currentTask);
        this.setState({ task: updated! });
        break;
      default:
        this.setState({ task: currentTask });
        break;
    }
  };

  /**
   * Merges the form state with the current Zap state.
   */
  mergeFormWithCurrenZap = (formState: FormState) => {
    const { steps, currentStepIndex, task } = this.state;
    const step = steps[currentStepIndex];

    const helper = new FormHelper(formState);
    if (step.formType === FormType.Read) {
      const export_step = helper.getExportStep();
      return { ...task, ...export_step, mappings: formState.mapping };
    } else {
      const import_step = helper.getImportStep();
      return { ...task, ...import_step, mappings: formState.mapping };
    }
  };

  /**
   * Fetches an action if it exists.
   */
  getAction = (key?: string, type?: model.ActionType, appID?: string) => {
    if (!key || !type || !appID) return;
    return ActionForKeyAndType(this.props.actionState, key, type)!;
  };

  //------------------------------------
  // Network Requests
  //------------------------------------

  /**
   * Creates a new Task!
   */
  createNewZap = async (task: model.Task) => {
    const op = this.props.createTask(task);
    const [err, created] = await sync(op as any);
    if (err) {
      this.props.flashError('Oops something went wrong. Please try again.');
      Logger.LogError('Failed to create Zap with error.', err);
      return;
    }
    return created as model.Task;
  };

  /**
   * Updates an existing Task.
   */
  updateZap = async (task: model.Task) => {
    const op = this.props.updateTask(task);
    const [err, updated] = await sync(op as any);
    if (err) {
      this.props.flashError('Oops something went wrong. Please try again.');
      Logger.LogError('Failed to update Zap with error.', err);
      return;
    }
    return updated as model.Task;
  };

  /**
   * Run an existing Zap!
   */
  runZap = async (task: model.Task) => {
    const op = this.props.runTask(`${task.id}`);
    const [err] = await sync(op as any);
    if (err) {
      this.props.flashError('Oops something went wrong. Please try again.');
      Logger.LogError('Failed to run Zap with error.', err);
    }
  };

  //------------------------------------
  // Form Handlers
  //------------------------------------

  /**
   * Handles changes in our form.
   */
  handleChange = async (formState: FormState) => {
    const currentTask = this.mergeFormWithCurrenZap(formState);
    this.setState({ task: currentTask });
  };

  /**
   * Handles next clicks by incrementing our step.
   */
  handleNext = async () => {
    const { currentStepIndex, task, steps } = this.state;
    const currentStep = steps[currentStepIndex];

    // Run our Zap if the submit is coming form a Test Step.
    if (currentStep.step === TaskSetupStep.Test) {
      await this.runZap(task);
    }

    const nextStep = currentStepIndex + 1;
    this.navigate(nextStep);
  };

  /**
   * Handles submits clicks.
   *
   * Submit clicks are emitted from the BaseSetupForm.
   */
  handleSubmit = async (formState: FormState) => {
    const { currentStepIndex, steps } = this.state;
    const currentStep = steps[currentStepIndex];

    this.setState({ loading: true });
    await this.updateTaskState(formState);

    const lastStep = steps[steps.length - 1];
    if (currentStep === lastStep) {
      navigate(`/tasks`);
    }

    const nextStep = currentStepIndex + 1;
    this.navigate(nextStep);
    this.setState({ loading: false });
  };

  /**
   * Handles back clicks by decrementing our step.
   */
  handleBack = () => {
    const { currentStepIndex } = this.state;
    const previousStep = currentStepIndex - 1;
    this.navigate(previousStep);
  };

  /**
   * Handles cancels by navigating to my tasks.
   */
  handleCancel = () => {
    navigate(`/tasks`);
  };

  /**
   * Handles step clicks in our step Nav Bar.
   */
  handleStepClick = (step: TaskSetupStep) => {
    this.navigate(step);
  };

  //------------------------------------
  // Step Builder
  //------------------------------------

  /**
   * Builds the TaskSetp array for the component.
   *
   * The TaskStep array determines the steps that are displayed to the user.
   */
  buildTaskSteps = (task: model.Task, defaultSteps: boolean): TaskStep[] => {
    if (defaultSteps) {
      return this.buildDefaultConfig();
    } else {
      return this.buildConfigForTask(task);
    }
  };

  buildDefaultConfig = () => {
    const read = FormType.Read;
    const write = FormType.Write;
    return [
      BuildTaskStep(TaskSetupStep.AppGrid, read),
      BuildTaskStep(TaskSetupStep.Auth, read),
      BuildTaskStep(TaskSetupStep.Action, read),
      BuildTaskStep(TaskSetupStep.Needs, write),
      BuildTaskStep(TaskSetupStep.Test, write),
    ];
  };

  buildConfigForTask = (task: model.Task) => {
    const taskSteps = [];
    const read = FormType.Read;
    const write = FormType.Write;

    const exportAppID = task.export_step?.app;
    if (!exportAppID) {
      taskSteps.push(BuildTaskStep(TaskSetupStep.AppGrid, read));
    }

    const exportApp = this.props.appsByID[exportAppID!];
    taskSteps.push(BuildTaskStep(TaskSetupStep.Auth, read, exportApp));

    const importAppID = task.import_step?.app!;
    const importApp = this.props.appsByID[importAppID];
    taskSteps.push(BuildTaskStep(TaskSetupStep.Auth, write, importApp));

    const exportActionKey = task.export_step?.action;
    if (!exportActionKey) {
      taskSteps.push(BuildTaskStep(TaskSetupStep.Action, read));
    }

    const exportAction = task.import_step?.action;
    if (!exportAction) {
      taskSteps.push(BuildTaskStep(TaskSetupStep.Action, write));
    }

    const importActionType = task.export_step?.action_type;
    const action = this.getAction(
      exportActionKey!,
      importActionType!,
      exportAppID!
    );
    if (!action || !this.props.actionState.currentNeeds) {
      taskSteps.push(BuildTaskStep(TaskSetupStep.Needs, read));
    }

    taskSteps.push(BuildTaskStep(TaskSetupStep.Needs, write));
    taskSteps.push(BuildTaskStep(TaskSetupStep.Test, write));
    taskSteps.push(BuildTaskStep(TaskSetupStep.Confirmation, write));
    return taskSteps;
  };

  //------------------------------------
  // UI Builders
  //------------------------------------

  /**
   * Builds a loading spinner.
   */
  buildSpinner = () => {
    const classname = Classname({
      'spinner-container': true,
      'd-flex': true,
      'justify-content-center ': true,
      'align-items-center': true,
    });
    return (
      <div className={classname}>
        <Spinner large={true} />
      </div>
    );
  };

  /**
   * Builds the step Nav.
   */
  buildStepNav = () => {
    const { currentStepIndex, steps } = this.state;
    return (
      <TaskSetupNav
        steps={steps}
        currentStepIndex={currentStepIndex}
        onStepClick={this.handleStepClick}
      />
    );
  };

  /**
   * Builds our Form component.
   */
  buildSetupForm = () => {
    const { currentStepIndex, steps, task } = this.state;
    const currentStep = steps[currentStepIndex];

    return (
      <TaskSetupForm
        currentStep={currentStep}
        task={task}
        onChange={this.handleChange}
        onNext={this.handleNext}
        onBack={this.handleBack}
        onSubmit={this.handleSubmit}
        onCancel={this.handleCancel}
        nextOnAppSelect={true}
      />
    );
  };

  render() {
    const { loading } = this.state;
    if (loading) return this.buildSpinner();

    return (
      <div className="task-setup-container">
        {this.buildStepNav()}
        {this.buildSetupForm()}
      </div>
    );
  }
}

const mapStateToProps = (state: RootReducerState) => ({
  appsByID: state.app.appsByID,
  actionState: state.action,
  currentTask: state.task.currentTask,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...actions.AppActions,
      ...actions.TaskActions,
      ...actions.AuthActions,
      ...actions.ActionActions,
      ...actions.GiveActions,
      ...actions.FlashActions,
      ...actions.NavActions,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskSetup);
