import './style.scss';

import * as React from 'react';
import * as RB from 'react-bootstrap';
import { ChevronLeft, Edit2, ExternalLink } from 'react-feather';

// Components
import Button, { ButtonWidth } from '../../../common/components/Button';

// Models
import * as models from '../../../common/models';

// Utils
import Classname from '../../../common/utils/classname';

type Props = {
  task?: models.Task;
  onBackClick: () => void;
  onSaveZapName: (title: string) => void;
  onImportDataClick: () => void;
};

const initialState = {
  name: '',
};
type State = typeof initialState;

class Header extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...initialState };
  }

  /**
   * Handles blurs in the Zap name input.
   */
  handleBlur = () => {
    const { name } = this.state;
    this.props.onSaveZapName(name);
  };

  /**
   * Handles changes to the Zap name input.
   */
  handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ name: event.currentTarget.value });
  };

  /**
   * Builds our Zapier back Icon.
   */
  buildBackIcon = () => {
    const className = 'back-icon-container d-flex align-items-center';
    return (
      <div className={className} onClick={this.props.onBackClick}>
        <ChevronLeft size={20} />
        <img
          className="zap-logo-img"
          src="https://cdn.zapier.com/zapier/images/logos/zapier-logomark.png"
          alt="zapier-icon"
        />
      </div>
    );
  };

  buildNameInput = () => {
    const taskName = this.props.task ? this.props.task.name : undefined;
    return (
      <div className="name-input-container d-flex align-items-center">
        <Edit2 size={14} />
        <RB.FormControl
          defaultValue={taskName}
          placeholder="Name Your Task"
          aria-label="Name your Task"
          aria-describedby="zap-name"
          onBlur={this.handleBlur}
          onChange={this.handleChange as any}
        />
      </div>
    );
  };

  buildButtonInput = () => {
    const { task, onImportDataClick } = this.props;
    return (
      <Button
        loading={task?.running}
        title="Export Data"
        icon={ExternalLink}
        width={ButtonWidth.Large}
        variant="primary"
        onClick={onImportDataClick}
      />
    );
  };

  buildButtonContainer = () => {
    const className = Classname({
      'header-button-container': true,
      'd-flex': true,
    });
    return <div className={className}>{this.buildButtonInput()}</div>;
  };

  render() {
    return (
      <div className="editor-header-container d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          {this.buildBackIcon()}
          {this.buildNameInput()}
        </div>
        {this.buildButtonContainer()}
      </div>
    );
  }
}

export default Header;
