/* eslint-disable no-underscore-dangle */

import ObjectHash from 'object-hash';
import { Action } from './action';
import { Auth } from './auth';

export interface Zap {
  id: number;
  account_id: number;
  title: string;
  description: string;
  state: string;
  paused: boolean;
  paused_by_plan: boolean;
  last_live_at: string;
  last_paused_at: string;
  lastchanged: string;
  has_shared: boolean;
  nodes: Node[];
}

export interface Node {
  id: number;
  code: string;
  date: string;
  lastchanged: string;
  type_of: string;
  parent_id: number;
  root_id: number;
  account_id: number;
  owner_id: number;
  children_ids: number;
  offspring_ids: number;
  authentication_id?: number;
  title: string;
  paused: boolean;
  selected_api: string;
  implementation: Implementation;
  action: string;
  params: {
    spreadsheet: string;
    worksheet: number;
  };
  meta: {
    validation: {
      action: {
        is_opened: boolean;
      };
      params: {
        invalid_mappings: any;
        is_opened: boolean;
        is_valid: boolean;
        is_edited: boolean;
      };
      webhook: {
        is_opened: boolean;
      };
    };
    parammap: {
      spreadsheet: string;
      worksheet: string;
    };
    selectedGives: any;
  };
  subscription_url: string;
  note: string;
  has_shared: boolean;
  polling_interval_override: number;
  block_and_release_limit_override: number;
  guided_recipe_origin_id: string;
  diverged_guided_recipe: string;
  last_live_at: string;
  last_paused_at: string;
  paused_by_error: boolean;
  paused_by_auth: boolean;
  paused_by_plan: boolean;
  paused_by_zombie: boolean;
  paused_by_maintenance: boolean;
  created_by_id: string;
  last_edited_by_id: number;
  last_activity: string;
  is_template: boolean;
  legacy: string;
  authentication_owner_id: number;
  root_paused: boolean;
}

export interface Implementation {
  actions: Action[];
  app_id: number;
  auth_fields: Auth[];
  auth_type: string;
  is_beta: boolean;
  is_deprecated: boolean;
  is_invite_only: boolean;
  is_premium: boolean;
  is_private_only: boolean;
  name: string;
  selected_api: string;
}

// Interface for Data From Zapier
interface ZapRecord {
  [x: string]: any;
}

export class NodeWrite {
  NodeOutput: NodeOutput;

  formattedPayload: any;
}

export class NodeWriteResult {
  rootID: string;

  error?: string;
}

export type WritesByRootID = { [key: string]: FormattedNodeInput | NodeOutput };
export type WritesWithErrors = { [key: string]: Error };

export class NodeOutput {
  private __data: ZapRecord;

  private __previousNodeOutput: NodeOutput | null;

  private __nextNodeOutput: NodeOutput | null;

  private __nodeID: number;

  // ID of the root record to kick off a zap flow
  private __rootID: string; // If null, traverse to the root. Root NodeOutput should always have this set

  private __rootIDKey: string; // Key used to ID the record.

  private static getIdentifierAndKeyForRecord = (record: ZapRecord) => {
    if (record.id) {
      return 'id';
    }
    if (record._id) {
      return '_id';
    }
    if (record.key) {
      return 'key';
    }
    return null;
  };

  constructor(
    nodeOutput: ZapRecord,
    nodeID: number,
    previousNodeOutput?: NodeOutput
  ) {
    this.__data = nodeOutput;
    this.__nodeID = nodeID;
    if (!previousNodeOutput) {
      // Root node
      const identifier = NodeOutput.getIdentifierAndKeyForRecord(this.__data);
      if (identifier) {
        this.__rootIDKey = identifier;
        this.__rootID = `${this.__data[this.__rootIDKey]}`; // Normalize into string
      } else {
        this.__rootIDKey = '__transfer__id';
        this.__rootID = ObjectHash(this.__data);
        this.__data[this.__rootIDKey] = this.__rootID;
      }
      this.__nextNodeOutput = null;
      this.__previousNodeOutput = null;
    } else {
      // Non-root node, make the next node and link
      this.__previousNodeOutput = previousNodeOutput;
      previousNodeOutput.__nextNodeOutput = this;
    }
  }

  toString(): string {
    return 'debug';
  }

  get data(): ZapRecord {
    return this.__data;
  }

  get nodeID(): number {
    return this.__nodeID;
  }

  get nextNode(): NodeOutput | null {
    return this.__nextNodeOutput;
  }

  get previousNode(): NodeOutput | null {
    return this.__previousNodeOutput;
  }

  get rootID(): string {
    if (this.__rootID) {
      return this.__rootID;
    }
    if (this.__previousNodeOutput) {
      return this.__previousNodeOutput.rootID;
    }
    throw new Error('Invalid node output, root ID missing');
  }

  get rootIDKey(): string {
    if (this.__rootIDKey) {
      return this.__rootIDKey;
    }
    if (this.__previousNodeOutput) {
      return this.__previousNodeOutput.rootIDKey;
    }
    throw new Error('Invalid node output, root id key record missing');
  }
}

/**
 * Used to represent an emphemeral representation of a node's need formatted properly via the output
 */
export class FormattedNodeInput {
  private __data: any;

  private __previousNodeOutput: NodeOutput;

  constructor(data: any, previousNodeOutput: NodeOutput) {
    this.__data = data;
    this.__previousNodeOutput = previousNodeOutput;
  }

  get data(): any {
    return this.__data;
  }

  get previousNodeOutput(): NodeOutput {
    return this.__previousNodeOutput;
  }

  get rootID(): string {
    return this.__previousNodeOutput.rootID;
  }

  get nodeID(): number {
    return this.__previousNodeOutput.nodeID;
  }
}
