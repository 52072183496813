import './style.scss';

import * as React from 'react';
import * as RB from 'react-bootstrap';
import * as RF from 'react-feather';
import { connect } from 'react-redux';

// Components
import AppIcon from '../../AppIcon';
import { TaskStep } from '../shared';
import { FormType } from '../../BaseSetupForm/helper';

// Models
import * as model from '../../../models';

// Utils
import Classname from '../../../utils/classname';

// State
import { RootReducerState } from '../../../reducers';

export enum IconType {
  None = 0,
  Success,
  Error,
  Party,
}

// Props
type ComponentProps = {
  step: TaskStep;
  task?: model.Task;
  iconType: IconType;
  formType: FormType;
  testSuccess: boolean;
  testError: boolean;
};
type ConnectedState = ReturnType<typeof mapStateToProps>;
type Props = ConnectedState & ComponentProps;

// State
const initialState = {};
type State = typeof initialState;

/**
 * Renders a header for the TaskSetup component.
 */
class TaskSetupHeader extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...initialState };
  }

  getApp = (appID?: string) => {
    if (!appID) return;

    const { appsByID } = this.props;
    return appsByID[appID];
  };

  getIconComponent = () => {
    const { task, iconType } = this.props;
    const importApp = this.getApp(task?.export_step?.app);
    const exportApp = this.getApp(task?.import_step?.app);

    let content;
    let containerClass;
    switch (iconType) {
      case IconType.None:
        containerClass = 'icon-container';
        content = (
          <>
            <AppIcon app={importApp} size={72} />
            <RF.ChevronRight size={32} color={'black'} />
            <AppIcon app={exportApp} size={72} />
          </>
        );
        break;
      case IconType.Success:
        containerClass = 'success-container';
        content = <RF.Check size={48} color={'white'} />;
        break;
      case IconType.Error:
        containerClass = 'error-container';
        content = <RF.AlertTriangle size={32} color={'white'} />;
        break;
      case IconType.Party:
        containerClass = 'party-container';
        content = (
          <span className="party-icon" role="img" aria-label="party">
            🎉
          </span>
        );
        break;
    }
    const className = Classname({
      'icon-component-container': true,
      'd-flex': true,
      'justify-content-center': true,
      'align-items-center': true,
      [containerClass]: true,
    });

    return <div className={className}>{content}</div>;
  };

  getTitle = () => {
    const { step, testSuccess } = this.props;
    const labels = step.labels;
    if (testSuccess && labels.success) {
      return labels!.success!.title;
    }
    return labels.title[step.formType];
  };

  getSubtitle = () => {
    const { step, testSuccess } = this.props;
    const labels = step.labels;
    if (testSuccess && labels.success) {
      return labels!.success!.subtitle;
    }
    return labels.subtitle ? labels.subtitle[step.formType] : '';
  };

  render() {
    const iconComponent = this.getIconComponent();
    const title = this.getTitle();
    const subtitle = this.getSubtitle();
    return (
      <RB.Container className="task-setup-header-container">
        <RB.Row>
          <RB.Col className="task-setup-card-row">
            {iconComponent}
            <h1>{title}</h1>
            <p>{subtitle}</p>
          </RB.Col>
        </RB.Row>
      </RB.Container>
    );
  }
}

const mapStateToProps = (state: RootReducerState) => ({
  appsByID: state.app.appsByID,
});

export default connect(mapStateToProps, {})(TaskSetupHeader);
