import React from 'react';
import './style.scss';

type AppIcon = {
  selected_api: string;
  size: AppIconSize;
};

export enum AppIconSize {
  SIZE_16,
  SIZE_24,
  SIZE_32,
  SIZE_64,
}

export default ({ selected_api, size }: AppIcon) => {
  let cleanSelectedAPI = ''
  if (selected_api) {
    cleanSelectedAPI = selected_api.split('@')[0]
  }
  let imgSize = '64x64';
  let styleSize = { height: 64, width: 64 };
  switch (size) {
    case AppIconSize.SIZE_16:
      imgSize = '16x16'
      styleSize.width = 16
      styleSize.height = 16
      break
    case AppIconSize.SIZE_24:
      imgSize = '32x32'
      styleSize.width = 24
      styleSize.height = 24
      break
    case AppIconSize.SIZE_32:
      imgSize = '32x32'
      styleSize.width = 32
      styleSize.height = 32
      break
    case AppIconSize.SIZE_64:
      break
  }
  return <div style={styleSize} className={`app-icon-container PlaceholderAPI64x64 ${cleanSelectedAPI}${imgSize}`} />;
};
