import * as React from 'react';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { FieldProps } from 'formik';
import { sortBy, partition } from 'underscore';

// State
import { RootReducerState } from '../../../reducers';

// State
import { SoftCompareActionType } from '../../../reducers/action';

// Models
import * as model from '../../../models';

// Components
import SelectInput from '../Select';
import { FormType } from '../../BaseSetupForm/helper';

// Form
import { SPINNER_OPTION } from '../../../utils/form';

// Types
import { Input, InputType, InputOption } from '../types';
import { ActionType } from '../../../models';

type ComponentProps = {
  /**
   * The type for the form.
   */
  type: FormType;

  /**
   * The selected app for the component.
   */
  app: model.App;

  /**
   * The selected action for the component.
   */
  action?: model.Action;

  /**
   * Formik field props for the component.
   */
  fieldProps: FieldProps;

  /**
   * On Open handler. Called when the select is opened.
   */
  onOpen: (key: string, fieldProps: FieldProps) => void;

  /**
   * On Change handler. Called when a selection is made.
   */
  onChange: (option: any, fieldProps: FieldProps) => void;

  /**
   * On Search handler. Called when user input is made.
   */
  onSearch: (search: string, fieldProps: FieldProps) => void;

  /**
   * On Error handler.
   */
  onError: (error: any) => void;
};
type ConnectedState = ReturnType<typeof mapStateToProps>;
type Props = ComponentProps & ConnectedState;

const initialState = {
  /**
   * Search filter form the component.
   */
  filter: '',
};
type State = typeof initialState;

/**
 * Select component for selecing an Action.
 */
class ActionSelectInput extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...initialState };
  }

  //-----------------------------------------
  // Handlers
  //-----------------------------------------

  /**
   * Handles user search input.
   */
  handleSearch = (search: string, fieldProps: FieldProps) => {
    this.setState({ filter: search });
    if (!this.props.onSearch) return;
    this.props.onSearch(search, fieldProps);
  };

  //-----------------------------------------
  // Data Helpers
  //-----------------------------------------

  /**
   * Builds an action label for our action
   */
  getLabelForAction = (action: model.Action) => {
    const { type } = this.props;
    let label = action.name;
    if (type === FormType.Read) {
      if (action.noun && action.noun.length) {
        label = action.noun;
      } else {
        label = action.name.replace('New', '').trim();
      }
    }
    if (action.type === model.ActionType.ReadBulk) {
      label = `${label} (Bulk)`;
    }
    return label;
  };

  /**
   * Filters out actions depending on the FormType.
   */
  filterAction = (action: model.Action) => {
    const { type } = this.props;
    if (!SoftCompareActionType(type, action.type)) return false;
    if (type === FormType.Write && action.type === ActionType.Write)
      return true;
    if (type === FormType.Read && action.type === ActionType.ReadBulk)
      return true;
    if (action.is_hidden && action.type !== model.ActionType.ReadBulk)
      return false;
    if (type === FormType.Read) {
      if (action.name.includes('Update')) return false;
      if (
        action.name.includes('My') ||
        action.name.includes('List') ||
        action.name.includes('Liked') ||
        action.name.includes('New') ||
        action.name.includes('Create')
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  /**
   * Returns an array of InputOptions for available action choices.
   * @discussion Returns a SPINNER_OPTION if an app doesn't exist.
   * This implies we are fetching the app's actions.
   */
  public getActionChoices = (): InputOption[] => {
    const { app } = this.props;
    if (!app) return SPINNER_OPTION;

    // The below logic attempts to filter out actions that don't pertain to Transfer.
    const filtered = this.props.actionState.actions.filter(this.filterAction);
    const parts = partition(
      filtered,
      (action: model.Action) => action.type === ActionType.ReadBulk
    );
    const bulkParts = parts[0];
    const normalParts = parts[1];

    const ordered = sortBy(normalParts, 'is_important').reverse();
    const readBulkOrdered = bulkParts.concat(ordered);
    return readBulkOrdered.map((action: model.Action) => {
      let label = this.getLabelForAction(action);
      return { label, value: action };
    });
  };

  /**
   * Helper for building an input.
   */
  buildInput = (): Input => {
    return {
      id: 'action-select',
      type: InputType.Select,
      key: 'action',
      placeholder: 'Select...',
      label: 'Choose an action',
      required: true,
      options: this.getActionChoices(),
      validation: Yup.string().required('Please Select an Action'),
    };
  };

  render() {
    const input = this.buildInput();
    const { fieldProps, onOpen, onChange, app } = this.props;
    return (
      <SelectInput
        key={input.key}
        input={input}
        app={app}
        fieldProps={fieldProps}
        onOpen={onOpen}
        onChange={onChange}
        onSearch={this.handleSearch}
      />
    );
  }
}

const mapStateToProps = (state: RootReducerState) => ({
  actionState: state.action,
});

export default connect(mapStateToProps, {})(ActionSelectInput);
