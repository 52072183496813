import './style.scss';

import * as React from 'react';
import { Button as AntButton } from 'antd';
import { ButtonProps } from 'antd/lib/button/button'


type Props = {

};

/**
 * Renders a custom button component.
 */
class Button extends React.Component<Props & ButtonProps & React.RefAttributes<HTMLElement>, any> {

    render() {
        const className = this.props.className ? `transfer-button ${this.props.className}` : `transfer-button`
        return (
            <div className={className}>
                <AntButton {...this.props} />
            </div>
        )
    }
}

export default Button;
