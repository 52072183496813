import './style.scss';

import * as React from 'react';
import * as RB from 'react-bootstrap';
import * as Feather from 'react-feather';

// Components
import AppIconButton from '../../../common/components/AppIconButton';

// Models
import * as model from '../../../common/models';

type Props = {
  app: model.App;
  action?: model.Action;
  onTabClick: () => void;
  onNewTabClick: () => void;
};

/**
 * Builds the table Tab Menu
 */
export const TabMenu = (props: Props) => {
  /**
   * Builds the action button row component.
   */
  const buildSourceDetailItem = () => {
    const action = props.action ? props.action.name : 'New Spreadsheet Row';
    return (
      <RB.Nav.Item>
        <RB.Nav.Link eventKey="link-1" onClick={props.onTabClick}>
          <div className="d-flex align-items-center">
            <AppIconButton app={props.app} size={28} padding={2} />
            <p className="import-tab-label">{`${props.app.name}: ${action}`}</p>
          </div>
        </RB.Nav.Link>
      </RB.Nav.Item>
    );
  };

  const buildPlusTabItem = () => {
    return (
      <RB.Nav.Item>
        <RB.Nav.Link eventKey="link-2" onClick={props.onNewTabClick}>
          <Feather.Plus size={20} />
        </RB.Nav.Link>
      </RB.Nav.Item>
    );
  };

  return (
    <RB.Nav className="tab-menu-container" defaultActiveKey="link-1">
      {buildSourceDetailItem()}
      {buildPlusTabItem()}
    </RB.Nav>
  );
};

export default TabMenu;
