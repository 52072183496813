import './style.scss';

import * as React from 'react';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { FieldProps } from 'formik';
import { sortBy, groupBy } from 'underscore';
import { bindActionCreators, Dispatch } from 'redux';

// State
import { RootReducerState } from '../../../reducers';
import { authsByAppIDSelector } from '../../../reducers/auth';

// Actions
import { AuthActions } from '../../../actions/auth';

// Models
import * as model from '../../../models';

// Components
import SelectInput from '../Select';
import NewAuthButton from './NewAuthButton';

// Form
import { SPINNER_OPTION } from '../../../utils/form';

// Types
import { Input, InputType, InputOption } from '../types';
import { Auth } from '../../../models';
import { sync } from '../../../../utils/sync';

export const NEW_AUTH = 'NEW_AUTH';
export const NEW_AUTH_OPTION = {
  value: NEW_AUTH as any,
  label: 'Add a New Account',
};

type ComponentProps = {
  /**
   * The selected app for the component.
   */
  app: model.App;

  /**
   * The selected app for the component.
   */
  auth?: model.Auth;

  /**
   * Formik field props for the component.
   */
  fieldProps: FieldProps;

  /**
   * On Open handler. Called when the select is opened.
   */
  onOpen: (key: string, fieldProps: FieldProps) => void;

  /**
   * On Change handler. Called when a selection is made.
   */
  onChange: (option: any, fieldProps: FieldProps) => void;

  /**
   * On Search handler. Called when user input is made.
   */
  onSearch: (search: string, fieldProps: FieldProps) => void;

  /**
   * On Error handler.
   */
  onError: (error: any) => void;

  /**
   * Boolean to indicate if auth and app are selected at once.
   */
  authAndApp: boolean;
};
type ConnectedState = ReturnType<typeof mapStateToProps>;
type ConnectedActions = ReturnType<typeof mapDispatchToProps>;
type Props = ComponentProps & ConnectedState & ConnectedActions;

const initialState = {
  /**
   * Search filter form the component.
   */
  filter: '',

  /**
   * Boolean that indicates whether or not the auth failed validation.
   */
  invalidAuth: (null as unknown) as model.Auth,
};
type State = typeof initialState;

/**
 * Select component for selecing an Auth.
 */
class AuthSelectInput extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...initialState };
    this.refreshAuthState();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.app !== prevProps.app) {
      this.refreshAuthState();
    }
  }

  //-----------------------------------------
  // Form Handlers
  //-----------------------------------------

  /**
   * Handles opens for the select input.
   */
  handleOpen = (key: string, fieldProps: FieldProps) => {
    if (!this.props.onOpen) return;
    this.props.onOpen(key, fieldProps);
  };

  /**
   * Handles changes for the select input.
   */
  handleChange = async (option: any, fieldProps: FieldProps<any>) => {
    this.validateAuth(option.value);

    if (!this.props.onChange) return;
    this.props.onChange(option, fieldProps);
  };

  handleReauth = (auth: model.Auth) => {
    this.openSignInWindow(auth.selected_api, `${auth.id}`);
  };

  //-----------------------------------------
  // Network Requests
  //-----------------------------------------
  /**
   * Refreshes Auths.
   */
  refreshAuthState = async () => {
    const { app } = this.props;
    if (!app) return;

    const filter = app ? app.currentImplementationId : '';
    await this.props.fetchAuths(filter);
  };

  /**
   * Validates the selected auth.
   */
  validateAuth = async (auth: model.Auth) => {
    const [err] = await sync(this.props.validateAuth(auth) as any);
    if (err) {
      this.setState({ invalidAuth: auth });
      return;
    }
  };

  /**
   * Opens a modal to facilate setting up a new Auth with Zapier.
   */
  openSignInWindow = (appID: string, authID?: string) => {
    const t = this;
    let url = `https://zapier.com/engine/auth/start/${appID}/`;
    if (authID) {
      url = `${url}?_zapier_auth_id=${authID}`;
    }
    const target = '_blank';
    const features = 'toolbar=0,status=0,width=980,height=700';
    const authWindow = window.open(url, target, features);

    var timer = setInterval(async function () {
      if (authWindow && authWindow.closed) {
        clearInterval(timer);
        t.props.fetchAuths();
        t.setState({ invalidAuth: null as any });
      }
    }, 500);
  };

  //-----------------------------------------
  // Data Helpers
  //-----------------------------------------

  public getAuths = () => {
    const { app, authAndApp, authsByAppID } = this.props;
    if (!app && !authAndApp) return [];

    const selectedAPI = app.currentImplementationId;
    const auths = authsByAppID[selectedAPI];
    if (!auths) return [];
    if (authAndApp) return auths;
    const { me } = this.props
    const grouped = groupBy(auths, (auth: Auth) => auth.customuser_id === me.zapier_customuser_id);
    const sortedGroups = sortBy(grouped.false, 'lastchanged').reverse();
    const sortedPrivate = sortBy(grouped.true, 'lastchanged').reverse();

    const finalSorted = sortedPrivate.concat(sortedGroups);
    return finalSorted.filter((auth: model.Auth) => {
      return auth.selected_api === selectedAPI;
    });
  };

  /**
   * Returns an array of InputOptions for auth choices.
   * @discussion Returns a SPINNER_OPTION if an app and/or auths don't exist.
   * This implies we are fetching auths and/or an app.
   */
  public getAuthChoices = (): InputOption[] => {
    const { app } = this.props;
    const { fetchingAuths } = this.props;
    if (!app) return SPINNER_OPTION;

    const auths = this.getAuths();
    if (!auths.length && fetchingAuths) return SPINNER_OPTION;

    const choices = auths.map((auth: model.Auth) => {
      return { value: auth, label: auth.title };
    });
    choices.push({ value: NEW_AUTH as any, label: 'Add a New Account' });
    return choices;
  };

  /**
   * Helper for building an input.
   */
  buildInput = (): Input => {
    return {
      id: 'action-select',
      type: InputType.Select,
      key: 'auth',
      placeholder: 'Select...',
      label: 'Choose an action',
      required: true,
      options: this.getAuthChoices(),
      validation: Yup.string().required('Please Select an Action'),
    };
  };

  buildAuthButton = () => {
    const { app } = this.props;
    const title = `Sign in to ${app.name}`;
    const onClick = () => this.openSignInWindow(app.currentImplementationId);
    return <NewAuthButton app={app} title={title} onClick={onClick} />;
  };

  buildReauthLabel = () => {
    const { invalidAuth } = this.state;
    if (!invalidAuth) return;

    const onClick = () => this.handleReauth(invalidAuth);
    return (
      <p className="reauth-label">
        Looks like your auth is expired.{' '}
        <span className="reauth-link" onClick={onClick}>
          Click Here to reconnect.
        </span>
      </p>
    );
  };

  buildAuthSelectComponent = () => {
    const input = this.buildInput();
    const reauth = this.buildReauthLabel();
    const { fieldProps, app } = this.props;
    return (
      <>
        <SelectInput
          key={input.key}
          input={input}
          fieldProps={fieldProps}
          app={app}
          onOpen={this.handleOpen}
          onChange={this.handleChange}
          onSearch={this.props.onSearch}
        />
        {reauth}
      </>
    );
  };

  render() {
    const { app } = this.props;
    const { fetchingAuths } = this.props;
    const auths = this.getAuths();

    if (app && !auths.length && !fetchingAuths) {
      return this.buildAuthButton();
    }
    return this.buildAuthSelectComponent();
  }
}

const mapStateToProps = (state: RootReducerState) => ({
  fetchingAuths: state.auth.fetching,
  authsByAppID: authsByAppIDSelector(state),
  me: state.user.me!
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  const creators = {
    ...AuthActions,
  };
  return bindActionCreators(creators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthSelectInput);
