import './style.scss';

import * as React from 'react';
import * as RB from 'react-bootstrap';

// Components
import Button, { ButtonWidth } from '../../Button';

// Types
import { ModalButton } from './types';

type Props = {
  size?: 'sm' | 'lg';
  title?: string;
  customClass?: string;
  show: boolean;
  header?: any;
  children?: any;
  formFields?: any[];
  loading: boolean;
  submitButton?: ModalButton;
  secondaryButton?: ModalButton;
  cancelButton?: ModalButton;
  onToggle: () => void;
};

const initialState = {};
type State = typeof initialState;

/**
 * Modal renders a modal with optional buttons.
 */
class Modal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...initialState };
  }

  /**
   * Builds out modal header.
   */
  buildHeader = () => {
    const header = this.props.header ? (
      this.props.header
    ) : (
      <RB.Modal.Title>{this.props.title}</RB.Modal.Title>
    );
    return <RB.Modal.Header closeButton>{header}</RB.Modal.Header>;
  };

  /**
   * Builds our modal body.
   */
  buildBody = () => {
    return <RB.Modal.Body>{this.props.children}</RB.Modal.Body>;
  };

  /**
   * Builds outr modal button.
   */
  buildButton = (button?: ModalButton) => {
    if (!button) return;
    return (
      <Button
        title={button.title}
        width={ButtonWidth.Medium}
        variant={button.variant}
        onClick={button.action}
        loading={button.loading}
        dynamicSize={button.dynamicSize}
      />
    );
  };

  /**
   * Builds our modal footer.
   */
  buildFooter = () => {
    const { cancelButton, submitButton } = this.props;
    if (!cancelButton && !submitButton) return;

    return (
      <RB.Modal.Footer className="d-flex justify-content-between">
        {this.buildButton(this.props.cancelButton)}
        <div className="d-flex right-buttons">
          {this.buildButton(this.props.secondaryButton)}
          {this.buildButton(this.props.submitButton)}
        </div>
      </RB.Modal.Footer>
    );
  };

  render() {
    return (
      <RB.Modal
        className={this.props.customClass}
        size={this.props.size}
        show={this.props.show}
        onHide={this.props.onToggle}
      >
        {this.buildHeader()}
        {this.buildBody()}
        {this.buildFooter()}
      </RB.Modal>
    );
  }
}

export default Modal;
