// Internal Dependencies
import { Actions, TypeKeys, FlashType } from '../actions/flash';

export type FlashState = typeof defaultState;

const defaultState = {
  showFlash: false,
  flashType: (null as unknown) as FlashType,
  message: '',
};

function flash(state = defaultState, action: Actions) {
  switch (action.type) {
    case TypeKeys.DISPLAY_FLASH: {
      const updatedState = { ...state };
      updatedState.showFlash = true;
      updatedState.flashType = action.flashType;
      updatedState.message = action.message;
      return updatedState;
    }
    case TypeKeys.CLEAR_FLASH:
      return { ...defaultState };
    default:
      return state;
  }
}
export default flash;
