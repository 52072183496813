import React from 'react';
import './style.scss';

type EmptyComponentProps = {
    subtitle?: string,
    title: string,
    description: string,
    emoji: string,
    supportLink?: string,
    supportLinkLabel?: string
}

export default ({ subtitle, title, description, emoji, supportLink = `https://zapier.com/app/contact-us`, supportLinkLabel }: EmptyComponentProps) => {
    let subTitle = subtitle ? (<p>{subtitle}</p>) : null
    return (
        <aside className='aside-component-container'>
            <div className="empty-content-container d-flex flex-column align-items-center">
                {emoji ? <h1 className="emoji-header">{emoji}</h1> : null}
                {subTitle}
                <h4 className="empty-data-header">{title}</h4>
                <h5 className="empty-data-label">{description}</h5>
                <a href={supportLink} className="empty-data-label-link">{supportLinkLabel}</a>
            </div>
        </aside>

    )
}
