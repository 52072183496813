import './style.scss';

import * as React from 'react';
import * as Feather from 'react-feather';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { sortBy } from 'underscore';

// @ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
// @ts-ignore
import BootstrapTable from 'react-bootstrap-table-next';

// State
import { RootReducerState } from '../../common/reducers';
import { appListSelector } from '../../common/reducers/app';

// Actions
import { AppActions } from '../../common/actions/app';
import { AuthActions } from '../../common/actions/auth';
import { TaskActions } from '../../common/actions/task';
import { FlashActions } from '../../common/actions/flash';
import { UserActions } from '../../common/actions/user';

// Models
import * as model from '../../common/models';

// Components
// import Button, { ButtonWidth } from '../Button';
import TabMenu from './TabMenu';
import HeaderCell from './HeaderCell';
import { Give } from '../../common/models';

enum DataType {
  String = 'string',
  Number = 'number',
  Object = 'object',
  Date = 'date',
  URL = 'url',
  Boolean = 'boolean',
}

// Props
type ConnectedState = ReturnType<typeof mapStateToProps>;
type ConnectedActions = ReturnType<typeof mapDispatchToProps>;
type ComponentProps = {
  task: model.Task;
  gives: model.Give[];
  data: any[];
  onNewZapClick: () => void;
};
type Props = ConnectedState & ConnectedActions & ComponentProps;

const initialState = {
  typeMap: {} as { [key: string]: string },
};
type State = typeof initialState;

/**
 * Renders a table to display Zap data.
 */
class Table extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const typeMap = this.buildTypeMap();
    this.state = { typeMap };
  }

  /**
   * Builds a type map.
   *
   * @discussion The TypeMaps maps record keys to their type.
   */
  buildTypeMap = () => {
    const typeMap = {} as any;
    if (!this.props.data) return typeMap;

    const record = this.props.data[0];
    if (!record) return {};

    Object.keys(record).forEach((key: string) => {
      const value = record[key];
      if (typeof value === 'number') {
        typeMap[key] = DataType.Number;
      } else if (Date.parse(value)) {
        typeMap[key] = DataType.Date;
      } else {
        typeMap[key] = typeof record[key];
      }
    });
    return typeMap;
  };

  handleSave = () => {
    // Nothing to do for now.
  };

  handleCancel = () => {
    // Nothing to do for now.
  };

  buildTableCell = (cell: any) => {
    if (typeof cell === 'object') {
      return null
    }
    return cell;
  };

  getIconForType = (type: DataType) => {
    switch (type) {
      case DataType.String:
        return Feather.Type;
      case DataType.Number:
        return Feather.Hash;
      case DataType.Date:
        return Feather.Calendar;
      case DataType.Object:
        return Feather.Box;
      case DataType.Boolean:
        return Feather.CheckSquare;
      default:
        return Feather.Hash;
    }
  };

  buildHeaderCell = (cell: any) => {
    const { appsByID } = this.props.appState;
    const selectedAPI = 'ZapierFormatterDevAPI';
    const app = appsByID[selectedAPI];
    const icon = this.getIconForType(cell.dataType);
    return (
      <HeaderCell
        title={cell.text}
        icon={icon}
        app={app}
        onSave={this.handleSave}
        onCancel={this.handleCancel}
      />
    );
  };

  /**
   * Builds the columns for the table.
   */
  buildColumns = () => {
    // We're not getting gives all the time. Still investigating
    let { gives } = this.props;
    if (!gives.length) {
      // No gives, so let's use fields in object. Going to sort them by populated key
      const counts = this.keysByCount(this.props.data)
      gives = Object.keys(counts).map((key: string) => {
        const score = key.indexOf('COL$') === -1 ? 0 : 1;
        const newGive: Give = {
          key: key,
          score: score,
          subscore: 0.0,
          zap_meta_sample: '',
          label: key,
          type: 'unicode',
          custom_field: false,
        };
        return newGive;
      }).sort((giveA: Give, giveB: Give) => {
        if (counts[giveA.key] > counts[giveB.key]) {
          return -1
        } else if (counts[giveA.key] === counts[giveB.key]) {
          return giveA.key > giveB.key ? -1 : 1
        }
        return 1
      });
    }

    const sortedGives = sortBy(gives, (give: Give) => -give.score);
    const columnCount = sortedGives.length;
    const desiredWidth =
      columnCount > 10 ? '80px' : `${(1 / columnCount) * 100}%`;
    return sortedGives.map((give: Give) => {
      const title = give.label ? give.label : give.key;
      const dataField = give.key.split('__').join('.');
      return {
        text: title,
        dataType: this.state.typeMap[give.key],
        dataField,
        formatter: this.buildTableCell,
        headerFormatter: this.buildHeaderCell,
        headerStyle: () => {
          return { width: desiredWidth };
        },
        style: () => {
          return {
            maxWidth: '200px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          };
        },
      };
    });
  };

  /**
   * Returns the value counts by key
   *
   * @memberof Table
   */
  keysByCount = (data: any[]) => {
    const counts: { [key: string]: any } = {}
    if (!data.length) {
      return counts
    }
    const subSlice = data.slice(0, 50) // Reasonable approx
    for (const record of subSlice) {
      const recordKeys = Object.keys(record)
      for (const key of recordKeys) {
        if (!counts[key]) {
          counts[key] = 1
        } else if (record[key]) {
          counts[key] = counts[key] + 1
        }
      }
    }
    return counts
  }

  /**
   * Builds the actual table component.
   */
  buildTable = () => {
    if (!this.props.data || !this.props.data.length)
      return <div className="table-container" />;

    const columns = this.buildColumns();
    const selectRow = { mode: 'checkbox', clickToSelect: false };

    const { data } = this.props;
    let uniq = 0
    const cleaned = data.map((value: any) => {
      return { id: uniq++, ...value, key: uniq++ };
    });
    return (
      <div className="table-container">
        <BootstrapTable
          keyField="id"
          data={cleaned}
          columns={columns}
          cellEdit={cellEditFactory({ mode: 'dbclick' })}
          selectRow={selectRow}
        />
      </div>
    );
  };

  handleTabClick = () => { };

  render() {
    const { appsByID } = this.props.appState;
    const { task } = this.props;

    const exportApp = task.export_step?.app;
    const exportAction = task.export_step?.action;
    const app = appsByID[exportApp!];
    if (!app) return <div></div>;

    const actions = app.actions ? app.actions : [];
    const action = actions.find((a: model.Action) => {
      return a.key === exportAction;
    });
    const table = this.buildTable()
    return (
      <div className="table-component-container">
        {table}
        <TabMenu
          app={app}
          action={action}
          onTabClick={this.handleTabClick}
          onNewTabClick={this.props.onNewZapClick}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: RootReducerState) => ({
  appState: state.app,
  taskState: state.task,
  authState: state.auth,
  actionState: state.action,
  apps: appListSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...AppActions,
      ...TaskActions,
      ...FlashActions,
      ...AuthActions,
      ...UserActions,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
