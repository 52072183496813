import './style.scss';

import * as React from 'react';
import { Modal } from 'antd';
import { Image } from 'react-bootstrap';

import Button from '../../Button'

// Might have more in the future
type FBConfirmationOverrideData = {
    pixel_id: string,
    page_id: string
}

type ConfirmationModalProps = {
    visible: boolean,
    zapID: number,
    rootAppName: string,
    onClose: () => void,
    overrideData: Map<string, string> | FBConfirmationOverrideData | null
}

const SendingRecordsConfirmationModal: React.FunctionComponent<ConfirmationModalProps> = ({ visible, zapID, onClose, overrideData, rootAppName }) => {

    const navToTaskHistoryHandler = () => {
        window.open(`https://zapier.com/app/history?root_id=${zapID}`, "_blank")
    }

    const nextStepsContent = () => {
        if (overrideData) {
            const navToExternalFBBizSuite = () => {
                window.open(`https://business.facebook.com/latest/customer/crm_destination/contacts_list?asset_id=${(overrideData as FBConfirmationOverrideData).page_id}&nav_ref=crm_3p_zapier`, "_blank")
            }
            const navToExternalEventsMgr = () => {
                window.open(`https://business.facebook.com/events_manager2/list/pixel/${(overrideData as FBConfirmationOverrideData).pixel_id}`, "_blank")
            }
            return (
                <>
                    <p className={'view-tasks-instructions'}>{'Follow either of the links below to continue setting up your Facebook App.'}</p>
                    <Button className={'send-to-task-history'} children={'FB Business Suite'} disabled={false} onClick={navToExternalFBBizSuite} />
                    <Button className={'close-modal'} type={'primary'} children={'FB Events Manager'} disabled={false} onClick={navToExternalEventsMgr} />
                </>
            )
        }

        return (
            <>
                <p className={'view-tasks-instructions'}>{'Track your Zap activity as it runs in Task History.'}</p>
                <Button className={'send-to-task-history'} children={'Go to Task History'} disabled={false} onClick={navToTaskHistoryHandler} />
                <Button className={'close-modal'} type={'primary'} children={'Close'} disabled={false} onClick={onClose} />
            </>
        )
    }

    return (
        <Modal
            className={'records-sending-to-zap'}
            visible={visible}
            onCancel={onClose}
            title={
                <div className={'d-flex align-items-center flex-column'}>
                    <Image className={'header-img'} src={'https://cdn.zapier.com/storage/photos/a47004dadbf741f7849b0e13946ca9f3.png'} />
                </div>
            }
            footer={null}
        >
            <div className={'d-flex align-items-center flex-column content'}>
                <p className='title'>{`The data from ${rootAppName} was sent to your Zap!`}</p>
                {nextStepsContent()}
            </div>
        </Modal >
    )
}

export default SendingRecordsConfirmationModal
