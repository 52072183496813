// Components
import { InputType, Input } from '../Inputs/types';
import { getInputConfigForType } from '../Inputs/inputs';

/**
 * Builds the input config for ZapSetupModal ConfigureApp form step.
 */
export const BuildAppInputs = (): Input[] => {
  const inputTypes = [
    InputType.AppSelect,
    InputType.AuthSelect,
    InputType.ActionSelect,
  ];
  return inputTypes.map((type: InputType) => {
    return getInputConfigForType(type);
  });
};

export const BuildNeedInputs = () => {
  return [getInputConfigForType(InputType.NeedSelect)];
};
