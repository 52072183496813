import './style.scss';

import * as React from 'react';
import { ChevronDown, Check } from 'react-feather';

// Utils
import Classname from '../../utils/classname';

const initialState = {};
type State = typeof initialState;
type Props = {
  title: string;
  onClick: () => void;
};

/**
 * StepButton renders UI for a completed step in an Import or Export Form.
 */
class StepButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...initialState };
  }

  /**
   * Builds a green circle check mark!
   */
  buildCircleCheck = () => {
    const className = Classname({
      'circle-check-container': true,
      'd-flex ': true,
      'justify-content-center': true,
      'align-items-center': true,
    });
    return (
      <div className={className}>
        <Check size={14} color="white" />
      </div>
    );
  };

  render() {
    const className = Classname({
      'step-button-container': true,
      'd-flex ': true,
      'justify-content-between': true,
      'align-items-center': true,
    });
    return (
      <div onClick={this.props.onClick} className={className}>
        <div className="d-flex align-items-center">
          <ChevronDown size={24} />
          <p className="step-button-title">{this.props.title}</p>
        </div>
        {this.buildCircleCheck()}
      </div>
    );
  }
}

export default StepButton;
