import './style.scss';

import * as React from 'react';
import * as RB from 'react-bootstrap';
import { navigate } from '@reach/router';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Power } from 'react-feather';

// State
import { RootReducerState } from '../../common/reducers';
import { loggedInUser } from '../../common/reducers/user';

// Actions
import { FlashActions } from '../../common/actions/flash';
import { UserActions } from '../../common/actions/user';

// Models
import { User } from '../../common/models';

// Analytics
import Analytics, { Action } from '../../common/utils/analytics';

// Props
type ConnectedState = ReturnType<typeof mapStateToProps>;
type ConnectedActions = ReturnType<typeof mapDispatchToProps>;
type ComponentProps = {
  onLogout: () => void;
};
type Props = ConnectedState & ConnectedActions & ComponentProps;

/**
 * Navbar for the application.
 */
const Navbar = (props: Props) => {
  /**
   * Handle Logout.
   */
  const handleLogout = () => {
    Analytics.trackAction(Action.LogoutClick);
    props.onLogout();
  };

  /**
   * Builds a login button.
   */
  const buildLoggedOutComponent = () => {
    return (
      <RB.Form inline>
        <RB.Button variant="outline-primary">Login</RB.Button>
      </RB.Form>
    );
  };

  const buildInitialsComponent = (loggedInUser: User) => {
    if (loggedInUser.photo_url) {
      return (
        <RB.Image className='user-photo' src={loggedInUser.photo_url} roundedCircle />
      )
    }

    const initials = `${loggedInUser.first_name[0]}${loggedInUser.last_name[0]}`;
    return (
      <div className="initials-component">
        <p>{initials}</p>
      </div>
    );
  };

  const buildUserItem = (user: User) => {
    const fullName = `${user.first_name} ${user.last_name}`;
    return (
      <RB.Dropdown.Item>
        <p className="dropdown-label">{fullName}</p>
        <p className="dropdown-label-small">{user.email}</p>
      </RB.Dropdown.Item>
    );
  };

  const buildLogoutItem = () => {
    const className = 'd-flex align-items-center';
    const onClick = handleLogout;
    return (
      <RB.Dropdown.Item className={className} onClick={onClick}>
        <Power className="dropdown-icon" size={20} />
        <p className="dropdown-label">Logout</p>
      </RB.Dropdown.Item>
    );
  };

  const buildDropdownToggle = (user: User) => {
    return (
      <RB.Dropdown.Toggle
        className="user-dropdown-toggle"
        id="user-dropdown-toggle"
      >
        {buildInitialsComponent(user)}
      </RB.Dropdown.Toggle>
    );
  };

  /**
   * Shows the user avatar.
   */
  const buildLoggedInComponent = (ussr: User) => {
    return (
      <>
        <RB.Dropdown as={RB.NavItem}>
          {buildDropdownToggle(ussr)}
          <RB.Dropdown.Menu alignRight>
            {buildUserItem(ussr)}
            <RB.NavDropdown.Divider />
            {buildLogoutItem()}
          </RB.Dropdown.Menu>
        </RB.Dropdown>
      </>
    );
  };

  const buildAuthComponent = () => {
    if (props.loggedInUser) {
      return buildLoggedInComponent(props.loggedInUser);
    }
    return buildLoggedOutComponent();
  };

  const buildBrand = () => {
    const navigateHome = () => navigate('/bulk-runner');
    return (
      <RB.Navbar.Brand
        className="d-flex align-items-center"
        onClick={navigateHome}
      >
        <img
          src="https://cdn.zapier.com/storage/photos/ea79d780f8caf96484cd7ad398f83d50.png"
          alt="transfer-logo"
          className="transfer-logo"
        />
        <div className='nav-title'>
          <p className='title'>{'Transfer'}</p>
          <p className='description'>{'A '}<span className="zapier">{'Zapier Labs'}</span>{' Product'}</p>
        </div>
      </RB.Navbar.Brand>
    );
  };

  const authComponent = buildAuthComponent();

  const navBarComponent = () => {
    if (!props.showNav) return null;

    return (
      <div className="transfer-navbar">
        <RB.Navbar expand="lg">
          {buildBrand()}
          <RB.Navbar.Toggle aria-controls="basic-navbar-nav" />
          <RB.Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            {authComponent}
          </RB.Navbar.Collapse>
        </RB.Navbar>
      </div>
    );
  };

  return props.loggedInUser ? navBarComponent() : null;
};

const mapStateToProps = (state: RootReducerState) => ({
  loggedInUser: loggedInUser(state),
  showNav: state.nav.showNav,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...FlashActions,
      ...UserActions,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
