import { RootReducerState } from '../reducers';

const LAST_UPDATE_KEY = 'TRANSFER_LAST_UPDATE_KEY_V0002';
const STATE_KEY = 'TRANSFER_STATE_V0002';
export const SESSION_TOKEN_KEY = 'TRANSFER_SESSION_TOKEN_KEY_V0002';

// Expire the session cache after 2 hrs
const SESSION_EXPIRATION = 1000 * 60 * 2;

/**
 * Loads the state from local storage.
 */
export function loadState() {
  try {
    // Retrieve the cached state from localStorage
    const serializedState = localStorage.getItem(STATE_KEY);
    const lastUpdate = localStorage.getItem(LAST_UPDATE_KEY);
    if (serializedState === null) {
      return undefined;
    }

    /**
     * We are storing the session data for the logged in user
     * in local storage at the key 'session'. We want this to data
     * to expire after two hours. This checks to see if our time
     * constraint has been met, if so, delete the data stored at key 'session'.
     */
    const resolvedState = JSON.parse(serializedState);
    if (Date.now() > Number(lastUpdate) + Number(SESSION_EXPIRATION)) {
      delete resolvedState.session;
    }

    // Return the cached state
    return resolvedState;
  } catch (err) {
    // If we have an error, let the reducers initialize the state
    return undefined;
  }
}

/**
 * We are saving reducer state inside of local storage.
 * @param state
 */
export function saveState(state: RootReducerState) {
  try {
    delete state.error
    var cache: Array<any> | null = [];
    const serializedState = JSON.stringify(state, function (_: string, value: any) {
      if (typeof value === 'object' && value !== null) {
        if (cache!.indexOf(value) !== -1) {
          // Circular reference found, discard key
          return;
        }
        // Store value in our collection
        cache!.push(value);
      }
      return value;
    });
    cache = null; // Enable garbage collection
    localStorage.setItem(STATE_KEY, serializedState);
    localStorage.setItem(LAST_UPDATE_KEY, Date.now().toString());
  } catch (err) {
    console.error('ERROR SAVING STATE: ', err); // eslint-disable-line
  }
}

/**
 * Clears the state stored in local storage.
 */
export function clearState() {
  try {
    localStorage.removeItem(STATE_KEY);
    localStorage.removeItem(LAST_UPDATE_KEY);
    localStorage.removeItem(SESSION_TOKEN_KEY);

    // Remove all the __formState keys
    for (let i = 0; i < localStorage.length; i++) {
      const keyName = localStorage.key(i);
      if (keyName && keyName.includes('__formState')) {
        localStorage.removeItem(keyName);
      }
    }
  } catch (err) {
    console.error('ERROR CLEARING STATE: ', err); // eslint-disable-line
  }
}

/**
 * Clears the state stored in local storage.
 */
export function clearStateForKey(key: string) {
  try {
    localStorage.removeItem(key);
  } catch (err) {
    console.error('ERROR CLEARING STATE: ', err); // eslint-disable-line
  }
}

/**
 * Saves the given state with the given key to local storage.
 */
export function saveStateForKey(state: any, key: string) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (err) {
    console.error('ERROR SAVING STATE FOR KEY: ', key, err); // eslint-disable-line
  }
}

/**
 * Loads the state stored by the given key from local storage. Returns it.
 */
export function loadStateForKey(key: string) {
  try {
    // Retrieve the cached state from localStorage
    const serializedState = localStorage.getItem(key);
    const resolvedState = JSON.parse(serializedState!);
    return resolvedState;
  } catch (err) {
    console.error('ERROR LOADING STATE FOR KEY: ', key, err); // eslint-disable-line
  }
}

/**
 * Clears the state stored by the given key in local storage.
 */
export function removeStateForKey(key: string) {
  try {
    localStorage.removeItem(key);
  } catch (err) {
    console.error('ERROR CLEARING STATE FOR KEY: ', key, err); // eslint-disable-line
  }
}
