import './style.scss';
import FullStory from 'react-fullstory';
import { Col, Row } from 'react-bootstrap';

import React from 'react';
import { navigate, RouterProps, RouteComponentProps } from '@reach/router';
import { captureEvent } from '@sentry/browser';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

// State
import { RootReducerState } from '../../common/reducers';

// Actions
import * as actions from '../../common/actions';
import { SubmitOauthCode } from '../../services/session';

// Components
import Navbar from '../Navbar';
import Flash from '../Flash';
import Spinner from '../../common/components/Spinner';
import EmptyComponent from '../../components/Shared/EmptyComponent'

// Store
import * as store from '../../common/store/local';

// Utils
import { sync } from '../../utils/sync';
import * as Logger from '../../utils/logger';
import { normalizeErrorMessage } from '../../utils/error'
import Classname from '../../common/utils/classname';
import Analytics, { Action } from '../../common/utils/analytics';
import { CURRENT_PATH_KEY, FULLSTORY_ORG_ID } from '../../common/utils/constants';

type ConnectedState = ReturnType<typeof mapStateToProps>;
type ConnectedActions = ReturnType<typeof mapDispatchToProps>;
type Props = ConnectedState &
  RouterProps &
  RouteComponentProps<any> &
  ConnectedActions;

const initialState = {
  loadingUser: true // In the mounting process, we always load the user, so initial here is true
  /**
   * Boolean that indicates whether or not we are fetching user information.
   */
};

// For Appcues onboarding
declare global {
  interface Window { Appcues: any; }
}

type State = typeof initialState;

/**
 * Container is the top level React component for our application.
 */
class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    // Save our location so that we can redirect after auth if needed.
    const { location } = this.props;
    this.saveLocation(location);
    this.state = {
      ...initialState,
      loadingUser: props.user ? false : true
    };
  }

  componentDidMount = async () => {
    this.checkForCompleteOauthOrRefreshUser();
    Analytics.trackPage(this.props.location);
  };

  async componentDidUpdate(prevProps: Props) {
    if (prevProps.loadingUser && !this.props.loadingUser) {
      this.setState({ loadingUser: false })
    }

    const { location } = this.props
    let currentPath = null
    if (location) {
      currentPath = location.pathname
    }

    let prevPath = null
    const prevLocation = prevProps.location
    if (prevLocation) {
      prevPath = prevLocation.pathname
    }

    if (currentPath !== prevPath && window.Appcues) {
      window.Appcues.page();
    }
  }

  //---------------------------------
  // Application State Setup
  //---------------------------------

  /**
   * Sets up our application state.
   */
  checkForCompleteOauthOrRefreshUser = async () => {
    // This base container serves as a landing to setup state
    // If we are on the oauth completion URL, we attempt to complete.
    const { location } = this.props;
    if (location!.pathname === '/oauth/complete/') {
      await this.completeOauth();
    } else {
      await this.fetchMeOrRedirect()
    }
  };

  /**
   * Completes the OAuth flow by parsing the query params and submitting.
   * Upon success, we refresh app state and load Zaps.
   */
  completeOauth = async () => {
    try {
      await SubmitOauthCode(this.props.location!.search);
    } catch (e) {
      captureEvent({ extra: { error: e }, message: 'Unable to complete oauth' })
      this.props.setError(e)
    }

    await this.fetchMeOrRedirect()
    Analytics.trackAction(Action.LoginSuccess);

    // If we have a persisted location, we redirect there and clear it.
    const location = this.getLocation();
    if (location) {
      this.clearLocation();
      navigate(`${location.pathname}${location.search}`);
    } else {
      navigate('/bulk-runner');
    }
  };

  //----------------------------------
  // Network Requests
  //----------------------------------

  /**
   * Fetches me!
   */
  fetchMeOrRedirect = async () => {
    const [err] = await sync(this.props.fetchMe() as any);
    if (err || !this.props.user) {
      Logger.LogInfo('Failed to fetch profile with err:', err);
      const location = this.getLocation();
      const path = location ? `/login/${location.search}` : `/login`
      navigate(path);
    }
  };

  /**
   * Helpers
   */

  /**
   * Fetches current location data from disk.
   */
  getLocation = (): any => {
    const foundLocation = store.loadStateForKey(CURRENT_PATH_KEY);
    if (foundLocation?.pathname.indexOf('login') > -1) {
      foundLocation.pathname = '/bulk-runner'
    }
    return foundLocation
  };

  /**
   * Clears the current location data on disk
   */
  clearLocation = (): any => {
    return store.clearStateForKey(CURRENT_PATH_KEY);
  };

  /**
  * Saves a current location to disk.
  */
  saveLocation = (location: any) => {
    if (location.pathname.includes('oauth')) return;
    store.saveStateForKey(location, CURRENT_PATH_KEY);
  };

  //------------------------------------
  // UI Builders
  //------------------------------------

  buildSpinner = () => {
    if (!this.state.loadingUser) {
      return null
    }

    const classname = Classname({
      'spinner-container': true,
      'd-flex': true,
      'justify-content-center': true,
      'align-items-center': true,
    });
    return (
      <div className={classname}>
        <Spinner large />
      </div>
    );
  };

  /**
   * Optionally builds an error component
   */
  buildError = () => {
    const errorLayout = (err: string | Error, description: string, emoji: string, subtitle: string, supportLink: string, supportLinkLabel: string) => {
      const formattedMessage = normalizeErrorMessage(err)
      return (
        <Row>
          <Col className="full-screen" xs={12}>
            <Row>
              <EmptyComponent title={formattedMessage} subtitle={subtitle} emoji={emoji} description={description} supportLink={supportLink} supportLinkLabel={supportLinkLabel} />
            </Row>
          </Col>
        </Row >
      )
    }

    if (this.props.appError) {
      const err = this.props.appError.message || this.props.appError
      const subTitle = this.props.appErrorIsZapierFormatted ? 'Zapier Error:' : ''
      const description = this.props.appErrorIsZapierFormatted ? 'Please try fixing the error, or contacting support at:' : 'Please try reloading, or contacting support at:'
      const supportLink = `https://zapier.com/app/contact-us`
      const supportLinkLabel = `Zapier Support Contact From`
      return errorLayout(err, description, '😪', subTitle, supportLink, supportLinkLabel)
    }

    return null
  }


  render() {

    const error = this.buildError()
    const loadingSpinner = this.buildSpinner();

    return (
      <div className='master-container'>
        <FullStory org={FULLSTORY_ORG_ID} />
        <Navbar onLogout={this.props.logout} />
        <Flash />
        {error || loadingSpinner || this.props.children}
      </div>
    );
  }
}

const mapStateToProps = (state: RootReducerState) => ({
  appError: state.error.error,
  appErrorIsZapierFormatted: state.error.zapierFormattedError,
  user: state.user.me,
  loadingUser: state.user.refreshingUser
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...actions.UserActions,
      ...actions.SessionActions,
      ...actions.ErrorActions,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
