import './style.scss';

import * as React from 'react';
import * as RB from 'react-bootstrap';

import * as model from '../../common/models';

type Props = {
  task: model.Task;
};

/**
 * ZapProgresBar renders a progress bar for a Zap.
 */
export const TaskProgressBar = (props: Props) => {
  if (!props.task || !props.task.running) return <></>;
  const complete = props.task.completed_records;
  const total = props.task.total_records;
  const progress = (complete! / total!) * 100;
  return (
    <RB.ProgressBar
      className="task-progress-bar"
      now={progress}
      animated
      striped
    />
  );
};

export default TaskProgressBar;
