import './style.scss';
import * as React from 'react';
import * as RB from 'react-bootstrap';
import { RouterProps, RouteComponentProps } from '@reach/router';

type Props = RouterProps & RouteComponentProps<any>;

/**
 * Terms for Alpha Zapier PRojects
 */
class Terms extends React.Component<Props & React.RefAttributes<HTMLElement>, any> {
    render() {
        return (
            <RB.Container className={'transfer-terms'}>
                <RB.Col xs={{ span: 6, offset: 3 }} >
                    <RB.Row>
                        <h1>
                            Alpha Service Addendum to Zapier Terms of Service
                    </h1>

                    </RB.Row>
                    <RB.Row>
                        <h5>
                            Last updated: 9/10/2020
                </h5>
                    </RB.Row>
                    <RB.Row><p>We are excited to provide our users with access to our services ahead of a commercial release, and to get your feedback on our services. However, because the services we provide pursuant to this Addendum are not commercially available, and because we provide the service to you free of charge for a test period (“Alpha Service”), certain terms in the Zapier Terms of Service (“Terms”) do not apply to the Alpha Service and certain additional terms apply, as set forth below, in this Alpha Test Addendum (“Addendum”). Any defined terms in this Addendum has the meaning set forth in this Addendum or, if not in this Addendum, in the Terms. Any Terms not expressly amended in this Addendum remain of full force and effect. By using the Alpha Service, you agree to the applicable Terms as expressly amended or supplemented by this Addendum. In the event of any conflict between the Terms and this Addendum, this Addendum shall control but only for the Alpha Service and related matters covered in this Addendum.</p></RB.Row>
                    <RB.Row><p>We will provide you with access to the Alpha Service for a period of time for testing purposes, during which period, the Alpha Service is provided to you “As-Is” and without any representations, warranties or indemnities. We grant you the limited right to access the Alpha Service for the period we make the service available. Nothing in this Addendum grants you any other right, title or interest in or to the Alpha Service. We retain all right, title and interest in and to the Alpha Service. You agree that your access and use of the Alpha Service will comply with the Terms and the <a href={'https://zapier.com/aup'}>AUP</a>.</p></RB.Row>
                    <RB.Row><p>While we endeavor to ensure that the Alpha Service is secure, we make no representation to the security or integrity of any data processed via the Alpha Service and expressly disclaim any representations made in the Section titled “Our Security and Data Privacy Practices” of the Terms.</p></RB.Row>
                    <RB.Row><p>When you access and use the Alpha Service, we will automatically collect, process and store information from you about your use, your posts or about features, statistical reports, bug or errors, or the functionality or interoperability of the Alpha Service. We use this information for the purpose of further analyzing, evaluating and developing the Alpha Service. In addition, we will ask you to provide us with information about your experience with the Alpha Service as well as your contact information (name and email address). We will combine the information we collect about you with the information you provide us and we may share it with our third party service providers in accordance with our <a href={'https://zapier.com/privacy'}>Privacy Policy</a>.</p></RB.Row>
                    <RB.Row><p>For participating in the Alpha Service, you agree to provide us with your ideas, suggestions, improvements, concepts, enhancements or other information about the Alpha Service (“Feedback”). Feedback may contain your original ideas or inventions. You agree that, upon your submission of the Feedback, you irrevocably transfer and assign all of your right, title and interest in or to the Feedback to us. To the extent the Feedback is not effectively assigned to us pursuant to the foregoing assignment, upon your submission to us, you grant us a perpetual, irrevocable, royaltyfree, fully paid-up, transferable, worldwide license to make, use, sell, sublicense, execute, reproduce, distribute and modify the Feedback, create derivatives of the Feedback, or otherwise commercially exploit the Feedback by all means and in any medium or format, now known or later developed.</p></RB.Row>
                    <RB.Row><p>You agree that any information that is disclosed to you during your use of the Alpha Service, including the fact that the Alpha Service is made available, or information that you learn about the Alpha Service through your access or use of the Alpha Service, is our confidential and proprietary information (“Confidential Information”). In addition to any restrictions in the Terms, you agree not to publish, disseminate or otherwise share our Confidential Information with any third party or use our Confidential Information for you own benefit or for the benefit of any third party. You may only use our Confidential Information to access, use and test the Alpha Service. Notwithstanding anything to the contrary in the Terms, you agree that you will continue to keep our Confidential Information confidential for so long as you retain Confidential Information and you agree not to use or disclose our Confidential Information following the release of a product or feature that may arise from the Alpha Service.</p></RB.Row>
                    <RB.Row><p>We may update, change or modify the Alpha Service (or any component thereof) at any time, for any reason, with or without prior notice to you. We may suspend or terminate your access to the Alpha Service (in part or in whole) at any time, for any reason, without prior notice you.</p></RB.Row>
                    <RB.Row><p>The Alpha Service is a pre-release service and may not be available or operable, may contain bugs or errors, or otherwise may not function properly. Therefore, Section 7(a) “Service Warranty”, Section 7(d) “Zapier’s Indemnification” do not apply to the Alpha Service. Alpha Service is provided As-Is, with all faults. Section 8 “Limitation of Liability” does not apply to the Alpha Service and the following limitation of liability applies to the Alpha Service:</p></RB.Row>
                    <RB.Row><p>YOU ASSUME ALL RISKS AND ALL COSTS ASSOCIATED WITH THE ALPHA SERVICE, INCLUDING, WITHOUT LIMITATION, ANY DAMAGE TO ANY EQUIPMENT, SOFTWARE, INFORMATION OR DATA. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, NEITHER WE NOR OUR LICENSORS SHALL BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE, SPECIAL, EXEMPLARY OR STATUTORY DAMAGES (INCLUDING, WITHOUT LIMITATION, LOSS OF BUSINESS, LOSS OF PROFITS, LOSS OF REVENUE, LOSS OF DATA OR LOSS OF GOODWILL), EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND REGARDLESS OF THE LEGAL THEORY UNDER WHICH DAMAGES ARE SOUGHT, WHETHER IN BREACH OF CONTRACT OR IN TORT, INCLUDING NEGLIGENCE. IN NO EVENT WILL OUR TOTAL AGGREGATE LIABILITY TO YOU FOR ALL DAMAGES ARISING FROM OR RELATED TO THE ALPHA SERVICE EXCEED FIFTY DOLLARS ($50.00). THE FOREGOING LIMITATIONS WILL APPLY EVEN IF THE ABOVE STATED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.</p></RB.Row>

                </RB.Col>
            </RB.Container>
        )
    }
}

export default Terms;
