import './style.scss';
import * as React from 'react';
import { addBreadcrumb } from '@sentry/browser';
import { ChangeEvent } from 'react';
import Fuse from 'fuse.js';
import { Table, Switch, Input, Tabs, Modal, Select, Spin, List, Tooltip, Collapse } from 'antd';
import { TableRowSelection, ColumnType, TablePaginationConfig } from 'antd/lib/table/interface';
import { LogoutOutlined, LoginOutlined, CaretRightOutlined, LoadingOutlined, CheckCircleOutlined, CloseCircleOutlined, MinusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { RouterProps } from '@reach/router';
import { bindActionCreators, Dispatch } from 'redux';
import * as Feather from 'react-feather';
import { filter, sortBy, find, isObject, object, indexBy, keys, isString, isNumber, isBoolean } from 'underscore';
import { Col, Row, Container, Image } from 'react-bootstrap';
import { SpinProps } from 'antd/lib/spin';
import Button from './Button';
import { validateZapForLoad, actionForNode } from '../../common/utils/zap';
import Analytics, { Action } from '../../common/utils/analytics';

// Reducers
import { RootReducerState } from '../../common/reducers';

// Actions
import { ZapActions } from '../../common/actions/zap';
import { SessionActions } from '../../common/actions/session';

// Components
import EmptyComponent from '../Shared/EmptyComponent';
import AppIcon, { AppIconSize } from '../Shared/AppIcon';
import Spinner from '../../common/components/Spinner';

import SendingRecordsConfirmationModal from './modals/SendingRecordsConfirmation';

// Models
import { Node, Give, NodeOutput, FormattedNodeInput, Zap, WritesByRootID } from '../../common/models';

// Utils
import Classname from '../../common/utils/classname';
import { flattenNestedParams, mapInputParamsToNodeWriteParams } from '../../utils/paramTemplateMapping';


const { Option } = Select;
const { Panel } = Collapse;

// Constants
const NO_INDEX_FLAG = 'NO_INDEX_FLAG';
const TRANSFER_PROGRESS_COL_KEY = '__transfer_write_progress';

// Spinner
// const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

// Props
type ConnectedState = ReturnType<typeof mapStateToProps>;
type ConnectedActions = ReturnType<typeof mapDispatchToProps>;
type Props = ConnectedState & ConnectedActions & RouterProps;

type SelectionKeyMap = { [key: string]: boolean }; // All keys in JS are strings, so for typings

// Interface for data associated with the table
const rowIDKey = '__zapier_table_key';
export interface IRowData {
  [rowIDKey]: string;
  [x: string]: any;
}

// Enum to control the shown
enum TabState {
  DataIn,
  DataOut
}

// State
const initialState = {
  // Component Data
  error: null as null | string,
  selectedStep: 0, // The Node Step we're on
  writeStepIdx: 0, // Where we are in a write step index
  zapID: null as number | null,
  zapTemplateID: null as number | null,
  showSendToZapModal: false,
  showSentRecordsToZapModal: false,
  showFilteringOptions: false,

  // Table Data. We keep in State because we need to do
  // some processing in the component.
  rowData: null as null | IRowData[],
  recordsByID: {} as { [key: string]: NodeOutput | FormattedNodeInput },
  columns: null as null | ColumnType<IRowData>[],

  // Table State
  selectedRowKeys: {} as SelectionKeyMap,
  onlyShowSelectedRecords: false,
  queryResults: null as null | Fuse.FuseResult<IRowData>[],
  indexColKey: null as null | string,
  fuseIdx: null as null | Fuse<IRowData, Fuse.IFuseOptions<IRowData>>,
  currentTab: TabState.DataIn as TabState
};
type State = typeof initialState;

/**
 * Renders a list of Tasks for the currently authenticated user.
 */
class BulkZapRunner extends React.Component<Props, State> {
  private progressContext: React.Context<WritesByRootID>;

  constructor(props: Props) {
    super(props);
    this.state = { ...initialState };
    this.progressContext = React.createContext(this.props.zapActiveWrites);
  }

  //------------------------------------
  // Lifecycle CBs
  //------------------------------------

  async componentDidMount() {
    Analytics.trackPage(this.props.location);
    this.props.resetZapState();
    this.checkForZapIDAndFetch();
  }

  async componentDidUpdate(prevProps: Props, prevState: State) {
    const zapChanged = (!prevProps.zap && this.props.zap) || (prevProps.zap?.id !== this.props.zap?.id);
    if (zapChanged && this.props.zap) {
      // We've got a new zap, lets fetch records!
      this.setState({ rowData: null });
      this.props.fetchZapReadRecords(this.props.zap, this.state.zapTemplateID);
      window.scrollTo(0, 0);
    }

    const writeStarted = Object.keys(prevProps.zapActiveWrites).length === 0 && Object.keys(this.props.zapActiveWrites).length > 0;
    const writeEnded = Object.keys(prevProps.zapActiveWrites).length > 0 && Object.keys(this.props.zapActiveWrites).length === 0;

    // If we have new records and gives, but haven't processed the row data, kick that off
    if ((this.state.selectedStep === 0) && this.currentNodeGives() && this.currentNodeRecords() && (this.state.rowData?.length !== this.currentNodeRecords()!.length)) {
      this.buildTableDataAndIndexes();
    } else if (this.state.selectedStep !== 0 && this.state.currentTab === TabState.DataIn && !this.state.rowData) {
      this.buildTableDataAndIndexes();
    } else if (prevState.currentTab !== this.state.currentTab) {
      this.buildTableDataAndIndexes(); // Switched Tab
    } else if (writeStarted || writeEnded) {
      this.buildTableDataAndIndexes(); // Triggered a write to zap
    }

    // Monitor for writing state changes to pop dialog
    if (prevProps.writingToZap && !this.props.writingToZap) {
      this.setState({ showSentRecordsToZapModal: true });
    }
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    console.error('getDerivedStateFromError: ', error);
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.error('componentDidCatch: ', error, errorInfo);
  }

  //------------------------------------
  // Node / Data Helpers
  //------------------------------------

  currentNode(): Node | null {
    return this.props.zap?.nodes[this.state.selectedStep] || null;
  }

  currentNodeRecords(): NodeOutput[] | null {
    const currentNode = this.currentNode();
    if (!currentNode) {
      return [];
    }
    return this.props.nodeOutputsByNodeID[currentNode.id];
  }

  currentNodeGives(): Give[] | null {
    const currentNode = this.currentNode();
    if (!currentNode) {
      return [];
    }
    return this.props.zapGivesByNodeID[currentNode.id];
  }

  previousNode(): Node | null {
    if (this.state.selectedStep > 0) {
      return this.props.zap!.nodes[this.state.selectedStep - 1];
    }
    return null;
  }

  previousNodeRecords(): NodeOutput[] {
    const previousNode = this.previousNode();
    if (!previousNode) {
      return [];
    }
    return this.props.nodeOutputsByNodeID[previousNode.id] || [];
  }

  previousNodeGives(): Give[] {
    const previousNode = this.previousNode();
    if (!previousNode) {
      return [];
    }
    return this.props.zapGivesByNodeID[previousNode.id] || [];
  }

  activeRecords(): NodeOutput[] | null {
    if (this.state.currentTab === TabState.DataIn && this.state.selectedStep > 0) {
      return this.previousNodeRecords();
    }
    return this.currentNodeRecords();
  }

  activeGives(): Give[] | null {
    if (this.state.currentTab === TabState.DataIn && this.state.selectedStep > 0) {
      return this.previousNodeGives();
    }
    return this.currentNodeGives();
  }

  currentNodeNeeds(): { [key: string]: any } {
    return this.currentNode()!.params;
  }

  currentNodeNodeNeedsPopulated(rootID?: string): FormattedNodeInput[] | [] {
    const currentNode = this.currentNode();
    const currentNodeNeeds = this.currentNodeNeeds();
    if (!currentNode || !currentNodeNeeds || this.state.selectedStep === 0) {
      return [];
    }
    const previousNodeRecords = this.previousNodeRecords().reverse();
    const filteredRecords = filter(previousNodeRecords, (nodeOutput: NodeOutput) => {
      if (rootID) {
        return rootID === nodeOutput.rootID;
      }
      return this.state.selectedRowKeys[nodeOutput.rootID];
    });
    const filledNeeds = filteredRecords.map((record: NodeOutput) => {
      let currentRecord: NodeOutput | null = record;
      let nodeNeeds = currentNodeNeeds;
      while (currentRecord) {
        nodeNeeds = mapInputParamsToNodeWriteParams(currentRecord.data, currentRecord.nodeID, nodeNeeds);
        currentRecord = currentRecord.previousNode;
      }
      return new FormattedNodeInput(nodeNeeds, record);
    });
    return filledNeeds;
  }

  async checkForZapIDAndFetch() {
    const { location } = this.props;
    if (location) {
      const params = new URLSearchParams(location.search);
      // Typical
      const zapID = params.get('id');
      const zapTemplateID = params.get('zt_id');

      let ztID: number | undefined;
      if (zapTemplateID?.length) {
        this.setState({ zapTemplateID: parseInt(zapTemplateID) });
        ztID = parseInt(zapTemplateID);
      }

      if (zapID?.length) {
        return this.props.fetchZap(parseInt(zapID), ztID);
      }
    }
  }

  currentStepIntegrationTitle = () => {
    return this.props.zap?.nodes[this.state.selectedStep].implementation.name;
  };

  //------------------------------------
  // CBHandlers
  //------------------------------------

  writeRecordsToFullZap = () => {
    const records = this.currentNodeRecords()!;
    const filteredRecords = filter(records, (nodeOutput: NodeOutput) => {
      return this.state.selectedRowKeys[nodeOutput.rootID];
    }).reverse();
    const currentNode = this.currentNode();
    const user = this.props.currentUser;
    if (!this.state.rowData || !this.props.zap || !filteredRecords || !user || !currentNode) {
      throw Error('Unable to perform write with no row data');
    }

    this.props.writeRecordsToZap(filteredRecords, user, currentNode);
    this.setState({ showSendToZapModal: false });
  };

  writeRecordsToCurrentNode = (rootID?: string) => {
    if (!this.state.rowData || !this.props.zap) {
      throw Error('Unable to perform write with no row data');
    }

    const currentNode = this.currentNode();
    if (!currentNode) {
      throw Error('Unable to perform write due to missing node');
    }
    const populatedNeeds = this.currentNodeNodeNeedsPopulated(rootID);
    this.props.writeRecordsToNode(populatedNeeds, currentNode);
    this.setState({ currentTab: TabState.DataOut });
  };

  selectAllRows = () => {
    if (!this.state.rowData) {
      return;
    }
    this.setState({ selectedRowKeys: object(this.state.rowData.map((record: IRowData) => [record.key, true])) });
  };

  setNewFilterQuery = (query: string) => {
    if (this.state.fuseIdx && query.length) {
      const res = this.state.fuseIdx.search(query);
      return this.setState({ queryResults: res });
    }
    return this.setState({ queryResults: null });
  };

  filterChanged = (change: ChangeEvent<HTMLInputElement>) => {
    const queryString = change.target.value;
    this.setNewFilterQuery(queryString);
  };

  writeRow = (rowData: IRowData) => {
    this.writeRecordsToCurrentNode(rowData.key);
  };

  /**
   * Gets the value of a object given the zapier `foo__bar__baz` double
   * underscore notation.
   * @param obj {object}
   * @param path {string}
   */
  retrieveUnderscorePathLookup = (obj: any, path: string) => {
    const nestedKeys = path.split('__');
    let foundObj = nestedKeys.length ? obj[nestedKeys[0]] : null;
    // Get the first val out, if it's not there, bail.
    if (!foundObj) {
      return foundObj;
    }


    for (let i = 1, len = nestedKeys.length; i < len; i++) {
      const val = foundObj[nestedKeys[i]];
      if (val) {
        foundObj = val;
      } else {
        return null;
      }
    };
    return foundObj;
  };

  onSelectChange = (selectedRowKeys: any[]) => {
    const keyMap = selectedRowKeys.reduce((keys: SelectionKeyMap, key: number) => { keys[key] = true; return keys; }, {});
    this.setState({ selectedRowKeys: keyMap });
  };

  toggleSelection = (key: number) => {
    const rowKeys = this.state.selectedRowKeys;
    if (rowKeys[key]) {
      delete rowKeys[key];
    } else {
      rowKeys[key] = true;
    }
    this.setState({ selectedRowKeys: rowKeys });
  };

  onRowHandler = (record: IRowData): React.HTMLAttributes<HTMLElement> => {
    return {
      onClick: () => {
        if (this.state.selectedStep === 0) {
          this.toggleSelection(record.key);
        }
      }
    };
  };

  onNodeRowClick = (rowID: number) => {
    if (this.state.selectedStep !== rowID) {
      this.props.resetWriteState();
      this.setState({ selectedStep: rowID, rowData: null, currentTab: TabState.DataIn });
    }
  };

  //------------------------------------
  // Menu Pane Setup
  //------------------------------------

  buildNodeRows = () => this.props.zap?.nodes.map((node: Node, idx: number) => (
    <Row className={`node-cell ${this.state.selectedStep === idx ? 'selected' : 'not-selected'}`} key={idx} noGutters onClick={this.onNodeRowClick.bind(this, idx)}>
      <Feather.ChevronRight className="icon" size={30} />
      <div className="header d-flex align-items-center">
        <div className='circle-header'>{idx + 1}</div>
        <span className='action-header'>{node.type_of}</span>
      </div>
      <div className="cell-body">
        <div className="app-icon">
          <AppIcon selected_api={node.selected_api} size={AppIconSize.SIZE_64} />
        </div>
        <div className="text">
          <h3 className='node-title'>{node.implementation?.name}</h3>
          <h5 className='node-action'>{actionForNode(node)?.name}</h5>
        </div>
      </div>
      <div className="overlay" />
    </Row>
  )
  );

  //------------------------------------
  // Table Setup
  //------------------------------------


  buildTableColumns = (): ColumnType<IRowData>[] => {
    // If we have custom fields, only show those. Otherwise, show them all
    // Base Records
    let columns: ColumnType<IRowData>[];
    let filteredGives = this.activeGives();
    if (this.state.selectedStep === 0 && filteredGives) {
      // Cols are the params (Needs)
      const customFieldsPresent = find(filteredGives, (give: Give) => give.custom_field);
      if (customFieldsPresent) {
        filteredGives = filter(filteredGives, (give: Give) => give.custom_field);
      }

      const sortedGives = sortBy(filteredGives, (give: Give) => -give.score);
      columns = sortedGives.map((give: Give) => {
        return {
          title: give.label || give.key,
          dataIndex: give.key,
          key: give.key,
          width: '200px',
          shouldCellUpdate: () => false,
          sorter: (a: IRowData, b: IRowData) => {
            const aVal = a[give.key];
            const bVal = b[give.key];
            if (!aVal) return 1;
            if (!bVal) return -1;
            if (isString(aVal)) {
              return bVal.localeCompare(aVal);
            } if (isNumber(aVal)) {
              return aVal - bVal;
            }
            return 0;
          },
          ellipsis: {
            showTitle: false,
          },
          render: (val: any) => (
            <Tooltip placement="topLeft" title={`${give.label || give.key}: ${val}`}>
              {val}
            </Tooltip>
          ),
          sortDirections: ['descend', 'ascend'],
        };
      });
    } else {
      // Cols are the output of the node (Record + Gives)
      const currentNode = this.currentNode()!; // Node has to exist to be here
      const paramsForNode = currentNode.params;
      const flatParams = flattenNestedParams(paramsForNode);
      const colKeys = keys(flatParams);
      const sortedColKeys = sortBy(colKeys, (key: string) => {
        const val = flatParams[key];
        if (isString(val) && (val as string).indexOf('{{') > -1) {
          return -1;
        }
        return 1;
      });
      columns = sortedColKeys.map((key: string) => {
        const col: ColumnType<IRowData> = {
          title: key,
          dataIndex: key,
          key,
          sorter: true,
          sortDirections: ['descend', 'ascend'],
          width: '100px',
          shouldCellUpdate: () => false
        };
        return col;
      });
    }

    // Add a progress indicator to records in flight
    if (Object.keys(this.props.zapActiveWrites).length || (this.state.currentTab === TabState.DataOut && this.state.selectedStep !== 0)) {
      const ctx = this.progressContext;
      columns.unshift({
        key: TRANSFER_PROGRESS_COL_KEY,
        title: 'Status',
        width: '60px',
        className: 'progress-cell',
        render: (val: IRowData) => {
          return (
            <ctx.Consumer>
              {
                (activeWrites: WritesByRootID) => {
                  if (this.props.zapWriteErrors[val[rowIDKey]]) {
                    return (
                      <Tooltip placement="top" title={this.props.zapWriteErrors[val[rowIDKey]] || 'An error occured'}>
                        <CloseCircleOutlined style={{ fontSize: 24, color: '#ff4a00' }} />
                      </Tooltip>
                    );
                  } if (this.props.zapWriteSuccesses[val[rowIDKey]]) {
                    return <CheckCircleOutlined style={{ fontSize: 24, color: '#62d493' }} />;
                  } if (activeWrites[val[rowIDKey]] !== undefined) {
                    return <LoadingOutlined style={{ fontSize: 24 }} spin={activeWrites[val[rowIDKey]] === undefined} />;
                  }
                  return <MinusOutlined style={{ fontSize: 24 }} />;

                }
              }
            </ctx.Consumer>
          );
        }
      } as ColumnType<IRowData>);
    }


    return columns;
  };

  buildTableDataAndIndexes = () => {
    let columnData = this.buildTableColumns();
    columnData = filter(columnData, (col: ColumnType<IRowData>) => (col.key as string).indexOf(NO_INDEX_FLAG) === -1); // Filter action Cols
    // Optionally surface (flatten) nested col gives
    const nestedColumns = filter(columnData, (col: ColumnType<IRowData>) => (col.key as string).split('__').length > 1);

    // Base Records
    let records: any[] | null = []; // Don't want to do this, but There's a TS error w the union of types and `map`
    if (this.state.selectedStep === 0) {
      records = this.activeRecords()!;
    } else {
      records = this.currentNodeNodeNeedsPopulated();
    }

    if (!records || !records.length) {
      return this.setState({
        recordsByID: {},
        rowData: [],
        fuseIdx: null
      });
    }

    // Convenience indexing here for needed fast lookups while reviewing data
    const recordsByID = indexBy(records, 'rootID');
    // Any needed mapping
    const seenKeys: { [key: string]: boolean } = {};
    const sortedData = sortBy(records, 'rootID') as any[];
    const rowData = sortedData.map((record: NodeOutput | FormattedNodeInput, idx: number) => {
      const mappedObj = {
        key: record.data.key || record.rootID || idx,
        [rowIDKey]: record.rootID,
        ...record.data
      } as IRowData;

      // We need to unpack the zapier `foo__bar` key syntax. If there aren't any
      // gives doing this, we ignore it. For all gives w a `__` though, we attempt to
      // flatten the object value to match the key

      for (const nestedCol of nestedColumns) {
        const nestedKey = nestedCol.key as string;
        const lookupVal = this.retrieveUnderscorePathLookup(mappedObj, nestedKey);
        mappedObj[nestedKey] = lookupVal;
      }

      // Need to clear out any boolean primitives for the table and indexing
      const keys = Object.keys(mappedObj);
      for (const key of keys) {
        if (isBoolean(mappedObj[key])) {
          mappedObj[key] = mappedObj[key].toString();
        } else if (isObject(mappedObj[key])) {
          mappedObj[key] = JSON.stringify(mappedObj[key]);
        }

        if (mappedObj[key] !== undefined && mappedObj[key] !== null) {
          seenKeys[key] = true;
        }
      }

      return mappedObj;
    }) as IRowData[];

    // Pluck unused columns
    const columns = filter(columnData, (col: ColumnType<IRowData>) => seenKeys[`${col.key}`] || col.key === TRANSFER_PROGRESS_COL_KEY);
    this.setState({
      recordsByID,
      columns,
      rowData
    }, () => {
      if (this.state.selectedStep === 0 && columnData.length) {
        this.buildFuseIdx(columnData[0].key as string);
      }
    });
  };

  buildFuseIdx = (colKey: string) => {
    this.setState({ fuseIdx: null });
    if (!this.state.rowData) {
      return null;
    }
    const fuseOpts: Fuse.IFuseOptions<any> = {
      includeScore: true,
      includeMatches: false,
      threshold: 0.1,
      keys: [colKey]
    };
    addBreadcrumb({ category: 'fuse_opts', data: { fuseOpts } });
    const fuseIdx = new Fuse(this.state.rowData, fuseOpts);
    this.setState({ fuseIdx });
  };

  buildLoadingRecordsMessage = () => {
    if (this.props.writingToNode && this.state.rowData) {
      return `Writing ${this.state.rowData.length} records to ${this.currentNode()?.implementation.name}`
    }

    if (this.props.loadingZapRecords) {
      if (this.state.rowData?.length) {
        return `Loaded ${this.state.rowData.length} records from ${this.currentNode()?.implementation.name}`
      }
      return `Loading data from ${this.currentNode()?.implementation.name}`
    }
  }

  buildTable = () => {
    if (!this.props.zap) {
      return null;
    }

    // Data
    let dataSource = this.state.rowData;
    const { columns } = this.state;

    // State
    const allowSelection = this.state.selectedStep === 0;

    // Show spinner if we're loading
    if (this.props.loadingZap || this.props.loadingGives) {
      return this.buildSpinner();
    }

    // Define selection if we have rows
    const tableSelection: TableRowSelection<IRowData> = {
      selectedRowKeys: Object.keys(this.state.selectedRowKeys),
      onChange: this.onSelectChange,
      type: 'checkbox'
    };

    // Only show if we have a datasource and are viewing the root (read) node
    const writingRecords = Object.keys(this.props.zapActiveWrites).length > 0;
    const selection = (dataSource && allowSelection && !writingRecords) ? tableSelection : undefined;

    // If we don't show selection, but are not on the root step, then lets filter the datasource
    if (dataSource && !allowSelection) {
      dataSource = filter(dataSource, (record: IRowData) => this.state.selectedRowKeys[record[rowIDKey]]);
    }

    // Only show selected rows if we're on the first node and have toggled that view, or
    // if we are any node but the root node
    if ((this.state.onlyShowSelectedRecords || !allowSelection) && dataSource) {
      const filterKeys = this.state.selectedRowKeys;
      dataSource = filter(dataSource, (record: IRowData) => filterKeys[record.key]);
    }

    // Filter based on a query input
    if (this.state.queryResults && dataSource) {
      const filterKeys = this.state.queryResults.reduce((values: any, val: Fuse.FuseResult<IRowData>) => {
        values[val.item.key] = true;
        return values;
      }, {});
      dataSource = filter(dataSource, (record: IRowData) => filterKeys[record.key] !== undefined);
    }

    // Create custom pagination options
    const pageSizeOptions: TablePaginationConfig = {
      pageSizeOptions: ['10', '20', '50', '100', '1000'],
      defaultPageSize: 50
    };

    // Spin Info
    let loading: SpinProps | undefined;
    if (this.props.loadingZapRecords || dataSource === null) {
      loading = {
        spinning: true,
        tip: this.buildLoadingRecordsMessage(),
        className: 'loading-zap-records'
      };
    }

    // Return Table
    return (
      <Table
        dataSource={dataSource || []}
        columns={columns || []}
        loading={loading}
        rowSelection={selection}
        size="middle"
        onRow={this.onRowHandler}
        key={rowIDKey}
        pagination={pageSizeOptions}
      />
    );
  };

  //------------------------------------
  // Detail Dashboard
  //------------------------------------

  buildDashHeaderBar = () => {
    const currentNode = this.currentNode();
    const writesInProgress = Object.keys(this.props.zapActiveWrites).length > 0;
    if (!currentNode) {
      return null;
    }
    const { title, subtitle } = this.promptsForStep();
    const writeButton = this.state.selectedStep > 0 && this.state.currentTab === TabState.DataIn ? (
      <Button children="Write All" type="primary" onClick={this.writeRecordsToCurrentNode.bind(this, undefined)} loading={writesInProgress} />
    ) : null;
    return (
      <div className='write-actions d-flex justify-content-between align-items-center'>
        <div className="d-flex align-items-center">
          <div className="app-icon">
            <AppIcon selected_api={currentNode!.selected_api} size={AppIconSize.SIZE_64} />
          </div>
          <div className="d-inline-block">
            <h5 className="write-action-description">{title}</h5>
            <p className="write-action-description-subtext">{subtitle}</p>
          </div>
        </div>
        {writeButton}
      </div>
    );
  };

  promptsForStep = () => {
    const currentNode = this.currentNode();
    if (!currentNode) {
      return {
        title: '',
        subtitle: ''
      };
    }

    if (currentNode.type_of === 'read') {
      return {
        title: `${this.currentStepIntegrationTitle()}`,
        subtitle: `Choose the data from ${this.currentStepIntegrationTitle()} to send to your Zap`
      };
    } if (currentNode.type_of === 'write') {
      return {
        title: `Write to ${this.currentStepIntegrationTitle()}`,
        subtitle: 'Confirm the data to be written, and adjust it in a prior step if it\'s not correct.'
      };

    } if (currentNode.type_of === 'search') {
      return {
        title: `Search from ${this.currentStepIntegrationTitle()}`,
        subtitle: 'Perform a search with the provided parameters.'
      };
    }
    throw new Error('Node type not supported');
  };

  buildStepActionBar = () => {
    const { TabPane } = Tabs;
    const stepBarChanged = (activeKey: string) => {

      this.setState({
        currentTab: activeKey === '1' ? TabState.DataIn : TabState.DataOut
      });
    };
    const nodeRecords = this.currentNodeRecords();
    let nodeRecordsPopulated = true;
    if (!nodeRecords || nodeRecords.length === 0) {
      nodeRecordsPopulated = false;
    }

    return (
      <Tabs defaultActiveKey="1" activeKey={this.state.currentTab === TabState.DataIn ? '1' : '2'} onChange={stepBarChanged}>
        <TabPane
          tab={(
            <span className="d-flex align-items-center">
              <LoginOutlined />
                  Data In
            </span>
          )}
          key="1"
        />
        <TabPane
          tab={(
            <span className="d-flex align-items-center">
              <LogoutOutlined />
                  Data Out
            </span>
          )}
          key="2"
          disabled={!nodeRecordsPopulated}
        />
      </Tabs>
    );
  };

  buildTableActionBar = () => {
    if (!this.props.zap) {
      return null;
    }

    let content;
    if (this.state.selectedStep === 0) {
      // We're in our base read step.
      const showFilterPanel = this.state.showFilteringOptions ? ['1'] : [];
      content = (
        <>
          {this.buildDashHeaderBar()}
          <div className='d-flex justify-content-between align-items-center mt-3'>
            {this.buildLeadingZapActions()}
            {this.buildShowSelectionToggle()}
          </div>
          <div className='d-flex justify-content-between align-items-center'>
            <Collapse className="filter-actions" activeKey={showFilterPanel} ghost>
              <Panel header="" key="1" showArrow={false}>
                {this.buildLeadingActionContent()}
              </Panel>
            </Collapse>
          </div>
        </>
      );
    } else {
      // We're in something else than the base node (read). To be expanded...
      content = (
        <div className='d-flex flex-column'>
          {this.buildDashHeaderBar()}
          {this.buildStepActionBar()}
        </div>
      );
    }
    return (
      <div className='action-bar'>
        {content}
      </div>
    );
  };

  buildSendRecordsToZapModal = () => {
    const closeHandler = () => {
      this.setState({ showSendToZapModal: false });
    };

    return (
      <Modal
        className="send-to-zap-modal"
        visible={this.state.showSendToZapModal}
        onCancel={closeHandler}
        title={(
          <div className="d-flex align-items-center flex-column">
            <Image className="header-img" src="https://cdn.zapier.com/storage/photos/afef29df1ae0b20fbdf8232ff6d73849.png" />
            <p className='title'>
              {`Send data from ${this.currentStepIntegrationTitle()} to your `}
              <span className="zapier">Zap</span>
            </p>
          </div>
        )}
        footer={null}
      >
        <div className="d-flex align-items-center flex-column content">
          <p className="send-to-zap-instructions">
            {`Your Zap will perform all steps using the selected data from ${this.currentStepIntegrationTitle()}. Each action your Zap completes successfully will `}
            <br />
            <a href="https://zapier.com/help/manage/tasks/learn-about-tasks-in-zapier#step-1" target="_target">count as one task.</a>
          </p>
          <Button className="send-to-next-step" type="primary" children="Send data to Zap" disabled={false} onClick={this.writeRecordsToFullZap} />
        </div>
      </Modal>
    );
  };

  buildConfirmRecordsSentToZapierModal = () => (
    <SendingRecordsConfirmationModal
      visible={this.state.showSentRecordsToZapModal}
      zapID={this.props.zap?.id || 0}
      rootAppName={this.currentStepIntegrationTitle() || ''}
      onClose={() => this.setState({ showSentRecordsToZapModal: false })}
      overrideData={this.props.zapPartnerMetadata}
    />
  );


  buildLeadingZapActions = () => {
    const unselectRows = () => {
      this.setState({ selectedRowKeys: {}, onlyShowSelectedRecords: false });
    };

    const sendRecordsToZap = () => {
      const disabled = !(Object.keys(this.state.selectedRowKeys).length && !Object.keys(this.props.zapActiveWrites).length);
      if (disabled) {
        return Modal.warning({
          title: 'First select records from the table',
          content: 'Before sending any records to your zap, you need to select one or more from the table.',
        });
      }

      this.setState({ showSendToZapModal: true });
    };

    const revealFilteringOptions = () => {
      this.setState({ showFilteringOptions: !this.state.showFilteringOptions });
    };

    const sendToZapDescription = 'Select trigger data below to send to your Zap. ';
    const filteringOptionsDescription = 'Reveal the filtering and selections options.';

    return (
      <div className='zap-action-buttons d-flex'>
        <Tooltip placement="topLeft" title={sendToZapDescription}>
          <Button className="send-to-zap" children="Send data to Zap" type="primary" onClick={sendRecordsToZap} />
        </Tooltip>
        <Tooltip placement="topLeft" title="Select all of the records is in the source application">
          <Button children="Select All" onClick={this.selectAllRows} disabled={!this.state.rowData} />
        </Tooltip>
        <Tooltip placement="topLeft" title="Unselect all of the records selected">
          <Button children="Unselect All" onClick={unselectRows} disabled={!this.state.rowData} />
        </Tooltip>

        <Tooltip placement="topLeft" title={filteringOptionsDescription}>
          <Button className="reveal-filtering" children="Filtering options" onClick={revealFilteringOptions} />
        </Tooltip>
      </div>
    );
  };

  buildLeadingActionContent = () => {

    const buildFilterSelection = () => {
      if (!this.state.columns || !this.state.columns.length) {
        return null;
      }
      const cleanFilterTitle = (title?: string) => title?.replace('_', ' ');
      const opts = this.state.columns.map((col: ColumnType<IRowData>) => (<Option className="bulk-query-opt" key={col.key!} value={col.key!}>{cleanFilterTitle(col.title as string)}</Option>));
      const initial = this.state.columns[0];
      return (
        <Select defaultValue={initial.key} className="select-before" onChange={this.buildFuseIdx as any}>
          {opts}
        </Select>
      );
    };
    return (
      <div className='action-buttons d-flex'>
        <Input className="query-filter" addonBefore={buildFilterSelection()} placeholder="Filter Query" onChange={this.filterChanged} disabled={!this.state.rowData} />
      </div>
    );
  };

  buildDashboard = () => {
    let detailCol;
    if (this.state.selectedStep && !Object.keys(this.state.selectedRowKeys).length) {
      const rootName = this.props.zap?.nodes[0].implementation.name;
      const title = 'No records selected';
      const description = `You must select the records from ${rootName} that you would like to use in a later step`;
      detailCol = (
        <Col className="full-screen" xs={9}>
          <Row>
            <EmptyComponent title={title} emoji="🤔" description={description} />
          </Row>
        </Col>
      );
    } else {
      const table = this.buildTable();
      const actionBar = this.buildTableActionBar();
      detailCol = (
        <Col className="detail-col" xs={9}>
          <Row noGutters>
            {actionBar}
          </Row>
          <Row noGutters>
            {table}
          </Row>
        </Col>
      );
    }

    const nodeRows = this.buildNodeRows();
    return (
      <Row noGutters className={`dash-row selected-step-${this.state.selectedStep}`}>
        <Col className="side-bar" xs={3}>
          {nodeRows}
        </Col>
        {detailCol}
      </Row>
    );
  };

  //------------------------------------
  // Various UI Builders
  //------------------------------------

  buildZapsTable = () => {
    let data = this.props.zaps || [];
    data = data.filter((zap: Zap) => {
      let activeNodeCount = 0;
      for (const node of zap.nodes) {
        if (node.selected_api) {
          activeNodeCount++;
        }
      }
      return activeNodeCount > 1;
    });

    const appIconComponent = (zap: Zap) => {
      const nodes = zap.nodes.filter((node: Node) => node.selected_api !== null);
      let content;
      const firstAPI = nodes[0].selected_api;
      const lastAPI = nodes[nodes.length - 1].selected_api;
      if (nodes.length === 1) {
        content = (
          <div className="icon-bg">
            <AppIcon selected_api={firstAPI} size={AppIconSize.SIZE_24} />
          </div>

        );
      } else if (nodes.length === 2) {
        content = (
          <>
            <div className="icon-bg">
              <AppIcon selected_api={firstAPI} size={AppIconSize.SIZE_24} />
            </div>

            <CaretRightOutlined />
            <div className="icon-bg">
              <AppIcon selected_api={lastAPI} size={AppIconSize.SIZE_24} />
            </div>

          </>
        );
      } else if (nodes.length > 2) {
        content = (
          <>
            <div className="icon-bg">
              <AppIcon selected_api={firstAPI} size={AppIconSize.SIZE_24} />
            </div>
            <CaretRightOutlined />
            <div className="icon-bg">
              <p className="node-count-box">{nodes.length - 2}</p>
            </div>
            <CaretRightOutlined />
            <div className="icon-bg">
              <AppIcon selected_api={lastAPI} size={AppIconSize.SIZE_24} />
            </div>
          </>
        );
      }

      return (
        <div className='zap-icon d-flex align-items-center'>
          {content}
        </div>
      );
    };

    const zapListItemRender = (zap: Zap) => {
      let { title } = zap;
      if (!title && zap.nodes.length > 1) {
        const firstTitle = zap.nodes[0]?.implementation?.name || zap.nodes[0].selected_api;
        const lastTitle = zap.nodes[zap.nodes.length - 1]?.implementation?.name || zap.nodes[zap.nodes.length - 1].selected_api;
        title = `${firstTitle} to ${lastTitle}`;
      } else if (!title) {
        title = 'Name your Zap!';
      }

      // Button
      const zapStaticError = validateZapForLoad(zap);
      let meta: JSX.Element;
      let clickHandler: any;
      if (zapStaticError) {
        meta = (
          <Tooltip placement="top" title={`Sorry, ${zapStaticError}`}>
            <List.Item.Meta
              avatar={
                appIconComponent(zap)
              }
              title={title}
            />
          </Tooltip>
        );
      } else {
        clickHandler = this.props.fetchZap.bind(this, zap.id, undefined);
        meta = (
          <List.Item.Meta
            avatar={
              appIconComponent(zap)
            }
            title={title}
          />
        );
        // button = <Button disabled={false} onClick={this.props.fetchZap.bind(this, zap.id, undefined)}>{'Select'}</Button>
      }

      return (
        <List.Item onClick={clickHandler} key={zap.id}>
          {meta}
        </List.Item>
      );
    };
    return (
      <List
        dataSource={data}
        renderItem={zapListItemRender}
      />
    );
  };

  buildWelcomeModal = () => {

    if (!this.props.zaps && !this.props.loadingZaps && this.props.currentUser) {
      this.props.fetchZaps(this.props.currentUser.account_id);
    }

    let fixButton = null;
    if (this.props.zapErrorFixID) {
      const fixURL = `https://zapier.com/app/editor/${this.props.zapErrorFixID}`;
      fixButton = (
        <Button
          className="zap-error-fix d-flex justify-content-center"
          children="Fix My Zap"
          type="primary"
          onClick={() => {
            Analytics.trackAction(Action.ClickFixZap, {
              zapID: this.props.zapErrorFixID
            });
            window.open(fixURL, '_blank');
          }}
        />
      );
    }

    const title = (
      <p className='title'>
        {'Welcome to Transfer by '}
        <span className="zapier">Zapier</span>
      </p>
    );
    const errorMsg = this.props.zapError ? (
      <div className='zap-error-box'>
        <p className="zap-error-title">Error Loading Zap:</p>
        <p className="zap-error">
          {`${this.props.zapError} Please open your Zap at Zapier.com to address and fix the issue(s).`}
        </p>
        {fixButton}
      </div>
    ) : null;
    const content = errorMsg ? (
      <>
        {title}
        {errorMsg}
      </>
    ) : (
        <>
          {title}
          <p className='sub-title'>Transfer lets you import existing app data into your Zaps. To get started, select a Zap.</p>
          <p className='sub-sub-title'>
            <span className="bold">{'Note: '}</span>
                Transfer by Zapier is currently in development and may not always work as expected.
              </p>
        </>
      );
    return (
      <Modal
        centered
        className="bulk-welcome-modal"
        visible={!this.props.zap && !this.props.loadingZap}
        title={(
          <div className="d-flex align-items-center flex-column">
            <Image className="header-img" src="https://cdn.zapier.com/storage/photos/50848e0de60b893cdb07ebc909e81605.png" />
            {content}
          </div>
        )}
        footer={(
          <>
            <Button className="logout-button" children="Logout" onClick={this.props.logoutUser} />
          </>
        )}
      >
        <Spin tip="Loading your Zaps..." spinning={this.props.loadingZaps}>
          <div className="d-flex justify-content-center content">
            {this.buildZapsTable()}
          </div>
        </Spin>
            ,
      </Modal>
    );
  };

  buildShowSelectionToggle = () => {
    const onChange = (checked: boolean) => {
      if (this.state.onlyShowSelectedRecords !== checked) {
        this.setState({ onlyShowSelectedRecords: checked });
      }
    };

    return (
      <div className='d-flex align-items-end flex-column'>
        <p className='toggle-info'>Show only selected data</p>
        <Switch
          defaultChecked={false}
          onChange={onChange}
          checked={this.state.onlyShowSelectedRecords}
          disabled={!Object.keys(this.state.selectedRowKeys).length}
        />
      </div>
    );
  };

  buildSpinner = () => {
    const classname = Classname({
      'spinner-container': true,
      'd-flex': true,
      'justify-content-center': true,
      'align-items-center': true,
    });
    return (
      <div className={classname}>
        <Spinner large />
      </div>
    );
  };

  /**
   * Optionally builds an error component
   */
  buildError = () => {
    const errorLayout = (title: string, emoji: string) => {
      return (
        <Row>
          <Col className="full-screen" xs={12}>
            <Row>
              <EmptyComponent title={title} emoji={emoji} description="Please try reloading, or contacting support at zapier.com" />
            </Row>
          </Col>
        </Row>
      );
    };

    if (this.state.error) {
      return errorLayout(this.state.error, '😪');
    }
    return null;
  };

  buildModals = () => [
    this.buildWelcomeModal(),
    this.buildSendRecordsToZapModal(),
    this.buildConfirmRecordsSentToZapierModal()
  ];

  render() {
    // Show spinner if we're loading
    if (this.props.loadingZap) {
      return this.buildSpinner();
    }

    // Build UI
    const modals = this.buildModals();
    const dashboard = this.buildDashboard();
    const error = this.buildError();

    const content = error || (
      <>
        {modals}
        {' '}
        {dashboard}
      </>
    );
    return (
      <Container className="bulk-zap-runner-component" fluid>
        <this.progressContext.Provider value={this.props.zapActiveWrites}>
          {content}
        </this.progressContext.Provider>
      </Container>
    );
  }
}

const mapStateToProps = (state: RootReducerState) => ({
  zap: state.zap.currentZap,
  zaps: state.zap.zaps,
  zapError: state.zap.error,
  zapErrorFixID: state.zap.errorZapID,
  zapPartnerMetadata: state.zap.zapPartnerMetadata,
  nodeOutputsByNodeID: state.zap.nodeOutputsByRootNodeID,
  zapRecordsByRootID: state.zap.zapRecordsByRootRecordID,
  zapGivesByNodeID: state.zap.givesByNodeID,
  zapActiveWrites: state.zap.activeWrites,
  zapWriteErrors: state.zap.writeErrors,
  zapWriteSuccesses: state.zap.successfulWrites,
  zapTotalWrites: state.zap.totalWriteCount,
  loadingZap: state.zap.loadingZap,
  loadingZaps: state.zap.loadingZaps,
  loadingGives: state.zap.loadingGives,
  loadingZapRecords: state.zap.loadingRecords,
  writingToZap: state.zap.writingToZap,
  writingToNode: state.zap.writingToNode,
  currentUser: state.user.me,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...ZapActions,
      logoutUser: SessionActions.logout
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkZapRunner);
