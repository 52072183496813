// import './style.scss';

import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

// State
import { RootReducerState } from '../../common/reducers';

// Actions
import { AppActions } from '../../common/actions/app';
import { AuthActions } from '../../common/actions/auth';
import { TaskActions } from '../../common/actions/task';
import { FlashActions } from '../../common/actions/flash';
import { UserActions } from '../../common/actions/user';

// Components
import ZapMapper from '../../common/components/ZapMapper';

// Models
import { App } from '../../common/models/app';

// Props
type ConnectedState = ReturnType<typeof mapStateToProps>;
type ConnectedActions = ReturnType<typeof mapDispatchToProps>;
type Props = ConnectedState & ConnectedActions;

// State
const initialState = {
  apps: (null as unknown) as App[],
  showSetupModal: false,
  loading: true,
  runningZap: 0,
};
type State = typeof initialState;

/**
 * Renders a list of Bulk Zaps for the currently authenticated user.
 */
class MyZaps extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...initialState };
  }

  render() {
    const { tasksByID } = this.props.taskState;
    const tasks = Object.values(tasksByID);
    return (
      <div className="zap-mapper-component">
        <ZapMapper task={tasks[0]} />
      </div>
    );
  }
}

const mapStateToProps = (state: RootReducerState) => ({
  appState: state.app,
  taskState: state.task,
  authState: state.auth,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...AppActions,
      ...TaskActions,
      ...FlashActions,
      ...AuthActions,
      ...UserActions,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MyZaps);
