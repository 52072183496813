import CamelCase from 'camelcase-keys';
import qs from 'querystring';

// Models
import * as model from '../models';
import { Node } from '../models';

// Utis
import { Get, Put, Post, Delete } from '../utils/fetch';

export let TRANSFER_HOST = ''

const BASE_URLS = {
  'production': 'transfer-api.zapier.com',
  'staging': 'transfer-api.zapier-staging.com',
}

if (
  process.env.REACT_APP_VERCEL_ENV === 'production' ||
  process.env.REACT_APP_REMOTE === 'production')
{
  TRANSFER_HOST = BASE_URLS.production;
}
else if (
  process.env.REACT_APP_VERCEL_ENV === 'staging' ||
  process.env.REACT_APP_REMOTE === 'staging')
{
  TRANSFER_HOST = BASE_URLS.staging;
}


export const TRANSFER_BASE_URL = TRANSFER_HOST ? `https://${TRANSFER_HOST}/` : '/';
export const TRANSFER_BASE_API_V1_URL = `${TRANSFER_BASE_URL}v1`;
export const TRANSFER_V1_CONSUMER_PATH = 'v1/ws/records/'

export enum RecordType {
  Source = 'source',
  Destination = 'destination',
}

export default class Transfer {
  //---------------------------------
  // Task Resource
  //---------------------------------

  /**
   * Fetches all Tasks for a user.
   */
  getTasks = (): Promise<model.Task[]> => {
    const url = `${TRANSFER_BASE_API_V1_URL}/tasks/`;
    return Get(url).then((res: any) => {
      return (res as unknown) as model.Task[];
    });
  };

  /**
   * Fetches all Tasks for a user.
   */
  getTask = (taskID?: string): Promise<any> => {
    const url = `${TRANSFER_BASE_API_V1_URL}/tasks/${taskID}/`;
    return Get(url).then((res: any) => {
      return res;
    });
  };

  /**
   * Creates a new Task.
   */
  createTask = (task: model.Task): Promise<model.Task> => {
    const url = `${TRANSFER_BASE_API_V1_URL}/tasks/`;
    return Post(url, task).then((res: any) => {
      return res;
    });
  };

  /**
   * Updates a new Task.
   */
  updateTask = (task: model.Task): Promise<any> => {
    const url = `${TRANSFER_BASE_API_V1_URL}/tasks/${task.id}/`;
    return Put(url, task).then((res: any) => {
      return res;
    });
  };

  /**
   * Deletes an existing task.
   */
  deleteTask = (taskID: string): Promise<any> => {
    const url = `${TRANSFER_BASE_API_V1_URL}/tasks/${taskID}/`;
    return Delete(url);
  };

  /**
   * Runs an existing task.
   */
  runTask = (taskID: string): Promise<any> => {
    const url = `${TRANSFER_BASE_API_V1_URL}/tasks/${taskID}/run/`;
    return Post(url, {});
  };

  /**
   * Executes a Task test run.
   */
  testTask = (taskID: string): Promise<any> => {
    const url = `${TRANSFER_BASE_API_V1_URL}/tasks/${taskID}/test/`;
    return Post(url, {});
  };

  //---------------------------------
  // Task Records
  //---------------------------------

  /**
   * Fetches records for an existing bulk task.
   */
  getRecordsForTask = (task: model.Task, type: RecordType): Promise<any[]> => {
    const url = `${TRANSFER_BASE_API_V1_URL}/tasks/${task.id}/${type}_records/`;
    return Get(url);
  };

  //---------------------------------
  // Task Status
  //---------------------------------

  /**
   * Gets statuses for requested tasks.
   */
  getTaskStatus = (task_ids: string): Promise<any> => {
    const url = `${TRANSFER_BASE_API_V1_URL}/tasks/status?ids=${task_ids}`;
    return Get(url);
  };

  //---------------------------------
  // Invoke API Mappings
  //---------------------------------

  /**
   * Fetches records for an existing bulk task.
   */
  getRecords = (
    action: string,
    selected_api: string,
    params: any,
    action_type = 'read',
    authId?: number
  ): Promise<any[]> => {
    const queryObj: { [key: string]: any } = {
      action,
      action_type,
      selected_api,
      params: JSON.stringify(params),
    };

    if (authId) {
      queryObj.auth_id = authId;
    }
    const query = qs.stringify(queryObj);
    const url = `${TRANSFER_BASE_API_V1_URL}/actions/read/?${query}`;
    return Get(url);
  };

  writeRecords = (
    action: string,
    selected_api: string,
    params: any,
    authId?: number
  ): Promise<any> => {
    const postObj: { [key: string]: any } = {
      action,
      action_type: 'write',
      selected_api,
      params,
    };
    if (authId) {
      postObj.auth_id = authId;
    }
    const url = `${TRANSFER_BASE_API_V1_URL}/actions/write/`;
    return Post(url, postObj);
  };

  searchRecords = (
    action: string,
    selected_api: string,
    params: any,
    authId?: number
  ): Promise<any> => {
    const postObj: { [key: string]: any } = {
      action,
      selected_api,
      params,
    };
    if (authId) {
      postObj.auth_id = authId;
    }
    const url = `${TRANSFER_BASE_API_V1_URL}/actions/search/`;
    return Post(url, postObj);
  };

  //---------------------------------
  // Create Batch
  //---------------------------------

  createBatch = (
    customUserID: number,
    node: Node,
    records: any, // Zapier formatted node output params
  ): Promise<any> => {
    const payload: { [key: string]: any } = {
      node_code: node.code,
      custom_user_id: customUserID,
      records,
      node,
    };
    const url = `${TRANSFER_BASE_API_V1_URL}/batches/`;
    return Post(url, payload);
  };

  //---------------------------------
  // Writes Records to Zap
  //---------------------------------

  writeRecordsToZap = (
    customUserID: number,
    node: Node,
    params: any, // Zapier formatted node output params
    batch_id: string,
  ): Promise<any> => {
    const payload: { [key: string]: any } = {
      node_code: node.code,
      custom_user_id: customUserID,
      params,
      node,
      batch_id
    };
    const url = `${TRANSFER_BASE_API_V1_URL}/actions/write_to_zap/`;
    return Post(url, payload);
  };

  //---------------------------------
  // Zap Resource
  //---------------------------------

  fetchZaps = async (accountID?: number): Promise<model.Zap[]> => {
    let url = `${TRANSFER_BASE_API_V1_URL}/zaps/`;
    if (accountID) {
      url = `${url}?account_id=${accountID}`;
    }
    return Get(url).then((res: any) => {
      return res.objects as model.Zap[];
    });
  };

  fetchZap = async (zapID: number): Promise<model.Zap> => {
    const url = `${TRANSFER_BASE_API_V1_URL}/zaps/${zapID}/`;
    return Get(url).then((res: any) => {
      return res as model.Zap;
    });
  };

  //---------------------------------
  // Auths Resource
  //---------------------------------

  /**
   * Fetches all auths.
   */
  getAuths = async (selected_api?: string): Promise<model.Auth[]> => {
    let url = `${TRANSFER_BASE_API_V1_URL}/auths/`;
    if (selected_api) {
      url = `${url}?selected_api=${selected_api}`;
    }
    return Get(url).then((res: any) => {
      return res as model.Auth[];
    });
  };

  /**
   * Validates an auth.
   */
  validateAuth = async (auth_id: number): Promise<model.Auth[]> => {
    const path = `/auths/validate/?auth_id=${auth_id}`;
    const url = `${TRANSFER_BASE_API_V1_URL}${path}`;
    return Get(url);
  };

  //---------------------------------
  // Users Resource
  //---------------------------------

  /**
   * Fetches me.
   */
  getMe = async (): Promise<model.User> => {
    const url = `${TRANSFER_BASE_API_V1_URL}/users/me/`;
    return Get(url);
  };

  /**
   * Fetches state.
   */
  getState = async (): Promise<any> => {
    const url = `${TRANSFER_BASE_API_V1_URL}/users/state/`;
    return Get(url).then((res: any) => {
      return CamelCase(res, { deep: true });
    });
  };

  //---------------------------------
  // App Resource
  //---------------------------------

  /**
   * Fetches all apps.
   */
  getApps = async (filter?: string): Promise<model.App[]> => {
    let url = `${TRANSFER_BASE_API_V1_URL}/apps/`;
    if (filter) {
      url = `${url}?filter=${filter}`;
    }
    return Get(url).then((res: any) => {
      return (CamelCase(res) as unknown) as model.App[];
    });
  };

  /**
   * Fetches an app.
   */
  getApp = async (selected_api: string): Promise<model.App> => {
    let url = `${TRANSFER_BASE_API_V1_URL}/apps/`;
    if (selected_api) {
      url = `${url}?selected_api=${selected_api}`;
    }
    return Get(url).then((res: any) => {
      if (!res) return {} as model.App;
      return (CamelCase(res) as unknown) as model.App;
    });
  };

  /**
   * Fetches Private Apps.
   */
  getPrivateApps = async (): Promise<model.App[]> => {
    const url = `${TRANSFER_BASE_API_V1_URL}/apps/`;
    return Get(url).then((res: any) => {
      if (!res) return [] as model.App[];
      return (CamelCase(res) as unknown) as model.App[];
    });
  };

  //---------------------------------
  // Accounts Resource
  //---------------------------------

  /**
   * Fetches all connected accounts for the authenticated user and specified `app`.
   */
  getAccountsForApp = (appID: string): Promise<any[]> => {
    // return this.client.get(`/apps/${appID}/accounts`);
    return Promise.resolve([{ appID }]);
  };

  //---------------------------------
  // Action Resource
  //---------------------------------

  /**
   * Fetches actions for an app.
   */
  getActionsForApp = async (selected_api: string): Promise<model.Action[]> => {
    return this.getApp(selected_api).then((app: model.App) => {
      if (!app.actions) return [];
      return app.actions.map((a: model.Action) => {
        const copy = { ...a };
        return copy;
      });
    });
  };

  //---------------------------------
  // Needs Resource
  //---------------------------------

  /**
   * Fetches needs for Write Action.
   */
  getNeedsForWriteAction = (
    action: string,
    selectedApi: string,
    params: any,
    authId?: number
  ): Promise<model.Need[]> => {
    const queryObject: { [key: string]: any } = {
      action,
      selected_api: selectedApi,
      params: JSON.stringify(params),
    };
    if (authId) {
      queryObject.auth_id = authId;
    }
    const query = qs.stringify(queryObject);
    const url = `${TRANSFER_BASE_API_V1_URL}/needs/destination/?${query}`;
    return Get(url, params);
  };

  //---------------------------------
  // Gives Resource
  //---------------------------------

  /**
   * Fetches needs for Write Action.
   */
  getGivesForAction = (
    actionKey: string,
    actionType: model.ActionType,
    selectedApi: string,
    params: any,
    authId?: number,
    sample?: any
  ): Promise<model.Give[]> => {
    const putObj: { [key: string]: any } = {
      type_of: actionType,
      selected_api: selectedApi,
      action: actionKey,
      params,
    };
    if (authId) {
      putObj.auth_id = authId;
    }

    if (sample) {
      putObj.read_result = JSON.stringify(sample);
    }
    const url = `${TRANSFER_BASE_API_V1_URL}/gives/read/`;

    return Put(url, putObj);
  };
}
