import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import './style.scss';

import React from 'react';
import { Router } from '@reach/router';
import { Provider } from 'react-redux';

// Components
import Container from './components/Container';
import Editor from './components/Editor';
import MyTasks from './components/MyTasks';
import TaskSetup from './common/components/TaskSetup';
import Mapper from './components/Mapper';
import Login from './common/Login';
import BulkZapRunner from './components/BulkZapRunner';
import Terms from './components/Terms';

// Store
import configureStore from './common/store';
import { loadState } from './common/store/local';

// Style
import 'antd/dist/antd.css';

// Utils
import { ProtectedLink, UnprotectedPath } from './utils/router';

// State
const persistedState = loadState();
const appStore = configureStore(persistedState);

// Bound action
export const App = () => {
  return (
    <Provider store={appStore}>
      <Router className='router'>
        <Container path="/oauth/complete" />
        <Terms path='/terms' />
        <Container path="/">
          <UnprotectedPath path="/login" component={Login} />
          <ProtectedLink default path="/bulk-runner" component={BulkZapRunner} />
          <ProtectedLink path="/tasks" component={MyTasks} />
          <ProtectedLink path="/tasks/:taskID" component={Editor} />
          <ProtectedLink path="/mapper" component={Mapper} />
          <ProtectedLink path="/setup" component={TaskSetup} />
        </Container>
      </Router>
    </Provider>
  );
};

export default App;
