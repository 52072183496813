import './style.scss';

import * as React from 'react';
import * as RB from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { debounce } from 'underscore';

// State
import { RootReducerState } from '../../reducers';

// Actions
import { AppActions } from '../../actions/app';

// Components
import AppIcon from '../AppIconButton';
import SearchInput from '../Inputs/Search';

// Models
import { App } from '../../models/app';

// Utils
import * as Logger from '../../../utils/logger';
import { sync } from '../../../utils/sync';

type ComponentProps = {
  iconSize?: number;
  onAppSelected: (app: App) => void;
};
type ConnectedState = ReturnType<typeof mapStateToProps>;
type ConnectedActions = ReturnType<typeof mapDispatchToProps>;
type Props = ComponentProps & ConnectedState & ConnectedActions;

const initialSate = {
  filter: '',
};
type State = typeof initialSate;

/**
 * Renders a grid of `AppIconButtons` components and a search bar.
 */
class AppSelect extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = initialSate;
  }

  /**
   * Fetches apps. Optionally with a search filter.
   */
  fetchApps = async () => {
    const { filter } = this.state;
    const [err] = await sync(this.props.fetchApps(filter) as any);
    if (err) {
      Logger.LogInfo('Failed to `refreshAppState` with err:', err);
    }
  };

  handleSearch = (search: string) => {
    const filter = search;
    this.setState({ filter }, () => this.fetchApps());
  };

  /**
   * Builds and AppIconButton.
   */
  buildAppIconButton = (app: App) => {
    const { iconSize, onAppSelected } = this.props;
    const onClick = () => onAppSelected(app);
    return <AppIcon app={app} size={iconSize} onClick={onClick} />;
  };

  /**
   * Builds our AppIconButton grid.
   */
  buildAppIconButtonGrid = () => {
    const { appsByID } = this.props;
    if (!appsByID) return;

    const { filter } = this.state;
    const apps = Object.values(appsByID);
    const filtered = apps
      .filter((app: App) => {
        const lower = app.name.toLowerCase();
        return lower.includes(filter.toLowerCase());
      })
      .slice(0, 8);

    return filtered.map((app: App) => (
      <RB.Col key={`service-col-${app.name}`} className="service-col" xs={3}>
        {this.buildAppIconButton(app)}
        <p className="app-name-label">{app.name}</p>
      </RB.Col>
    ));
  };

  render() {
    const sourceComponents = this.buildAppIconButtonGrid();
    const handleSearch = debounce(this.handleSearch, 250);
    return (
      <div className="app-grid-container">
        <SearchInput onSearch={handleSearch} />
        <RB.Container fluid>
          <RB.Row>{sourceComponents}</RB.Row>
        </RB.Container>
      </div>
    );
  }
}

const mapStateToProps = (state: RootReducerState) => ({
  appState: state.app,
  appsByID: state.app.appsByID,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...AppActions,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AppSelect);
