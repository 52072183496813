import { Need } from './need';
import { Give } from './give';

export enum ActionType {
  Read = 'read',
  ReadBulk = 'read_bulk',
  Write = 'write',
  WriteBulk = 'write_bulk',
  Search = 'search',
  SearchOrWrite = 'search_or_write',
}

interface ActionMeta {
  bulk_action: string
}

export interface Action {
  type: ActionType;
  key: string;
  name: string;
  noun: string;
  description: string;
  description_html: string;
  is_important: boolean;
  is_hidden: boolean;
  needs: Need[];
  gives: Give[];
  meta: ActionMeta;
}
