import * as Yup from 'yup';

// Components
import { InputType, Input } from '../../Inputs/types';

export interface FormState {
  url: string;
}

/**
 * Builds the input config for ZapSetupModal ConfigureApp form step.
 */
export const BuildInputs = (): Input[] => {
  return [
    {
      id: 'webhook-url-input',
      type: InputType.Text,
      key: 'url',
      placeholder: 'https://www.zapier.com',
      label: 'Webhook URL',
      required: true,
      validation: Yup.string()
        .url('Please enter a valid url.')
        .required('Please enter a url.'),
    },
  ];
};
