import './style.scss';

import React from 'react';
import * as RS from 'react-bootstrap';
import { FieldProps } from 'formik';

import { Input } from '../types';

type Props = {
  input: Input;
  fieldProps: FieldProps;
  onChange: (value: string, fieldProps: FieldProps) => void;
};

/**
 * TextInput renders a plain text input for use in Formik.
 */
const TextInput = (props: Props) => {
  const { input, fieldProps, onChange } = props;

  /**
   * Handles changes to the Text input.
   * @param event
   */
  const handleChange = (event: any) => {
    onChange(event.target.value, fieldProps);
  };

  if (!fieldProps.field.name && input.key) {
    fieldProps.field.name = input.key
  }

  if (!fieldProps.field.onChange) {
    fieldProps.field.onChange = handleChange
  }

  return (
    <RS.Form.Control
      className="transfer-text-input"
      placeholder={input.placeholder}
      {...fieldProps.field}
    />
  );
};
export default TextInput;
