import './style.scss';

import * as React from 'react';
import * as RB from 'react-bootstrap';
import * as RF from 'react-feather';
import { connect } from 'react-redux';

// Components
import AppIcon from '../../common/components/AppIconButton';
import Button, { ButtonWidth } from '../../common/components/Button';
import TaskProgressBar from '../TaskProgressBar';

// Models
import { Task } from '../../common/models/task';
import { App } from '../../common/models/app';

// Utils
import Classname from '../../common/utils/classname';

// State
import { RootReducerState } from '../../common/reducers';
import { appListSelector } from '../../common/reducers/app';

// Props
type ConnectedState = ReturnType<typeof mapStateToProps>;
type ComponentProps = {
  task: Task;
  running?: boolean;
  onClick: () => void;
  onDemoClick: () => void;
  onRunClick?: () => void;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
};
type Props = ConnectedState & ComponentProps;

// State
const initialState = {};
type State = typeof initialState;

/**
 * Renders a card that represents a Task.
 */
class TaskCard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...initialState };
  }

  handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const classname: string = (event.target as any).className;
    if (!classname) return;
    if (typeof classname !== 'string') return;
    if (classname.includes('dropdown-item')) return;
    if (classname.includes('dropdown-label')) return;
    this.props.onClick();
  };

  /**
   * Builds the icon container.
   */
  buildIconContainer = () => {
    const { task } = this.props;
    const { appsByID } = this.props.appState;

    const exportAppID = task.export_step?.app;
    const importAppID = task.import_step?.app;
    const source = appsByID[exportAppID!];
    const destination = importAppID ? appsByID[importAppID!] : undefined;
    return (
      <div className="task-icon-container d-flex align-items-center justify-content-center">
        {this.buildIconComponent(source)}
        <div className="task-row-chevron">
          <RF.ChevronRight size={20} />
        </div>
        {this.buildIconComponent(destination!, task.webhook_url)}
      </div>
    );
  };

  buildIconComponent = (app: App, webhook?: string) => {
    return (
      <div className="icon-component">
        <AppIcon app={app} size={60} padding={8} webhookURL={webhook} />
      </div>
    );
  };
  /**
   * Builds the Zap title container.
   */
  buildZapTitleContainer = () => {
    const { task } = this.props;
    const lastUpdated = new Date().toLocaleDateString();

    const className = Classname({
      'task-title-container': true,
      'd-flex ': true,
      'justify-content-between': true,
    });
    return (
      <div className={className} onClick={this.handleClick}>
        <div>
          <p className="task-title-label">
            {task.name || `New Task - ${task.id}`}
          </p>
          <p className="task-subtitle-label">{`Last Updated: ${lastUpdated}`}</p>
        </div>
        {this.buildMenuButton()}
      </div>
    );
  };

  /**
   * Builds the action button container.
   */
  buildActionButtonContainer = () => {
    return <div className="icon-container d-flex">{this.buildRunButton()}</div>;
  };

  buildRunButton = () => {
    return (
      <Button
        loading={this.props.running}
        customClass="task-row-button"
        width={ButtonWidth.Medium}
        title="Run"
        variant="outline-primary"
        icon={RF.Play}
        onClick={this.props.onRunClick}
      />
    );
  };

  buildMenuButton = () => {
    const { onEditClick, onDeleteClick } = this.props;
    return (
      <RB.Dropdown className="task-menu-dropdown">
        <RB.Dropdown.Toggle
          className="task-dropdown-toggle"
          variant="outline-secondary"
          id="dropdown-basic"
        >
          <RF.MoreHorizontal />
        </RB.Dropdown.Toggle>
        <RB.Dropdown.Menu alignRight>
          {this.buildMenuItem(RF.Menu, 'DEMO', this.props.onDemoClick)}
          <RB.Dropdown.Divider />
          {this.buildMenuItem(RF.Play, 'RUN', this.props.onRunClick)}
          <RB.Dropdown.Divider />
          {this.buildMenuItem(RF.Edit, 'EDIT', onEditClick)}
          <RB.Dropdown.Divider />
          {this.buildMenuItem(RF.XCircle, 'DELETE', onDeleteClick)}
        </RB.Dropdown.Menu>
      </RB.Dropdown>
    );
  };

  buildMenuItem = (icon: RF.Icon, title: string, onClick: any) => {
    const FeatherIcon = icon;
    return (
      <RB.Dropdown.Item className="d-flex align-items-center" onClick={onClick}>
        <FeatherIcon className="dropdown-icon" size={20} />
        <p className="dropdown-label">{title}</p>
      </RB.Dropdown.Item>
    );
  };

  render() {
    return (
      <RB.Col
        key="task-col-container"
        className="task-col"
        onClick={this.handleClick}
        md={4}
      >
        <div className="task-card-container">
          {this.buildZapTitleContainer()}
          {this.buildIconContainer()}
          <TaskProgressBar task={this.props.task} />
        </div>
      </RB.Col>
    );
  }
}

const mapStateToProps = (state: RootReducerState) => ({
  appState: state.app,
  apps: appListSelector(state),
});

export default connect(mapStateToProps, {})(TaskCard);
