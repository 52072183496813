import './style.scss';

import * as React from 'react';
import * as RB from 'react-bootstrap';
import { navigate, RouterProps, RouteComponentProps } from '@reach/router';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

// State
import { RootReducerState } from '../../common/reducers';

// Actions
import * as actions from '../../common/actions';

// Components
import Header, { View } from './Header';
import ZapRow from '../TaskRow';
import ZapCard from '../TaskCard';
import TaskSetupModal from '../../common/components/TaskSetupModal';
import { FormState } from '../../common/components/BaseSetupForm/inputs';
import Spinner from '../../common/components/Spinner';
import {
  FormType,
  FormHelper,
} from '../../common/components/BaseSetupForm/helper';
import TaskStateHelper from '../../common/components/BaseSetupForm/state';

// Models
import * as model from '../../common/models';
import { Task } from '../../common/models/task';

// Utils
import { sync } from '../../utils/sync';
import * as Logger from '../../utils/logger';
import Analytics, { Action } from '../../common/utils/analytics';
import { CURRENT_PATH_KEY } from '../../common/utils/constants';

// Store
import * as store from '../../common/store/local';

// Props
type ConnectedState = ReturnType<typeof mapStateToProps>;
type ConnectedActions = ReturnType<typeof mapDispatchToProps>;
type Props = ConnectedState & ConnectedActions & RouterProps & RouteComponentProps<any>;

// State
const initialState = {
  /**
   * The current view to display.
   */
  view: View.Grid,

  /**
   * The selected Task, if any.
   */
  selectedTask: (null as unknown) as model.Task,

  /**
   * Boolean that indicates whether or not the setup modal is shown.
   */
  showSetupModal: false,

  /**
   * Refresh timer for updating Task information. This is used to refresh
   * Task loading data for progress indicator display.
   */
  taskRefreshTimer: null as NodeJS.Timeout | null,
};
type State = typeof initialState;

/**
 * Renders a list of Tasks for the currently authenticated user.
 */
class MyTasks extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...initialState };
    this.props.showNav();
  }

  async componentDidMount() {
    Analytics.trackPage(this.props.location);

    // If not, we attempt to refresh our session and App State.
    await this.refreshAppState();


    // Refresh our Zaps anytime we load the page.
    this.props.fetchTasks();
  }

  componentDidUpdate() {
    this.checkForTaskRefreshNeed();
  }

  //---------------------------------
  // Application State Setup
  //---------------------------------

  /**
   * Helper function to refresh app state.
   */
  refreshAppState = async () => {

    const { user } = this.props;
    if (user) {
      await this.fetchState();
    } else {
      await this.fetchApps();
    }

    this.checkForTaskRefreshNeed();
    await this.setupTaskState();
  };

  /**
 * Fetches all tasks.
 */
  fetchState = async () => {
    const [err] = await sync(this.props.fetchState() as any);
    if (err) {
      Logger.LogInfo('Failed to load Tasks with err:', err);
    }
  };

  /**
   * Fetches all tasks.
   */
  fetchTasks = async () => {
    const [err] = await sync(this.props.fetchTasks() as any);
    if (err) {
      Logger.LogInfo('Failed to load tasks with err:', err);
    }
  };

  /**
   * Fetches all auths.
   */
  fetchAuths = async () => {
    const [err] = await sync(this.props.fetchAuths() as any);
    if (err) {
      Logger.LogInfo('Failed to load auths with err:', err);
    }
  };

  /**
   * Fetches all apps.
   */
  fetchApps = async () => {
    const [err] = await sync(this.props.fetchApps() as any);
    if (err) {
      Logger.LogInfo('Failed to fetch apps with err:', err);
    }
  };

  /**
   * Fetch private apps.
   */
  fetchPrivateApps = async () => {
    const [privateAppErr] = await sync(this.props.fetchPrivateApps() as any);
    if (privateAppErr) {
      Logger.LogInfo('Failed to fetch private apps with err:', privateAppErr);
    }
  };

  /**
   * Sets up the Task state for the component.
   *
   * If a `taskID` query param is passed, we fetch that Zap and updated state.
   * Otherwise, we look for a `task` query param and parse state from there.
   */
  setupTaskState = async () => {
    const taskID = this.getQueryParam('taskID');
    const taskData = this.getQueryParam('task');
    if (taskID) {
      await this.setupZapStateWithID(Number(taskID));
    } else if (taskData) {
      await this.setupZapStateWithParams(taskData);
    }
  };

  /**
  * Setup state if we have a `task` query param.
  */
  setupZapStateWithParams = async (taskData: string) => {
    const parsed = JSON.parse(taskData);
    await this.setCurrentZap(parsed);
  };

  /**
   * Updates the current Task in state and ensures we have Task info loaded.
   */
  setCurrentZap = async (task: Task) => {
    this.props.setCurrentTask(task);

    const { user } = this.props;
    if (!user) return;

    // Ensure our zap state is loaded.
    const helper = new TaskStateHelper({ ...this.props });
    await helper.ensureTaskState(task);
  };

  //------------------------------------
  // Helpers
  //------------------------------------

  /**
   * Parses a query param for the given key.
   */
  getQueryParam = (key: string) => {
    let location = this.getLocation();
    if (!location) {
      location = this.props.location;
    }
    const params = new URLSearchParams(location!.search);
    return params.get(key);
  };

  /**
   * Saves a current location to disk.
   */
  saveLocation = (location: any) => {
    if (location.pathname.includes('oauth')) return;
    store.saveStateForKey(location, CURRENT_PATH_KEY);
  };

  /**
   * Fetchs current location data from disk.
   */
  getLocation = (): any => {
    return store.loadStateForKey(CURRENT_PATH_KEY);
  };

  /**
   * Clears the current location data on disk
   */
  clearLocation = (): any => {
    return store.clearStateForKey(CURRENT_PATH_KEY);
  };


  /**
   * Setup state if we have a `taskID` query param.
   */
  setupZapStateWithID = async (taskID: number) => {
    // Check if we have the new task in redux, fetch tasks if not.
    const { tasksByID } = this.props;
    if (!tasksByID[taskID]) await this.fetchTasks();

    // Grab the zap out of redux...
    const fetched = tasksByID[taskID];
    this.setCurrentZap(fetched);
  };


  /**
   * Continuously refreshes Task if we have one or more running.
   */
  checkForTaskRefreshNeed = () => {
    const { tasksByID } = this.props.taskState;
    const tasks = Object.values(tasksByID) as Task[];
    const taskRunning = tasks.find((task: Task) => task.running);

    // Setup a timer if none already and we have running tasks
    if (taskRunning && !this.state.taskRefreshTimer) {
      const taskRefreshTimer = setInterval(() => this.fetchTasks(), 1000);
      return this.setState({ taskRefreshTimer });
    }

    // Check if we have a timer and no need
    if (!taskRunning && this.state.taskRefreshTimer) {
      clearInterval(this.state.taskRefreshTimer);
      this.setState({ taskRefreshTimer: null });
    }
  };

  //------------------------------------
  // Network Requests
  //------------------------------------

  /**
   * Creates a new Zap and redirects to the zap page.
   */
  createNewTask = async (state: FormState) => {
    const helper = new FormHelper(state);
    const export_step = helper.getExportStep();
    const task = { ...export_step };
    const [err, created] = await sync(this.props.createTask(task) as any);
    this.setState({ showSetupModal: false });
    if (err) {
      this.props.flashError('Oops something went wrong. Please try again.');
      Logger.LogError('Failed to create Task with error.', err);
      return;
    }
    this.props.flashSuccess('New Zap Created!');
    const newTask = created as model.Task;
    navigate(`/tasks/${newTask.id}`);
  };

  /**
   * Updates an existing Task.
   */
  updateTask = async (state: FormState) => {
    const { selectedTask } = this.state;

    const helper = new FormHelper(state);
    const export_step = helper.getExportStep();
    const updateInfo = { ...selectedTask, ...export_step };
    const [err] = await sync(this.props.updateTask(updateInfo) as any);
    this.setState({ showSetupModal: false });
    if (err) {
      this.props.flashError('Oops something went wrong. Please try again.');
      Logger.LogInfo('Failed to update Zap with err:', err);
    }
    this.props.flashSuccess('Zap Updated!');
  };

  /**
   * Deletes a Task.
   */
  deleteTask = async (taskID: string) => {
    const [err] = await sync(this.props.deleteTask(taskID) as any);
    if (err) {
      this.props.flashError('Oops something went wrong. Please try again.');
      Logger.LogInfo('Failed to delete Zap with err:', err);
      return;
    }
    this.props.flashSuccess('Your Zap was deleted.');
  };

  /**
   * Runs a Task.
   */
  runTask = async (taskID: string) => {
    const [err] = await sync(this.props.runTask(taskID) as any);
    if (err) {
      this.props.flashError('Oops something went wrong. Please try again.');
      Logger.LogInfo('Failed to run Zap with err:', err);
      return;
    }
    this.props.fetchTasks();
    this.props.flashSuccess('Success! We are running your Zap.');
  };

  /**
   * Fetches an app.
   */
  fetchApp = async (appID: string) => {
    const [err] = await sync(this.props.fetchApp(appID) as any);
    if (err) {
      Logger.LogInfo('Failed to fetch App with err:', err);
      return;
    }
  };

  /**
   * Ensures we have an app record in state.
   */
  ensureApp = (appID?: string) => {
    if (!appID) return;

    const { appsByID, privateAppsByID } = this.props.appState;
    const app = appsByID[appID];
    const privateApp = privateAppsByID[appID];

    if (app || privateApp) return;
    this.fetchApp(appID);
  };

  //------------------------------------
  // Button Handlers
  //------------------------------------

  /**
   * Handles clicks to view a Zap.
   */
  handleViewZapClick = async (taskID: string) => {
    Analytics.trackAction(Action.ViewZapClick, { taskID });
    navigate(`/tasks/${taskID}`);
  };

  /**
   * Displays the Import Zap modal.
   */
  handleNewZapClick = () => {
    Analytics.trackAction(Action.NewZapClick);
    this.setState({ selectedTask: null as any });
    this.handleToggle();
  };

  /**
   * Handles clicks to setup a task.
   */
  handleDemoZapClick = async (task: model.Task) => {
    const export_step = this.getDemoStepData(task?.export_step);
    const import_step = this.getDemoStepData(task?.import_step);
    const template = { export_step, import_step };
    window.location = `/setup?step=0&task=${JSON.stringify(template)}` as any;
  };

  getDemoStepData = (step?: model.Step) => {
    if (!step) return;

    const app = step.app;
    const action = step.action;
    const action_type = step.action_type;
    return { app, action, action_type };
  };

  /**
   * Handles clicks to edit a Zap.
   */
  handleEditZapClick = async (task: model.Task) => {
    Analytics.trackAction(Action.EditZapClick, { taskId: task.id });
    this.setState({ selectedTask: task });
    this.handleToggle();
  };

  /**
   * Handles clicks to delete a Zap.
   */
  handleDeleteZapClick = async (taskID: string) => {
    Analytics.trackAction(Action.DeleteZapClick, { taskID });
    await this.deleteTask(taskID);
  };

  /**
   * Handles clicks to run a task.
   */
  handleRunZapClick = async (taskID: string) => {
    Analytics.trackAction(Action.RunZapClick, { taskID });
    await this.runTask(taskID);
  };

  handleViewClick = (view: View) => {
    this.setState({ view });
  };

  //------------------------------------
  // Modal Handlers
  //------------------------------------

  /**
   * Handles Toggling the Import modal.
   */
  handleToggle = () => {
    const showSetupModal = !this.state.showSetupModal;
    this.setState({ showSetupModal });
  };

  /**
   * Handles clicks to the modal submit button.
   */
  handleSubmit = (formState: FormState) => {
    Analytics.trackAction(Action.ImportSubmitClick);
    if (this.state.selectedTask) {
      this.updateTask(formState);
    } else {
      this.createNewTask(formState);
    }
  };

  //------------------------------------
  // UI Builders
  //------------------------------------

  /**
   * Builds our import modal.
   */
  buildTaskSetupModal = () => {
    const { selectedTask } = this.state;

    return (
      <TaskSetupModal
        type={FormType.Read}
        task={selectedTask}
        show={this.state.showSetupModal}
        onSubmit={this.handleSubmit}
        onToggle={this.handleToggle}
      />
    );
  };

  /**
   * Builds the row body for the component.
   */
  buildListComponent = () => {
    const { appsByID } = this.props.appState;
    if (!appsByID) return <Spinner />;

    const { tasksByID } = this.props.taskState;
    const tasks = Object.values(tasksByID);
    const tasksRows = tasks.map((task: model.Task) => this.buildZapRow(task));
    return <RB.Col>{tasksRows}</RB.Col>;
  };

  /**
   * Builds the card body for the component.
   */
  buildGridComponent = () => {
    const { appsByID } = this.props.appState;
    if (!appsByID) return <Spinner />;

    const { tasksByID } = this.props.taskState;
    const tasks = Object.values(tasksByID);
    const taskCards = tasks.map((task: model.Task) => this.buildZapCard(task));
    return <RB.Row>{taskCards}</RB.Row>;
  };

  buildZapRow = (task: model.Task) => {
    // Ensure we have Zap apps in state.
    // Need this to show App Icons for Zaps.
    this.ensureApp(task.export_step?.app);
    this.ensureApp(task.import_step?.app);

    const onClick = () => this.handleViewZapClick(`${task.id}`);
    const onEditClick = () => this.handleEditZapClick(task);
    const onRunClick = () => this.handleRunZapClick(`${task.id}`);
    const onDeleteClick = () => this.handleDeleteZapClick(`${task.id}`);
    return (
      <ZapRow
        key={task.id}
        running={task.running}
        onClick={onClick}
        onEditClick={onEditClick}
        onRunClick={onRunClick}
        onDeleteClick={onDeleteClick}
        task={task}
      />
    );
  };

  buildZapCard = (task: model.Task) => {
    // Ensure we have Zap apps in state.
    // Need this to show App Icons for Zaps.
    this.ensureApp(task.export_step?.app);
    this.ensureApp(task.import_step?.app);

    const onClick = () => this.handleViewZapClick(`${task.id}`);
    const onDemoClick = () => this.handleDemoZapClick(task);
    const onEditClick = () => this.handleEditZapClick(task);
    const onRunClick = () => this.handleRunZapClick(`${task.id}`);
    const onDeleteClick = () => this.handleDeleteZapClick(`${task.id}`);
    return (
      <ZapCard
        key={task.id}
        running={task.running}
        onClick={onClick}
        onDemoClick={onDemoClick}
        onEditClick={onEditClick}
        onRunClick={onRunClick}
        onDeleteClick={onDeleteClick}
        task={task}
      />
    );
  };

  buildBody = () => {
    const { view } = this.state;
    if (view === View.List) {
      return this.buildListComponent();
    } else {
      return this.buildGridComponent();
    }
  };

  render() {
    const modal = this.state.showSetupModal ? this.buildTaskSetupModal() : null;
    return (
      <div className="my-zaps-component">
        <RB.Container className="my-zaps-container">
          <Header
            view={this.state.view}
            onNewZapClick={this.handleNewZapClick}
            onViewButtonClick={this.handleViewClick}
          />
          {this.buildBody()}
          {modal}
        </RB.Container>
      </div>
    );
  }
}

const mapStateToProps = (state: RootReducerState) => ({
  appsByID: state.app.appsByID,
  appState: state.app,
  authsByID: state.auth.authsByID,
  actionState: state.action,
  taskState: state.task,
  user: state.user.me
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...actions.AppActions,
      ...actions.TaskActions,
      ...actions.AuthActions,
      ...actions.ActionActions,
      ...actions.GiveActions,
      ...actions.FlashActions,
      ...actions.NavActions,
      ...actions.SessionActions,
      ...actions.UserActions,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MyTasks);
