import './style.scss';

// Render Prop
import React from 'react';

// Components
import AppIconButton from '../../AppIconButton';

// Models
import * as model from '../../../models';

// Utils
import classname from '../../../utils/classname';

type Props = {
  type: model.ActionType;
  app: model.App;
  action: model.Action;
};

const AppHeader = (props: Props) => {
  const { app, action } = props;
  const defaultClass = {
    'app-header-container': true,
    'd-flex': true,
    'align-items-center': true,
  };

  const headerForImport = () => {
    const headerClass = { ...defaultClass };
    const className = classname(headerClass);
    return (
      <div className={className}>
        <AppIconButton app={app} />
        <div className="left-align">
          <h5>{app.name}</h5>
          <p>{action ? action.name : ''}</p>
        </div>
      </div>
    );
  };

  const headerForExport = () => {
    const headerClass = { ...defaultClass, 'justify-content-end': true };
    const className = classname(headerClass);
    return (
      <div className={className}>
        <div className="right-align">
          <h5>{app.name}</h5>
          <p>{action ? action.name : ''}</p>
        </div>
        <AppIconButton app={app} />
      </div>
    );
  };

  const getHeader = () => {
    if (!app) return <></>;
    if (props.type === model.ActionType.Read) {
      return headerForImport();
    }
    return headerForExport();
  };
  return getHeader();
};

export default AppHeader;
