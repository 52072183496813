import * as React from 'react';

// Utils
import Classname from '../../../utils/classname';

// Components
import Button, { ButtonWidth } from '../../Button';

export type TaskSetupButton = {
  title: string;
  variant: string;
  onClick: () => void;
  loading?: boolean;
  width: ButtonWidth;
};

type Props = {
  rightButton: TaskSetupButton;
  leftButton: TaskSetupButton;
  secondaryButton?: TaskSetupButton;
};

// State
const initialState = {};
type State = typeof initialState;

/**
 * Renders a confirmation component.
 */
class TaskSetupButtonContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...initialState };
  }

  //------------------------------------
  // UI Builders
  //------------------------------------

  buildButton = (button?: TaskSetupButton) => {
    if (!button) return;
    return (
      <Button
        title={button.title}
        variant={button.variant}
        onClick={button.onClick}
        width={button.width}
        loading={button.loading}
      />
    );
  };

  render() {
    const { rightButton, leftButton, secondaryButton } = this.props;
    const className = Classname({
      'task-setup-button-container': true,
      'd-flex': true,
      'justify-content-between': true,
    });

    return (
      <div className={className}>
        <div>{this.buildButton(leftButton)}</div>
        <div className="d-flex">
          {this.buildButton(secondaryButton)}
          {this.buildButton(rightButton)}
        </div>
      </div>
    );
  }
}

export default TaskSetupButtonContainer;
