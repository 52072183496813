import FormData from 'form-data';
import {parse as ParseCookie} from 'cookie';
import { addBreadcrumb } from '@sentry/browser';

// Define Network Event Constants
export const UNAUTHORIZED_ACCESS_NOTIFICATION =
  'UNAUTHORIZED_ACCESS_NOTIFICATION';

// eslint-disable-line
export const successful = (status: number) => status >= 200 && status < 300;

export const Get = (url: string, opts: RequestInit = {}): Promise<any> => {
  const init = jsonInitOptions(opts);
  return fetch(url, init)
    .then(responseHandler)
    .catch(errorHandler);
};

/**
 * Post the body to the path. This is NOT a form post
 * @param url
 * @param opts
 */
export const Post = (url: string, body: object = {}): Promise<any> => {
  const opts: RequestInit = { method: 'POST' };
  const init = jsonInitOptions(opts);
  init.body = JSON.stringify(body);
  return fetch(url, init)
    .then(responseHandler)
    .catch(errorHandler);
};

/**
 * Put the body to the path. This is NOT a form post
 * @param url
 * @param opts
 */
export const Put = (url: string, body: object): Promise<any> => {
  const opts: RequestInit = { method: 'PUT' };
  const init = jsonInitOptions(opts);
  init.body = JSON.stringify(body);
  return fetch(url, init)
    .then(responseHandler)
    .catch(errorHandler);
};

/**
 * Post JSON in the body to the path. This is NOT a form post
 * @param url
 * @param opts
 */
export const PostForm = (url: string, form: FormData): Promise<any> => {
  const opts: RequestInit = {
    headers: form.getHeaders(),
    method: 'POST',
    body: form.getBuffer(),
  };
  const init = jsonInitOptions(opts);
  return fetch(url, init).then(responseHandler);
};

/**
 * Delete to the path.
 * @param url
 */
export const Delete = (url: string): Promise<any> => {
  const opts: RequestInit = { method: 'DELETE' };
  const init = jsonInitOptions(opts);
  return fetch(url, init)
    .then(responseHandler)
    .catch(errorHandler);
};

/**
 * Init Helpers
 */

// JSON Init Options
const jsonInitOptions = (overrides: RequestInit = {}): RequestInit => {
  const init: RequestInit = {
    ...overrides,
    headers: {
      ...overrides.headers,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };
  return baseInitOptions(init);
};

// Base Init Options
const baseInitOptions = (overrides: RequestInit = {}): RequestInit => {
  // TH - based on testing, I'm seeing some weird stuff where incognito in
  // chrome wont parse the cookie from django properly. This manual replace
  // should be happening above w the cookie parse, but it isn't on initial
  // account creation (on incognito)
  const parsedCookie = ParseCookie(document.cookie)
  const csrfToken = parsedCookie['transfer-csrf']

  let headers = overrides.headers
  if (csrfToken) {
    headers = {
      ...headers,
      'X-CSRFToken': csrfToken
    }
  }

  return {
    method: overrides.method || 'GET',
    headers: {
      ...headers
    },
    mode: 'cors',
    credentials: 'include',
  };
};

/**
 * Response Helpers
 */

/**
 * Base handler
 * @param response
 */
const responseHandler = async (response: Response): Promise<Object> => {
  addBreadcrumb({ data: { response }, message: 'Fetch Response Handler' });
  if (successful(response.status)) {
    if (response.headers.get('content-type') === 'application/json') {
      return jsonResponseHandler(response);
    }
    return response.text();
  }

  // Response is not 'successful. Let's check for unauthorized

  if (response.status === 401) {
    throw new Error(UNAUTHORIZED_ACCESS_NOTIFICATION);
  }
  throw response;
};

/**
 * Erorr handler
 * @param response
 */
const errorHandler = async (response: Response | Error): Promise<Object> => {
  addBreadcrumb({ data: { response }, message: 'Fetch Error Handler' });
  if (response instanceof Error) {
    throw response;
  }
  if (
    response.headers.get('content-type') === 'application/json' &&
    !response.bodyUsed
  ) {
    throw await jsonResponseHandler(response);
  }
  throw await response;
};

/**
 * Response handler for JSON responses
 * @param response
 */
const jsonResponseHandler = (response: Response): Promise<Object> => {
  return response.json().catch((err: Error) => {
    if (response.status === 204) return null;
    if (successful(response.status)) throw err;
    throw response;
  });
};
