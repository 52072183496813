import './style.scss';

import * as React from 'react';
import * as RB from 'react-bootstrap';
import { Icon } from 'react-feather';

// Components
import Spinner from '../Spinner';

// Utils
import classname from '../../utils/classname';

export enum ButtonWidth {
  Small = 0,
  Medium,
  Large,
  XLarge,
  FullWidth,
}

type Props = {
  title?: string | undefined;
  variant?: string;
  icon?: Icon;
  loading?: boolean;
  children?: any;
  iconSize?: number;
  disabled?: boolean;
  width?: ButtonWidth;
  height?: number;
  customClass?: string;
  style?: any;
  dynamicSize?: boolean;
  spinnerAnimation?: string;
  onClick?: () => void;
};

/**
 * Renders a custom button component.
 */
class Button extends React.Component<Props, any> {
  /**
   * Builds an icon if needed.
   */
  buildIcon = () => {
    if (!this.props.icon) {
      return;
    }
    const FeatherIcon = this.props.icon!;
    const size = this.props.iconSize ? this.props.iconSize : 16;
    const className = classname({
      'button-icon': true,
      'right-margin': Boolean(this.props.title),
    });
    return <FeatherIcon className={className} size={size} />;
  };

  /**
   * Gets the appropriate class for the supplied width.
   */
  getWidthClass = () => {
    if (this.props.dynamicSize) return '';

    switch (this.props.width) {
      case ButtonWidth.Small:
        return 'small-width';
      case ButtonWidth.Medium:
        return 'medium-width';
      case ButtonWidth.Large:
        return 'large-width';
      case ButtonWidth.XLarge:
        return 'x-large-width';
      case ButtonWidth.FullWidth:
        return 'full-width';
      default:
        return '';
    }
  };

  buildButtonContent = () => {
    if (this.props.loading) {
      const buttonProps = { animation: this.props.spinnerAnimation }
      return <Spinner {...buttonProps} />;
    }
    let { title } = this.props;
    const { children } = this.props;
    if (typeof title === 'string') title = title?.toUpperCase();
    return (
      <>
        {this.buildIcon()}
        {title || null}
        {children}
      </>
    );
  };

  render() {
    const { variant } = this.props;
    const widthClass = this.getWidthClass();
    const customClass = this.props.customClass ? this.props.customClass : '';
    const className = classname({
      'transfer-button': true,
      [widthClass]: true,
      [customClass]: Boolean(this.props.customClass),
      'd-flex align-items-center justify-content-center': true,
    });

    return (
      <RB.Button
        className={className}
        variant={variant as any}
        onClick={this.props.onClick}
        style={this.props.style}
        disabled={this.props.disabled}
      >
        {this.buildButtonContent()}
      </RB.Button>
    );
  }
}

export default Button;
