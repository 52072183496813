// External Dependencies
import { Dispatch, Action } from 'redux';

// Actions
export enum TypeKeys {
  SHOW_NAV = 'SHOW_NAV',
  HIDE_NAV = 'HIDE_NAV',
}

export type Actions = NavAction;

// -----------------------------------------------------------------------------
// Nav Actions
// -----------------------------------------------------------------------------

export interface NavAction extends Action {
  type: TypeKeys;
}

const showNav = () => async (dispatch: Dispatch<NavAction>) => {
  dispatch({ type: TypeKeys.SHOW_NAV });
};

const hideNav = () => async (dispatch: Dispatch<NavAction>) => {
  dispatch({ type: TypeKeys.HIDE_NAV });
};

export const NavActions = {
  showNav,
  hideNav,
};
