// Components
import { InputType, Input } from '../Inputs/types';

// Models
import * as model from '../../models';

export interface FormState {
  app: model.App;
  auth: model.Auth;
  action: model.Action;
  needs: { [key: string]: any };
  mappings: { [key: string]: any };
}

/**
 * Builds the input config for column type form.
 */
export const BuildInputs = (needs?: model.Need[]): Input[] => {
  const typeInput = {
    key: 'action',
    label: 'Select Action',
    type: InputType.ActionSelect,
    required: true,
  };
  if (!needs) return [typeInput];
  const needInputs = BuildNeedInputs(needs);
  return [typeInput, ...needInputs];
};

export const BuildNeedInputs = (needs: model.Need[]) => {
  return needs.map((need: model.Need) => {
    const needInput = {
      key: `needs.${need.key}`,
      label: need.label ? need.label : need.key,
      type: InputType.NeedSelect,
      required: need.required,
    };
    return needInput;
  });
};
