import * as Sentry from '@sentry/browser';
import { log as FullStoryLog } from 'react-fullstory';
import { isString, isObject, isError } from 'underscore';

// Actions
import {
  Actions as ErrorActions,
  TypeKeys as ErrorTypeKeys,
} from '../actions/error';

const defaultState = {
  error: null as null | Error,
  zapierFormattedError: false,
};

export type ErrorState = typeof defaultState;

function reducer(state = defaultState, action: ErrorActions) {
  switch (action.type) {
    case ErrorTypeKeys.SET_ERROR: {
      logError(action.error);
      let err = action.error;
      let isZapierFormatted = false;
      if (isObject(action.error) && isString((action.error as any).detail)) {
        isZapierFormatted = true;
        err = new Error((action.error as any).detail);
      } else if (isObject(action.error) && (action.error as any).error) {
        err = new Error((action.error as any).error);
      }

      return { ...state, error: err, zapierFormattedError: isZapierFormatted };
    }

    case ErrorTypeKeys.CLEAR_ERROR: {
      return { ...state, error: null };
    }

    default:
      return state;
  }
}

export const logError = (error: any) => {
  if (isError(error)) {
    Sentry.captureException(error);
    FullStoryLog('warn', error.message);
    return;
  } if (isObject(error)) {
    Sentry.captureException(error);
    FullStoryLog('warn', error);
    return;
  }
  Sentry.captureException(error);
  FullStoryLog('warn', error);
};

export default reducer;
