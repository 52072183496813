// Models
import * as model from '../../models';

// Types
import { ZapRecord } from '../Inputs/types';

// State
import { FormState } from './inputs';

export enum FormType {
  Read = 'read',
  Write = 'write',
}

/**
 * FormHelper is responsible for translating FormState into Zap state.
 */
export class FormHelper {
  public app: model.App;

  public action: model.Action;

  public auth: model.Auth;

  public type: model.ActionType;

  public record: ZapRecord;

  public needs: { [key: string]: any };

  public mapping: { [key: string]: any };

  public search: { [key: string]: any };

  constructor(data: FormState) {
    Object.assign(this, data);
  }

  public getExportStep = (): model.Task => {
    const export_step = this.getStep();
    const mappings = this.mapping;
    return { export_step, mappings };
  };

  public getImportStep = (): model.Task => {
    const import_step = this.getStep();
    const mappings = this.mapping;
    return { import_step, mappings };
  };

  private getStep = () => {
    const app = this.app ? this.app.currentImplementationId : '';
    const action = this.action ? this.action.key : '';
    const action_type = this.action ? this.action.type : undefined;
    const auth = this.auth ? this.auth.id : 0;
    const needs = this.needs ? this.needs : {};
    return {
      app,
      action,
      action_type,
      auth,
      needs,
    };
  };
}
