// From await-to-js https://github.com/scopsy/await-to-js/blob/master/src/await-to-js.ts
/**
 * @param { Promise } promise
 * @param { Object= } errorExt - Additional Information you can pass to the err object
 * @return { Promise }
 */
export const sync = <T, U = Error | any>(
  promise: Promise<T>,
  errorExt?: object
): Promise<[U | null, T | undefined]> => {
  return promise
    .then<[null, T]>((data: T) => [null, data])
    .catch<[U, undefined]>((err: U) => {
      if (errorExt) {
        Object.assign(err, errorExt);
      }
      return [err, undefined];
    });
};
