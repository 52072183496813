// External Dependencies
import { Dispatch, Action } from 'redux';

// Models
import * as model from '../models';

// Service
import Transfer from '../services/transfer';

const service = new Transfer();

// Actions
export enum TypeKeys {
  FETCHED_NEEDS_FOR_ACTION = 'FETCHED_NEEDS_FOR_ACTION',
}

export type Actions = FetchedNeedsAction;

// -----------------------------------------------------------------------------
// Fetch Needs
// -----------------------------------------------------------------------------

export interface FetchedNeedsAction extends Action {
  type: TypeKeys;
  needs: model.Need[];
  action: model.Action
}

const fetchNeedsForWriteAction = (
  action: model.Action,
  selectedAPI: string | undefined,
  params: { [key: string]: any },
  auth?: model.Auth,
) => async (dispatch: Dispatch<FetchedNeedsAction>) => {
  const actionKey = action.key;
  const appID = selectedAPI!;
  const needs = await service.getNeedsForWriteAction(
    actionKey,
    appID,
    params,
    auth?.id,
  );

  // Update the needs for our action.
  const type = TypeKeys.FETCHED_NEEDS_FOR_ACTION;
  dispatch({ type, needs, action });
};

export const NeedActions = {
  fetchNeedsForWriteAction,
};
