import { indexBy } from 'underscore';

// Actions
import { Actions, TypeKeys } from '../actions/task';

// Models
import * as models from '../models';

export type TaskState = typeof defaultState;

const defaultState = {
  fetchingTaskData: false,
  tasksByID: {} as { [key: number]: models.Task },
  taskRecords: {} as { [key: number]: any[] },
  currentTask: (undefined as unknown) as models.Task,
};

function task(state = defaultState, action: Actions) {
  switch (action.type) {
    case TypeKeys.FETCHED_TASKS: {
      const tasksByID = indexBy(action.tasks, 'id');
      return { ...state, tasksByID };
    }
    case TypeKeys.CREATED_TASK:
    case TypeKeys.UPDATED_TASK: {
      const fetched = action.task;
      if (!fetched.id) return { ...state };
      const updatedState = { ...state };
      updatedState.tasksByID[fetched.id] = fetched;
      return updatedState;
    }

    case TypeKeys.FETCHED_RECORDS_FOR_TASK: {
      const fetched = action.task;
      if (!fetched.id) return { ...state };
      const { records } = action;

      const updatedState = { ...state };
      updatedState.taskRecords[fetched.id] = records;
      return updatedState;
    }

    case TypeKeys.DELETED_TASK: {
      const taskID = Number(action.taskID);
      const updatedState = { ...state };
      delete updatedState.tasksByID[taskID];
      return updatedState;
    }

    case TypeKeys.FETCHING_DATA_FOR_TASK: {
      const fetchingTaskData = true;
      return { ...state, fetchingTaskData };
    }
    case TypeKeys.FETCHED_DATA_FOR_TASK: {
      const fetchingTaskData = false;
      return { ...state, fetchingTaskData };
    }
    case TypeKeys.SET_CURRENT_TASK: {
      state.currentTask = action.task;
      return { ...state };
    }
    default:
      return state;
  }
}

export default task;
