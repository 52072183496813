import './style.scss';

import * as React from 'react';
import * as RB from 'react-bootstrap';
import { PlusCircle, Menu, Grid } from 'react-feather';

// Components
import Button, { ButtonWidth } from '../../../common/components/Button';

export enum View {
  List = 0,
  Grid,
}

type Props = {
  view: View;
  onNewZapClick: () => void;
  onViewButtonClick: (view: View) => void;
};

export const Header = (props: Props) => {
  const buildNewTaskButton = () => {
    return (
      <Button
        icon={PlusCircle}
        width={ButtonWidth.Large}
        title="New Task"
        onClick={props.onNewZapClick}
      />
    );
  };

  const buildViewButtonGroup = () => {
    const listClick = () => props.onViewButtonClick(View.List);
    const gridClick = () => props.onViewButtonClick(View.Grid);
    const listClass = props.view === View.List ? 'active' : '';
    const gridClass = props.view === View.Grid ? 'active' : '';
    return (
      <RB.ButtonGroup className="header-button-group">
        <Button
          customClass={listClass}
          icon={Menu}
          variant="outline-primary"
          onClick={listClick}
        />
        <Button
          customClass={gridClass}
          icon={Grid}
          variant="outline-primary"
          onClick={gridClick}
        />
      </RB.ButtonGroup>
    );
  };

  const buildButtonContainer = () => {
    return (
      <div className="d-flex">
        {buildViewButtonGroup()}
        {buildNewTaskButton()}
      </div>
    );
  };
  return (
    <RB.Row className="header-row">
      <RB.Col className="header-col d-flex justify-content-between align-items-center">
        <h1>Tasks</h1>
        {buildButtonContainer()}
      </RB.Col>
    </RB.Row>
  );
};

export default Header;
