/**
 * Builds a className string given an object.
 * @param components The object of className components.
 */
const classname = (components: { [key: string]: boolean }) => {
  const keys = Object.keys(components);
  const includedComponents = keys.filter((key: string) => {
    return components[key];
  });
  return includedComponents.join(' ');
};

export default classname;
