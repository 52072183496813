import './style.scss';

import * as React from 'react';
import { navigate, RouterProps, RouteComponentProps } from '@reach/router';
import { connect } from 'react-redux';

// State
import { RootReducerState } from '../../common/reducers';
import { ActionForKeyAndType } from '../../common/reducers/action';

// Actions
import * as actions from '../../common/actions';

// Common Components
import TaskSetupModal, {
  FormStep,
} from '../../common/components/TaskSetupModal';
import {
  FormType,
  FormHelper,
} from '../../common/components/BaseSetupForm/helper';
import ExportOptionModal, {
  ExportOption,
} from '../../common/components/Modals/ExportOptionModal';
import WebhookModal from '../../common/components/Modals/WebhookModal';
import { FormState } from '../../common/components/BaseSetupForm/inputs';
import Spinner from '../../common/components/Spinner';
import EmptyComponent from '../../components/Shared/EmptyComponent';

// Components
import BlankEditor from '../BlankEditor';
import Table from '../Table';
import Header from './Header';
import TaskProgressBar from '../TaskProgressBar';

// Models
import { App } from '../../common/models/app';
import { Task } from '../../common/models/task';
import { ActionType } from '../../common/models/action';

// Utils
import { sync } from '../../utils/sync';
import * as Logger from '../../utils/logger';
import Analytics, { Action } from '../../common/utils/analytics';

// Service
import { RecordType } from '../../common/services/transfer';

import * as constants from './constants';

// Props
type ConnectedState = ReturnType<typeof mapStateToProps>;
type ConnectedActions = typeof mapDispatchToProps;
type ComponentProps = { taskID: string }; // Provided by Reach Router.
type Props =
  ComponentProps &
  ConnectedState &
  ConnectedActions &
  RouterProps &
  RouteComponentProps<any>;

enum ModalType {
  Export = 0,
  Import,
  Webhook,
  ExportOptions,
}

const initialState = {
  loadingRecords: true,
  loadingGives: true,
  error: null as any,
  apps: ([] as unknown) as App[],
  selectedApp: (null as unknown) as App,
  currentModal: (null as unknown) as ModalType,
  progress: 0,
  webhookURL: '',
  formStep: (null as unknown) as FormStep,
  task: (null as unknown) as Task,
};
type State = typeof initialState;

/**
 * Renders the Bulk Zap editor.
 *
 * The editor component allows users to view data from their selected app in a tabular format.
 * Users can then select rows and specify the data they would like exported.
 */
class Editor extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { taskID } = this.props;
    const { tasksByID } = this.props.taskState;

    const task = tasksByID[Number(taskID)];
    this.state = { ...initialState, task };
  }

  componentDidMount() {
    Analytics.trackPage(this.props.location);
    this.props.clearFlash();
    this.setupEditor();
  }

  setupEditor = async () => {
    const { task } = this.state;
    if (!task) return; // TODO Error?

    await Promise.all([
      this.getRecordsForZap(task, RecordType.Source),
      this.getFormatterApp(),
      this.fetchGivesForRead(task),
    ]);
  };

  //------------------------------------
  // Network Requests
  //------------------------------------

  getFormatterApp = async () => {
    const app = 'ZapierFormatterDevAPI';
    const [err] = await sync(this.props.fetchApp(app) as any);
    if (err) {
      Logger.LogInfo('Failed to update Zap with err:', err);
    }
  };

  /**
   * Updates an existing Task.
   */
  updateZap = async (task: Task) => {
    const [err] = await sync(this.props.updateTask(task) as any);
    if (err) {
      Logger.LogInfo('Failed to update Zap with err:', err);
      this.props.flashError(
        `Failed to update Zap with err: ${JSON.stringify(err)}`
      );
    }
  };

  /**
   * Runs an existing Zap.
   */
  runZap = async (task: Task) => {
    const [err] = await sync(this.props.runTask(`${task.id}`) as any);
    if (err) {
      Logger.LogInfo('Failed to run Zap with err:', err);
      this.props.flashError(`Failed to run Zap with err: ${err}`);
    }
    this.props.flashSuccess('Your zap is running!');
  };

  /**
   * Fetches all the gives associated with the read
   */
  fetchGivesForRead = async (task: Task) => {
    // Fetch our gives.
    await this.props.fetchGivesForStep(task.export_step!);

    // This is awkward. Need to fix this state stuff later...
    this.setState({ loadingGives: false });
  };

  /**
   * Fetches all records for a Zap.
   */
  getRecordsForZap = async (task: Task, type: RecordType) => {
    const [err] = await sync(this.props.fetchRecordsForTask(task, type) as any);
    if (err) {
      Logger.LogInfo('Failed to fetch records for Zap with err:', err);
      this.setState({ error: err });
    }
    this.setState({ loadingRecords: false });
  };

  //------------------------------------
  // Data Helpers
  //------------------------------------

  /**
   * Fetches an action if it exists.
   */
  getAction = (key: string, type: ActionType) => {
    return ActionForKeyAndType(this.props.actionState, key, type);
  };

  /**
   * Fetches Zap Records.
   */
  getZapRecord = () => {
    const { task } = this.state;
    if (!task) return;

    const { taskRecords } = this.props.taskState;
    const records = taskRecords[task.id!];
    if (!records) return;

    return records.length ? records[0] : null;
  };

  //------------------------------------
  // Header Handlers
  //------------------------------------

  /**
   * Handles back clicks in the Header.
   */
  handleBackClick = () => {
    navigate('/tasks');
  };

  /**
   * Handles saving a Zap name.
   */
  handleSaveZapName = async (name: string) => {
    Analytics.trackAction(Action.UpdateZapClick);

    const { task } = this.state;
    if (!task) return;

    task.name = name;
    await this.updateZap(task);
    this.props.flashSuccess('Zap Successfully Updated!');
  };

  //------------------------------------
  // Modal Handlers
  //------------------------------------

  /**
   * Toggles Modal Display.
   */
  hideModals = () => {
    this.setState({ currentModal: null as any });
  };

  /**
   * Handles submit for the Export Modal.
   */
  handleExportSubmit = async (form: FormState) => {
    Analytics.trackAction(Action.ExportToAppSubmit);

    this.hideModals();
    const { task } = this.state;
    if (!task) return;

    let updated = { ...task };
    const helper = new FormHelper(form);
    if (this.state.webhookURL) {
      updated.webhook_url = this.state.webhookURL;
    } else {
      const import_step = helper.getImportStep();
      updated = { ...updated, ...import_step };
      delete updated.webhook_url;
    }

    // Update our mappings.
    await this.updateZap(updated);
    await this.runZap(updated);
    await this.props.fetchTasks();

    this.setState({ ...initialState });
  };

  /**
   * Handles submit for the Import Modal.
   */
  handleImportSubmit = (values: any) => {
    Analytics.trackAction(Action.ImportSubmitClick);
    this.hideModals();
    return values;
  };

  /**
   * Hanldes clicks to the `Select Source` button.
   */
  handleSelectDataSource = (app: App) => {
    this.setState({ selectedApp: app });
  };

  //------------------------------------
  // Button Handlers
  //------------------------------------

  /**
   * Handles clicks to the `Import Data` button.
   */
  handleImportClick = () => {
    this.setState({ currentModal: ModalType.Import });
  };

  /**
   * Handles clicks to the `Send Rows To...` button.
   */
  handleExportClick = () => {
    Analytics.trackAction(Action.ExportDataClick);
    this.setState({ currentModal: ModalType.ExportOptions });
  };

  /**
   * Handles clicks on an export option.
   */
  handleExportOptionClick = (option: ExportOption) => {
    switch (option) {
      case ExportOption.App:
        Analytics.trackAction(Action.ExportToApp);
        this.setState({ currentModal: ModalType.Export });
        break;
      case ExportOption.Webhook:
        Analytics.trackAction(Action.ExportToWebhook);
        this.setState({ currentModal: ModalType.Webhook });
        break;
    }
  };

  /**
   * Handles webhook submissions.
   */
  handleSubmitWebhook = async (webhookURL: string) => {
    Analytics.trackAction(Action.ExportToWebhookSubmit);
    const { task } = this.state;
    if (!task) return;

    const currentModal = ModalType.Export;
    const formStep = FormStep.Confirmation;
    this.setState({ currentModal, formStep, webhookURL });
  };

  /**
   * Handles Test Clicks.
   */
  handleTest = async (webhookURL: string) => {
    const { task } = this.state;
    if (!task) return this.buildSpinner();

    const { taskRecords } = this.props.taskState;
    const records = taskRecords[task.id!];

    const body = JSON.stringify(records[0]);
    const opts = { method: 'POST', body };
    return fetch(webhookURL, opts);
  };

  //------------------------------------
  // UI Builders
  //------------------------------------

  /**
   * Builds the empty content component.
   */
  buidEmptyContent = () => {
    return <BlankEditor onAppSelected={this.handleSelectDataSource} />;
  };

  buildProgressBar = () => {
    const { task } = this.state;
    return <TaskProgressBar task={task} />;
  };

  /**
   * Builds the table header component.
   */
  buildTableHeader = () => {
    const { task } = this.state;
    return (
      <Header
        task={task}
        onBackClick={this.handleBackClick}
        onSaveZapName={this.handleSaveZapName}
        onImportDataClick={this.handleExportClick}
      />
    );
  };

  buildSpinner = () => {
    return (
      <div className="spinner-container">
        <Spinner large />
      </div>
    );
  };

  buildErrorComponent = () => {
    const { error } = this.state;
    const title = constants.ERR_TITLE;
    const description = error ? error.detail : constants.ERROR_DESC;
    return <EmptyComponent emoji={'😪'} title={title} description={description} />
  };

  buildNoRecordComponent = () => {
    const title = constants.NO_RECORDS_TITLE;
    const description = constants.NO_RECORDS_DESC;
    return <EmptyComponent emoji={'🤷‍♂️'} title={title} description={description} />
  };


  /**
   * Builds the data table component.
   */
  buildTable = () => {
    if (this.state.error) return this.buildErrorComponent();

    // Grab our zap.
    const { task } = this.state;
    if (!task) return this.buildSpinner();

    // Grab our records.
    const { taskRecords } = this.props.taskState;
    const records = taskRecords[task.id!];

    if (this.state.loadingGives || this.state.loadingRecords) return this.buildSpinner();
    if (records.length === 0) return this.buildNoRecordComponent();
    const gives = this.props.actionState.currentGives
    return (
      <div className="editor-inner-container">
        {this.buildTableHeader()}
        <Table
          task={task}
          gives={gives}
          data={records}
          onNewZapClick={this.handleImportClick}
        />
      </div>
    );
  };

  //---------------------------------------
  // Modal Builders
  //---------------------------------------

  buildImportModal = () => {
    const { currentModal, formStep } = this.state;
    const show = currentModal === ModalType.Import;
    if (!show) return;

    const { task } = this.state;
    return (
      <TaskSetupModal
        show
        key="zap-import-modal"
        task={task}
        step={formStep}
        type={FormType.Read}
        onSubmit={this.handleImportSubmit}
        onToggle={this.hideModals}
      />
    );
  };

  buildExportModal = () => {
    const { currentModal, formStep } = this.state;
    const show = currentModal === ModalType.Export;
    if (!show) return;

    const { task } = this.state;
    return (
      <TaskSetupModal
        show
        key="zap-export-modal"
        task={task}
        step={formStep}
        type={FormType.Write}
        onSubmit={this.handleExportSubmit}
        onToggle={this.hideModals}
      />
    );
  };

  buildExportMenuModal = () => {
    const { currentModal } = this.state;
    const show = currentModal === ModalType.ExportOptions;
    if (!show) return;

    return (
      <ExportOptionModal
        show
        onClick={this.handleExportOptionClick}
        onToggle={this.hideModals}
      />
    );
  };

  buildWebhookModal = () => {
    const { currentModal } = this.state;
    const show = currentModal === ModalType.Webhook;
    if (!show) return;

    const record = this.getZapRecord();
    return (
      <WebhookModal
        show
        testRecord={record}
        onTest={this.handleTest}
        onSubmit={this.handleSubmitWebhook}
        onToggle={this.hideModals}
      />
    );
  };

  render() {
    const component = this.buildTable();
    return (
      <div className="editor-container">
        {this.buildProgressBar()}
        {component}
        {this.buildImportModal()}
        {this.buildExportModal()}
        {this.buildWebhookModal()}
        {this.buildExportMenuModal()}
      </div>
    );
  }
}

const mapStateToProps = (state: RootReducerState) => ({
  appState: state.app,
  actionState: state.action,
  taskState: state.task,
});

const mapDispatchToProps = {
  ...actions.AppActions,
  ...actions.TaskActions,
  ...actions.FlashActions,
  ...actions.UserActions,
  ...actions.GiveActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Editor as any);
