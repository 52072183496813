// External Dependencies
import { Dispatch, Action } from 'redux';

// Actions
export enum TypeKeys {
  // Resources
  SET_ERROR = 'SET_ERROR',
  CLEAR_ERROR = 'CLEAR_ERROR',
}

export type Actions = SetErrorAction | ClearErrorAction;

// -----------------------------------------------------------------------------
// Fetch Zap
// -----------------------------------------------------------------------------

export interface SetErrorAction extends Action {
  type: TypeKeys.SET_ERROR;
  error: Error;
}

export interface ClearErrorAction extends Action {
  type: TypeKeys.CLEAR_ERROR;
}

const setError = (error: Error | string) => async (
  dispatch: Dispatch<SetErrorAction>
) => {
  if (typeof error === 'string') {
    return dispatch({
      type: TypeKeys.SET_ERROR,
      error: new Error(error as string),
    });
  }
  dispatch({ type: TypeKeys.SET_ERROR, error: error as Error });
};

const clearError = () => async (dispatch: Dispatch<ClearErrorAction>) => {
  dispatch({ type: TypeKeys.CLEAR_ERROR });
};

// -----------------------------------------------------------------------------
// Exports
// -----------------------------------------------------------------------------

export const ErrorActions = {
  setError,
  clearError,
};
