import './style.scss';

import * as React from 'react';
import { FieldProps, FieldInputProps } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

// Actions
import { AppActions } from '../../actions/app';
import { ActionActions } from '../../actions/action';
import { NeedActions } from '../../actions/need';

// State
import { RootReducerState } from '../../reducers';
import { ActionForKeyAndType } from '../../reducers/action';

// Components
import FormComponent from '../Form';
import Button from '../Button';
import { InputOption } from '../Inputs/types';
import { BuildInputs } from './inputs';

// Models
import * as model from '../../models';

// Utils
import * as Logger from '../../../utils/logger';
import { sync } from '../../../utils/sync';

type ConnectedState = ReturnType<typeof mapStateToProps>;
type ConnectedActions = ReturnType<typeof mapDispatchToProps>;
type ComponentProps = {
  app: model.App;
  auth?: model.Auth;
  actionType: model.ActionType;
  onSave: () => void;
  onCancel: () => void;
};
type Props = ComponentProps & ConnectedState & ConnectedActions;

const initialState = {
  loading: false,
  action: (null as unknown) as model.Action,
  needs: {} as { [key: string]: any },
};
type State = typeof initialState;

/**
 * Component displays a form that allows users to specify a Type and a Format for the Column.
 */
class ActionSetupForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...initialState };
  }

  componentDidMount() {
    this.ensureAppActions();
  }

  ensureAppActions = async () => {
    this.setState({ loading: true });
    await this.fetchActionForApp();
    this.setState({ loading: false });
  };

  //------------------------------------
  // Network Requests
  //------------------------------------

  /**
   * Fetches all actions for the supplied app.
   */
  fetchActionForApp = async () => {
    const { app } = this.props;
    const selectedAppID = app.selectedApi;
    const fetchPromise = this.props.fetchActionsForApp(selectedAppID);
    const [err] = await sync(fetchPromise as any);
    if (err) {
      Logger.LogInfo('Failed to needs for app with err:', err);
    }
  };

  /**
   * Fetches all needs for the current form values.
   */
  fetchNeedsForWriteAction = async () => {
    const { app, auth } = this.props;
    const { action } = this.state;
    if (!app || !auth || !action) return;

    const { needs } = this.state;
    const [err] = await sync(
      this.props.fetchNeedsForWriteAction(
        action,
        app.currentImplementationId,
        needs,
        auth,
      ) as any
    );
    if (err) {
      Logger.LogInfo('Failed to needs for app with err:', err);
    }
  };

  //------------------------------------
  // Form Handlers
  //------------------------------------

  /**
   * Handles changes to the form's select components.
   */
  handleChange = (option: InputOption, fieldProps: FieldProps) => {
    const { field } = fieldProps;
    if (field.name === 'action') {
      this.setState({ action: option.value });
    }

    if (field.name.includes('need')) {
      this.handleNeedChange(option.value, field);
    }
  };

  /**
   * Handles changes to a Need select input.
   */
  handleNeedChange = (need: any, field: FieldInputProps<any>) => {
    const key = field.name.split('.')[1];
    const needs = { ...this.state.needs };
    needs[key] = need;
    this.setState({ needs }, () => {
      this.fetchNeedsForWriteAction();
    });
  };

  //------------------------------------
  // Data Helpers
  // ----------- -------------------------

  /**
   * Gets the updated action from Redux.
   */
  public getUpdatedAction = (): model.Action | undefined => {
    const { action } = this.state;
    if (!action) return;

    // Pick the updated action out of redux.
    const { actionState } = this.props;
    return ActionForKeyAndType(actionState, action.key, action.type)
  };

  //------------------------------------
  // UI Builders
  //------------------------------------

  /**
   * Builds our Form inputs.
   */
  buildForm = (children: any) => {
    const { onSave, onCancel } = this.props;
    return (
      <div>
        {children}
        <div className="button-container d-flex justify-content-between">
          <Button title="CANCEL" variant="secondary" onClick={onCancel} />
          <Button title="SAVE" variant="primary" onClick={onSave} />
        </div>
      </div>
    );
  };

  render() {
    const { app, auth } = this.props;
    const { needs } = this.state;
    const action = this.getUpdatedAction();
    const inputs = BuildInputs(this.props.actionState.currentNeeds);
    const initialValues = { app, auth, action, needs };

    return (
      <FormComponent
        enableReinitialize
        inputs={inputs}
        initialValues={initialValues}
        onChange={this.handleChange}
        renderFunc={(children: any) => {
          return this.buildForm(children);
        }}
      />
    );
  }
}

const mapStateToProps = (state: RootReducerState) => ({
  appState: state.app,
  actionState: state.action,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...AppActions,
      ...ActionActions,
      ...NeedActions,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionSetupForm);
