// Components
import { InputType, Input } from '../../Inputs/types';
import { getInputConfigForType } from '../../Inputs/inputs';

// Step
import { TaskSetupStep } from '../shared';

/**
 * Builds the input config for column type form.
 */
export const BuildInputsForSetup = (step: TaskSetupStep): Input[] => {
  let types = [] as InputType[];
  switch (step) {
    case TaskSetupStep.Import:
    case TaskSetupStep.Export:
      types = [
        InputType.AppSelect,
        InputType.AuthSelect,
        InputType.ActionSelect,
        InputType.NeedSelect,
      ];
      break;
    case TaskSetupStep.AppGrid:
      types = [InputType.AppGrid];
      break;
    case TaskSetupStep.App:
      types = [InputType.AppSelect];
      break;
    case TaskSetupStep.Auth:
      types = [InputType.AuthSelect];
      break;
    case TaskSetupStep.AppAndAuth:
      types = [InputType.AppSelect, InputType.AuthSelect];
      break;
    case TaskSetupStep.Action:
      types = [InputType.ActionSelect, InputType.NeedSelect];
      break;
    case TaskSetupStep.Needs:
      types = [InputType.NeedSelect];
      break;
    default:
      types = [];
      break;
  }
  return BuildInputConfig(types);
};

export const BuildInputConfig = (types: InputType[]) => {
  return types.map((type: InputType) => {
    return getInputConfigForType(type);
  });
};
