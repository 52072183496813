// External Dependencies
import {
  createStore,
  applyMiddleware,
  Middleware,
  // MiddlewareAPI,
  Dispatch,
  Action,
  Store,
} from 'redux';
import thunk from 'redux-thunk';
import { saveState as saveToLocalStorage } from './local';
import * as Sentry from "@sentry/browser";
import createSentryMiddleware, { Options as SentryReduxOpts } from "redux-sentry-middleware";
import { composeWithDevTools } from 'redux-devtools-extension';


// Reducers
import rootReducer, { RootReducerState } from '../reducers';

// Actions
import { TypeKeys as SessionTypeKeys } from '../actions/session';
import { ErrorActions } from '../actions/error';

// Util
import { UNAUTHORIZED_ACCESS_NOTIFICATION } from '../utils/fetch';

export const unauthorizedHandler: Middleware = () =>
  // api: MiddlewareAPI<Dispatch>
  (next: Dispatch<Action>) => <A extends Action>(action: A) => {
    const middlewareAction = (next(action) as any);
    const middlewareActionThunk = middlewareAction as Promise<void>
    if (middlewareActionThunk && typeof middlewareActionThunk.catch === 'function') {
      middlewareActionThunk.catch((err: Error) => {
        if (err.message === UNAUTHORIZED_ACCESS_NOTIFICATION) {
          // Clears the store and forces the app back to login
          const signOutAction = { type: SessionTypeKeys.LOGGED_OUT };
          next(signOutAction);
          return;
        }
        // Dispatch an error to our error reducer
        ErrorActions.setError(err)(next)
        throw err;
      });
    } else if (middlewareAction?.error?.message === UNAUTHORIZED_ACCESS_NOTIFICATION) {
      const signOutAction = { type: SessionTypeKeys.LOGGED_OUT };
      next(signOutAction);
      return;
    }
    return middlewareAction;
  };

/**
 * @method      configureStore
 * @description Returns the created store for the application
 */
export default function configureStore(initialState: RootReducerState) {
  const opts: SentryReduxOpts<RootReducerState> = {
    stateTransformer: (state: RootReducerState) => {
      const stateCopy: RootReducerState = {
        ...state,
        zap: {
          ...state.zap,
          zapRecordsByRootRecordID: {},
          nodeOutputsByRootNodeID: {}
        }
      }

      return stateCopy
    }
  }
  const sentryMiddleware = createSentryMiddleware<RootReducerState>(Sentry as any, opts) // Type issue w redux middleware :(
  const middleware = applyMiddleware(unauthorizedHandler, sentryMiddleware, thunk);

  const store = createStore(rootReducer as any, Object.assign({}, initialState), composeWithDevTools(
    middleware,
    // other store enhancers if any
  ));

  addSubscribersToStore(store);
  return store;
}

/**
 * @method      addSubscribersToStore
 * @description Takes a store and adds subscribers to the store
 */
function addSubscribersToStore(store: Store<RootReducerState>) {
  // Only save updates to the session and report
  // and limit saves to once per second
  store.subscribe(() => {
    // Persist State to Local Storage
    saveToLocalStorage({
      user: store.getState().user,
      action: store.getState().action,
      app: {
        ...store.getState().app,
        appsByID: {},
        privateAppsByID: {}
      },
      auth: store.getState().auth,
      task: store.getState().task,
      flash: store.getState().flash,
      nav: store.getState().nav,
      zap: {
        ...store.getState().zap,
        zapRecordsByRootRecordID: {},
        nodeOutputsByRootNodeID: {},
        givesByNodeID: {},
        loadingZap: false,
        loadingRecords: false,
        loadingGives: false,
      },
      error: store.getState().error,
    });
  });
}
