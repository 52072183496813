import './style.scss';

import React from 'react';
import * as RF from 'react-feather';
import {
  OptionProps,
  ValueContainerProps,
  SingleValueProps,
  components,
} from 'react-select';

// Components
import AppIcon from '../../../AppIcon';
import Spinner from '../../../Spinner';

// Utils
import Classname from '../../../../utils/classname';

// Model
import * as model from '../../../../models';

/**
 * Builds a custom component for Select Values.
 * The `Value` component is displayed when a value has been selected.
 */
export const ValueComponent = (
  props: SingleValueProps<any>,
  app: model.App
) => {
  const { selectProps, hasValue } = props;
  let component = props.children;
  if (selectProps.name === 'app' && hasValue) {
    component = <AppValue {...props} />;
  }
  if (selectProps.name === 'auth' && hasValue) {
    component = <AuthValue valueProps={props} app={app} />;
  }
  if (selectProps.name === 'action' && hasValue) {
    component = <ActionValue valueProps={props} app={app!} />;
  }
  if (selectProps.name?.includes('need') && hasValue) {
    component = <NeedValue valueProps={props} app={app!} />;
  }
  if (selectProps.name?.includes('mapping') && hasValue) {
    component = <NeedValue valueProps={props} app={app!} />;
  }
  return (
    <components.SingleValue {...props}>{component}</components.SingleValue>
  );
};

/**
 * Builds a custom component for an Option.
 * The `Option` component is displayed in the select dropdown.
 */
export const OptionComponent = (props: OptionProps<any>, app: model.App) => {
  const { selectProps, data } = props;
  if (data.value === 'spinner') {
    return <SpinnerOption optionProps={props} />;
  }
  if (selectProps.name === 'app') {
    return <AppOption {...props} />;
  }
  if (selectProps.name === 'auth') {
    return <AuthOption optionProps={props} app={app!} />;
  }
  if (selectProps.name === 'action') {
    return <ActionOption optionProps={props} app={app!} />;
  }
  if (selectProps.name?.includes('need')) {
    return <NeedOption optionProps={props} app={app} />;
  }
  if (selectProps.name?.includes('mapping')) {
    return <NeedOption optionProps={props} app={app} />;
  }
  return <components.Option {...props} />;
};
//----------------------------------------
// App Components
//----------------------------------------

export const AppValue = (props: ValueContainerProps<any>) => {
  const { selectProps } = props;
  const app = selectProps.value.value
  return (
    <CustomSelectComponent
      type={'value'}
      class={`app-${app.name}`}
      app={app}
      label={app.name}
    />
  );
};

export const AppOption = (props: OptionProps<any>) => {
  const { innerProps, data } = props;
  const label = data.label;
  const app = data.value;
  const onClick = () => innerProps.onClick(app);
  return (
    <CustomSelectComponent
      type={'option'}
      class={`app-${label}`}
      app={app}
      label={label}
      onClick={onClick}
    />
  );
};

//----------------------------------------
// Auth Components
//----------------------------------------

type AuthValueProps = {
  app: model.App;
  valueProps: ValueContainerProps<any>;
};

export const AuthValue = (props: AuthValueProps) => {
  const { selectProps } = props.valueProps;
  const auth = selectProps.value.value as model.Auth;
  return (
    <CustomSelectComponent
      type={'value'}
      class={`app-${auth.title}`}
      app={props.app}
      label={auth.title}
    />
  );
};

type Props = {
  app: model.App;
  optionProps: OptionProps<any>;
};

export const AuthOption = (props: Props) => {
  const { innerProps, data } = props.optionProps;
  if (data.value === 'NEW_AUTH') {
    return <NewAuthOption optionProps={props.optionProps} />;
  }
  const label = data.label;
  const auth = data.value as model.Auth;
  const onClick = () => innerProps.onClick(auth as any);
  return (
    <CustomSelectComponent
      type={'option'}
      class={`app-${label}`}
      app={props.app}
      label={label}
      description={auth.selected_api}
      onClick={onClick}
    />
  );
};

//----------------------------------------
// Action Components
//----------------------------------------

type ActionValueProps = {
  app: model.App;
  valueProps: ValueContainerProps<any>;
};
export const ActionValue = (props: ActionValueProps) => {
  const { selectProps } = props.valueProps;
  const label = selectProps.value.label;
  const action = selectProps.value.value as model.Action;
  const key = `select-value-${action.name}`;
  return (
    <CustomSelectComponent
      type={'value'}
      class={`action-${label}`}
      key={key}
      app={props.app}
      label={label}
    />
  );
};

type ActionOptionProps = {
  app: model.App;
  optionProps: OptionProps<any>;
};
export const ActionOption = (props: ActionOptionProps) => {
  const { innerProps, data } = props.optionProps;
  const label = data.label;
  const action = data.value as model.Action;
  const onClick = () => innerProps.onClick(action as any);
  return (
    <CustomSelectComponent
      type={'option'}
      class={`action-${label}`}
      app={props.app}
      label={label}
      description={action.description}
      onClick={onClick}
    />
  );
};

//----------------------------------------
// Need Components
//----------------------------------------

type NeedValueProps = {
  app: model.App;
  valueProps: ValueContainerProps<any>;
};

export const NeedValue = (props: NeedValueProps) => {
  const { selectProps } = props.valueProps;
  const need = selectProps.value.label;
  return (
    <CustomSelectComponent
      type={'value'}
      class={`need-${need}`}
      app={props.app}
      label={need}
    />
  );
};

type NeedOptionProps = {
  app: model.App;
  optionProps: OptionProps<any>;
};

export const NeedOption = (props: NeedOptionProps) => {
  const { innerProps, data } = props.optionProps;
  const needID = data.value;
  const needLabel = data.label;
  const onClick = () => innerProps.onClick(needID as any);
  return (
    <CustomSelectComponent
      type={'option'}
      class={`need-${needLabel}`}
      app={props.app}
      label={needLabel}
      onClick={onClick}
    />
  );
};

//----------------------------------------
// Custom Component
//----------------------------------------

type CustomSelectComponentProps = {
  type: string;
  class: string;
  app: model.App;
  label: string;
  description?: string;
  onClick?: any;
};

export const CustomSelectComponent = (props: CustomSelectComponentProps) => {
  const className = Classname({
    'custom-select-container': true,
    [props.type]: true,
    [props.class]: true,
    'd-flex align-items-center': true,
  });

  const key = `select-value-${props.class}`;
  return (
    <div className={className} key={key} onClick={props.onClick}>
      <AppIcon app={props.app} size={32} padding={6} radius={8} />
      <div>
        <p className="custom-select-label">{`${props.label}`}</p>
        {props.description ? (
          <p className="custom-select-description">{`${props.description}`}</p>
        ) : null}
      </div>
    </div>
  );
};

//----------------------------------------
// Spinner Component
//----------------------------------------

type SpinnerProps = {
  optionProps: OptionProps<any>;
};

export const SpinnerOption = (props: SpinnerProps) => {
  const { label } = props.optionProps;
  const className = Classname({
    'custom-select-container': true,
    spinner: true,
    'd-flex': true,
    'align-items-center': true,
    'justify-content-center': true,
  });

  return (
    <div className={className} key={label}>
      <Spinner />
    </div>
  );
};

//----------------------------------------
// New Account Component
//----------------------------------------

type NewAuthProps = {
  optionProps: OptionProps<any>;
};

export const NewAuthOption = (props: NewAuthProps) => {
  const className = Classname({
    'custom-select-container': true,
    'new-auth': true,
    'd-flex': true,
    'align-items-center': true,
    'justify-content-center': true,
  });
  const { innerProps, data, label } = props.optionProps;
  const onClick = () => innerProps.onClick(data.value);
  return (
    <div className={className} key={label} onClick={onClick}>
      <RF.Plus size={20} />
      <p className="new-auth-label">ADD A NEW ACCOUNT</p>
    </div>
  );
};
