import './style.scss';

import React from 'react';
import * as RB from 'react-bootstrap';
import { FieldProps } from 'formik';
import { Search } from 'react-feather';

import { Input } from '../types';

type Props = {
  input?: Input;
  fieldProps?: FieldProps;
  onSearch: (search: string) => void;
};

/**
 * SearchInput renders a search input.
 */
const SearchInput = (props: Props) => {
  const { onSearch } = props;

  /**
   * Handles changes to the Search input.
   * @param event
   */
  const handleSearch = (event: any) => {
    onSearch(event.target.value);
  };

  const icon = (
    <RB.InputGroup.Prepend>
      <RB.InputGroup.Text id="search-icon">
        <Search size={20} />
      </RB.InputGroup.Text>
    </RB.InputGroup.Prepend>
  );

  return (
    <RB.InputGroup className="search-input-group mb-3">
      {icon}
      <RB.Form.Control
        className="transfer-search-input"
        placeholder="Search By App"
        aria-label="search-app"
        aria-describedby="search-app"
        onChange={handleSearch}
      />
    </RB.InputGroup>
  );
};
export default SearchInput;
