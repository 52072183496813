import './style.scss';

import * as React from 'react';
// import * as RB from 'react-bootstrap';

// Components
import AppSelector from '../../common/components/AppSelector';

// Models
import { App } from '../../common/models/app';

type Props = {
  onAppSelected: (app: App) => void;
};

/**
 * Renders a blank editor. Used for empty content display.
 */
export const BlankEditor = (props: Props) => {
  return (
    <div className="blank-editor-container d-flex justify-content-center">
      <div className="content-container">
        <h1>Select Data Source</h1>
        <p>Click below to select your origin data source.</p>
        <AppSelector onAppSelected={props.onAppSelected} />
      </div>
    </div>
  );
};

export default BlankEditor;
