// Inputs
import { InputType, InputOption } from '../Inputs/types';

export interface FormState {
  importFields: any[];
  exportFields: any[];
}

/**
 * Builds input config for a need!
 */
export const BuildFieldInput = (
  index: number,
  importOptions: InputOption[],
  exportOptions: InputOption[]
) => {
  return [
    {
      id: `import-field-${index}`,
      type: InputType.Select,
      key: `importFields[${index}]`,
      placeholder: 'Select...',
      size: 6,
      options: importOptions || [],
      required: true,
    },
    {
      id: `export-field-${index}`,
      type: InputType.Select,
      key: `exportFields[${index}]`,
      placeholder: 'Select...',
      size: 6,
      options: exportOptions || [],
      required: true,
    },
  ];
};
