import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
//import * as RF from 'react-feather';

// Actions
import { AppActions } from '../../../actions/app';
import { TaskActions } from '../../../actions/task';
import { ActionActions } from '../../../actions/action';

// Components
import { TaskStep } from '../shared';
import { ButtonWidth } from '../../Button';
import TaskSetupButtonContainer from '../TaskSetupButtonContainer';

// Model
import * as model from '../../../models';

// Utils
import { sync } from '../../../../utils/sync';

type ComponentProps = {
  task: model.Task;
  step: TaskStep;
  onNext: () => void;
  onSuccess: () => void;
  onError: (err: Error) => void;
  onSkip: () => void;
  onBack: () => void;
};
type ConnectedProps = ReturnType<typeof mapDispatchToProps>;
type Props = ConnectedProps & ComponentProps;

// State
const initialState = {
  loading: false,
  success: false,
};
type State = typeof initialState;

/**
 * Renders the Task Setup Test component.
 */
class TaskSetupTest extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...initialState };
  }

  //------------------------------------
  // Network Requests
  //------------------------------------

  /**
   * Fetches needs for an app.
   */
  executeZapTest = async () => {
    const { task } = this.props;
    this.setState({ loading: true });
    const fetch = this.props.testTask(`${task.id}`);
    const [err] = await sync(fetch as any);
    if (err) {
      // Handle
    }
    this.setState({ loading: false });
    return err;
  };

  //------------------------------------
  // Button Handlers
  //------------------------------------

  handleTest = async () => {
    const err = await this.executeZapTest();
    if (err) {
      this.props.onError(err);
      this.setState({ success: false });
    } else {
      this.props.onSuccess();
      this.setState({ success: true });
    }
  };

  //------------------------------------
  // UI Builders
  //------------------------------------

  buildButtonContainer = () => {
    const { onNext } = this.props;
    const { success } = this.state;

    const rightTitle = success ? 'Run' : 'Test';
    const rightOnClick = success ? onNext : this.handleTest;
    const leftButton = {
      title: 'Back',
      variant: 'transparent',
      onClick: this.props.onBack,
      width: ButtonWidth.Medium,
    };
    const rightButton = {
      title: rightTitle,
      variant: 'primary',
      onClick: rightOnClick,
      loading: this.state.loading,
      width: ButtonWidth.Large,
    };
    const secondaryButton = {
      title: 'Skip',
      variant: 'transparent',
      onClick: this.props.onSkip,
      width: ButtonWidth.Medium,
    };

    return (
      <TaskSetupButtonContainer
        leftButton={leftButton}
        rightButton={rightButton}
        secondaryButton={secondaryButton}
      />
    );
  };

  buildConfimrationCol = () => {
    return (
      <div className="confirmation-container">
        {this.buildButtonContainer()}
      </div>
    );
  };

  render() {
    return this.buildConfimrationCol();
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  const creators = {
    ...AppActions,
    ...TaskActions,
    ...ActionActions,
  };
  return bindActionCreators(creators, dispatch);
};

export default connect(null, mapDispatchToProps)(TaskSetupTest);
