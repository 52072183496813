// External Dependencies
import { Dispatch, Action } from 'redux';

// Model
import * as model from '../models';

// Service
import Transfer from '../services/transfer';

const service = new Transfer();

// Actions
export enum TypeKeys {
  FETCHING_ME = 'FETCHING_ME',
  FETCHED_ME = 'FETCHED_ME',
  FETCHED_STATE = 'FETCHED_STATE',
}

export type Actions = FetchedMeAction | FetchedStateAction | FetchingMeAction;

// -----------------------------------------------------------------------------
// Fetch Me
// -----------------------------------------------------------------------------

export interface FetchingMeAction extends Action {
  type: TypeKeys.FETCHING_ME;
}

export interface FetchedMeAction extends Action {
  type: TypeKeys.FETCHED_ME;
  user: model.User | null;
}

const fetchMe = () => async (dispatch: Dispatch<FetchingMeAction | FetchedMeAction>) => {
  dispatch({ type: TypeKeys.FETCHING_ME })
  const type = TypeKeys.FETCHED_ME;
  let user: model.User | null = null;
  try {
    const foundUser = await service.getMe();
    if (typeof foundUser === 'object') {
      user = foundUser
    }
  } catch (e) {
    dispatch({ type, user });
    throw e
  }
  dispatch({ type, user });
};

// -----------------------------------------------------------------------------
// Fetch State
// -----------------------------------------------------------------------------

export interface FetchedStateAction extends Action {
  type: TypeKeys.FETCHED_STATE;
  apps: model.App[];
  privateApps: model.App[];
  auths: model.Auth[];
  tasks: model.Task[];
}

const fetchState = () => async (dispatch: Dispatch<FetchedStateAction>) => {
  const state = await service.getState();
  const type = TypeKeys.FETCHED_STATE;
  const action = { type, ...state };
  dispatch(action);
};

// -----------------------------------------------------------------------------
// Exports
// -----------------------------------------------------------------------------

export const UserActions = {
  fetchMe,
  fetchState,
};
